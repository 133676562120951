import CreateOrgan from "./pages/create";
import ListOrgan from "./pages/list";
import { Route } from "@/types/RouteModel";
import UpdateOrgan from "./pages/update";
import DetailPerson from "./pages/detail-person";
import CreatePerson from "./pages/create-person";
import UpdatePerson from "./pages/update-person";

const organRoutes: Route[] = [
  {
    path: "organ/:type",
    element: <ListOrgan />,
  },
  {
    path: "organ/:type/tambah",
    element: <CreateOrgan />,
  },
  {
    path: "organ/:type/ubah/:id",
    element: <UpdateOrgan />,
  },
  {
    path: "organ/:type/person/:id",
    element: <DetailPerson />,
  },
  {
    path: "organ/:type/person/:id/tambah",
    element: <CreatePerson />,
  },
  {
    path: "organ/:type/person/:id/ubah/:personId",
    element: <UpdatePerson />,
  },
];

export default organRoutes;
