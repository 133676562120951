import { create } from "zustand";

interface RootState {
  desktopOpen: boolean;
  mobileOpen: boolean;
  isClosing: boolean;
  nestedOpen: { [key: string]: boolean };
}

const useRootStore = create<
  RootState & {
    toggleDrawer: () => void;
    closeDrawer: () => void;
    transitionEnd: () => void;
    toggleNestedOpen: (key: string) => void;
    setToggleDrawer: (value: boolean) => void;
  }
>((set, _) => ({
  desktopOpen: true,
  mobileOpen: false,
  isClosing: false,
  nestedOpen: {},
  setToggleDrawer: (value: boolean) =>
    set(() => ({ mobileOpen: value, desktopOpen: value })),
  toggleDrawer: () =>
    set((state: RootState) => {
      if (!state.isClosing) {
        return {
          mobileOpen: !state.mobileOpen,
          desktopOpen: !state.desktopOpen,
        };
      }
      return state;
    }),
  closeDrawer: () =>
    set(() => ({
      isClosing: true,
      mobileOpen: false,
      desktopOpen: false,
    })),
  transitionEnd: () =>
    set(() => ({
      isClosing: false,
    })),
  toggleNestedOpen: (key: string) =>
    set((state: RootState) => ({
      nestedOpen: {
        ...state.nestedOpen,
        [key]: !state.nestedOpen[key],
      },
    })),
}));

export default useRootStore;
