import { useParams } from "react-router-dom";
import useDetailPerson from "./useDetailPerson";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import DataTable from "@/components/table";
import PersonKanban from "../../components/PersonKanban";
import { ArrowCircleLeftOutlined } from "@mui/icons-material";
import toPascalCase from "@/utility/pascalCase";

const DetailPerson = () => {
  const { id, type } = useParams();
  const {
    defaultPostQuery,
    columns,
    service,
    handleAddButtonClick,
    uniqKey,
    navigate,
    periode,
  } = useDetailPerson(Number(id), String(type));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { xs: 2, sm: 3, md: 4 },
        width: { xs: "90%", sm: "85%", md: "90%" },
        marginX: "auto",
        marginTop: {
          xs: "4rem",
          sm: "6rem",
          md: "8rem",
          lg: "10rem",
          xl: "12rem",
        },
        paddingX: { xs: 2, sm: 3, md: 4 },
      }}
    >
      <Card>
        <CardHeader
          style={{ paddingBottom: 0 }}
          avatar={
            <IconButton
              size="small"
              onClick={() => navigate(`/cms-ybrs/organ/${type}`)}
            >
              <ArrowCircleLeftOutlined />
            </IconButton>
          }
          title={`List ${toPascalCase(String(type))} Periode ${
            periode?.data?.periodeRange ?? "-"
          }`}
          titleTypographyProps={{
            fontSize: 24,
          }}
          subheaderTypographyProps={{
            fontSize: 16,
          }}
        />
        <CardContent>
          <DataTable
            showPagination={false}
            postQueryValue={defaultPostQuery}
            kanbanRender={({ index, row }) => {
              return (
                <PersonKanban
                  index={index}
                  id={Number(id)}
                  type={String(type)}
                  row={row}
                />
              );
            }}
            uniqKey={uniqKey}
            handleAddButtonClick={handleAddButtonClick}
            columns={columns}
            service={service}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default DetailPerson;
