import { ColumnType } from "@/components/table/types/ColumnModel";
import { PostQuery } from "@/types/PostQuery";
import { useNavigate } from "react-router-dom";
import OrganAction from "../../components/OrganAction";
import PeriodeService from "../../services/PeriodeService";
import { PeriodeModel } from "../../types/PeriodeModel";
import { Chip } from "@mui/material";

const useListOrgan = (type: string) => {
  const uniqKey = `organ_${type}`;
  const periodeService = new PeriodeService(type);
  const navigate = useNavigate();
  const columns: ColumnType<PeriodeModel>[] = [
    {
      field: "periodeRange",
      type: "string",
      headerName: "Periode",
    },
    {
      field: "tahunAwal",
      type: "number",
      headerName: "Tahun Awal",
    },
    {
      field: "tahunAkhir",
      type: "number",
      headerName: "Tahun Akhir",
    },
    {
      field: "isActive",
      headerName: "Is Active",
      type: "boolean",
      renderCell: (row: PeriodeModel) => (
        <Chip label={row.isActive ? "Active" : "Draft"} />
      ),
    },
    {
      field: "actions",
      type: "actions",

      headerName: "Actions",
      renderCell: (data: PeriodeModel) => {
        return <OrganAction type={type} data={data} />;
      },
    },
  ];
  const handleAddButtonClick = () => {
    navigate(`/cms-ybrs/organ/${type}/tambah`);
  };

  const service = (postQuery: PostQuery) => {
    return periodeService.all(postQuery);
  };
  return {
    uniqKey,
    columns,
    handleAddButtonClick,
    service,
  };
};

export default useListOrgan;
