import logoHorizontal from "@/assets/images/horizontal_logo.png";
import shape23 from "@/assets/images/shape/shape-23.png";
import shape24 from "@/assets/images/shape/shape-24.png";
import shape25 from "@/assets/images/shape/shape-25.png";
import icon1 from "@/assets/images/icons/icon-1.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Footer = () => {
  const { t } = useTranslation("general");
  const { t: tHome } = useTranslation("home");

  const menuItems = [
    { name: t("beranda"), link: "/" },
    { name: t("tentang_kami"), link: "/tentang-kami" },
    //{ name: t("donasi"), link: "/donasi" },
    { name: t("artikel"), link: "/informasi/artikel" },
    { name: t("kegiatan"), link: "/informasi/kegiatan" },
    { name: t("hubungi_kami"), link: "/hubungi-kami" },
  ];

  return (
    <footer className="main-footer" id="footer">
      <div className="pattern-layer">
        <div
          className="pattern-1"
          style={{ backgroundImage: `url(${shape23})` }}
        ></div>
        <div
          className="pattern-2"
          style={{ backgroundImage: `url(${shape24})` }}
        ></div>
        <div
          className="pattern-3"
          style={{ backgroundImage: `url(${shape25})` }}
        ></div>
        <div className="pattern-4"></div>
      </div>
      <div className="widget-section pt_120 pb_100">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
              <div className="footer-widget logo-widget">
                <figure className="footer-logo">
                  <Link to="/">
                    <img
                      src={logoHorizontal}
                      className="ybrs-w-[180px] ybrs-h-[60px]"
                      alt=""
                    />
                  </Link>
                </figure>
                <p>{tHome("deskripsi")}</p>
                <ul className="social-links clearfix">
                  {/* <li>
                    <Link to={import.meta.env.VITE_INSTAGRAM}>
                      <i className="icon-4"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={import.meta.env.VITE_TWITTER}>
                      <i className="icon-5"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={import.meta.env.VITE_LINKEDIN}>
                      <i className="icon-6"></i>
                    </Link>
                  </li> */}
                  <li>
                    <a target="_blank" href={import.meta.env.VITE_YOUTUBE}>
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 footer-column"></div>
            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
              <div className="footer-widget links-widget ml_110">
                <div className="widget-title">
                  <h3>Quick Link</h3> 
                </div>
                <div className="widget-content">
                  <ul className="links-list clearfix">
                    {menuItems.map((item, index) => (
                      <li key={index}>
                        <Link to={item.link}>{item.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
              <div className="footer-widget contact-widget">
                <div className="widget-title">
                  <h3>{t("hubungi_kami")}</h3>
                </div>
                <div className="widget-content">
                  <ul className="info-list">
                    <li>
                      <img src={icon1} alt="" />
                      {import.meta.env.VITE_ADDRESS}
                    </li>
                    <li>
                      <i className="icon-2"></i>
                      <p>{import.meta.env.VITE_PHONE}</p>
                    </li>
                    <li>
                      <i className="icon-26"></i>
                      <a href={`mailto:${import.meta.env.VITE_EMAIL}`}>
                        {import.meta.env.VITE_EMAIL}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="auto-container">
          <div className="bottom-inner">
            <ul className="footer-nav clearfix"></ul>
            <div className="copyright">
              <p>© 2024 {t("hak_cipta")} YBRS GKP</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;