import Footer from "@/components/footer";
import Header from "@/components/header";
import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

const MainLayout = () => {
  const location = useLocation();
  const [hasChange, setHasChange] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const adminSecret = import.meta.env.VITE_ADMIN_LOGIN_SECRET;
  const isAdmin = useMemo(
    () =>
      location.pathname.includes(adminSecret) ||
      location.pathname.includes("cms-ybrs"),
    [location.pathname]
  );

  useEffect(() => {
    setHasChange(true);
  }, [location.pathname]);
  useEffect(() => {
    if (hasChange) {
      scrollToTop();
      setHasChange(false);
    }
  }, [hasChange]);

  return (
    <>
      <div id="#top" className="boxed_wrapper ltr">
        {!isAdmin && <Header />}
        <Outlet />
        {!isAdmin && <Footer />}
      </div>
      {!isAdmin && (
        <a
          className="scroll-to-top scroll-to-target d-block"
          onClick={scrollToTop}
        >
          <i className="fas fa-angle-up"></i>
        </a>
      )}
    </>
  );
};

export default MainLayout;
