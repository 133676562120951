import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import moment from "moment";
import { artikelDummy } from "@/constants/artikel_dummy";
import ArticleService from "@/page/admin/articles/services/ArticleService";

const useListArticles = () => {
  const isAPIActive = import.meta.env.VITE_API_ACTIVE === "true";
  const articleService = new ArticleService();
  const articleQuery = useQuery({
    queryKey: ["article-guest"],
    queryFn: () => articleService.listGuest(),
  });

  const listArtikel = useMemo(() => {
    if (isAPIActive && articleQuery.isSuccess) {
      return articleQuery.data.data.map((article) => ({
        ...article,
        imageFilePath: `${import.meta.env.VITE_BASE_URL}/${
          article.imageFilePath
        }`,
      }));
    } else {
      return artikelDummy;
    }
  }, [isAPIActive, articleQuery.isSuccess, articleQuery.data]);

  const sortedArticle = listArtikel.sort((a, b) => {
    return moment(b.createdDate).diff(moment(a.createdDate));
  });

  const slicedArticle = sortedArticle.slice(0, 3);

  return { listArticle: slicedArticle };
};

export default useListArticles;
