import MiniHero from "@/components/minihero";
import { Link, useParams } from "react-router-dom";
import useDetailArticle from "./useDetailArtikel";
import moment from "moment";
import RichTextView from "@/components/richtext_view";
import richtextDefaultFormater from "@/utility/richtextFormater";
import toPascalCase from "@/utility/pascalCase";
const DetailArticlePage = () => {
  const { id } = useParams();
  const { articleDetail } = useDetailArticle(Number(id));
  return (
    <>
      <MiniHero
        title="Detail Artikel"
        breadcrumb={[
          { name: "Beranda", link: "/" },
          { name: "Kumpulan Artikel", link: "/informasi/artikel" },
          { name: "Detail Artikel" },
        ]}
      />
      <section className="sidebar-page-container sec-pad-2">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-12 content-side">
              <div className="blog-details-content">
                <div className="news-block-one">
                  <div className="inner-box">
                    <figure className="image-box">
                      <img src={articleDetail?.imageFilePath} alt="" />
                    </figure>
                    <div className="lower-content">
                      <ul className="post-info mb_15 clearfix">
                        <li>
                          <a href="#" onClick={(e) => e.preventDefault()}>
                            {articleDetail?.createdBy}
                          </a>
                        </li>
                        <li>
                          {moment(articleDetail?.createdDate).format(
                            "DD MMM YYYY"
                          )}
                        </li>
                      </ul>
                      <h2>{articleDetail?.judul}</h2>
                      <RichTextView
                        content={articleDetail?.deskripsi}
                        formater={richtextDefaultFormater}
                      />
                    </div>
                  </div>
                </div>
                <div className="post-tags mb_60">
                  <ul className="tags-list clearfix">
                    <li>
                      <h3>Tags:</h3>
                    </li>
                    {articleDetail?.tags.split(",").map((tag) => (
                      <li>
                        <Link
                          to={`/informasi/artikel?tag=${tag.replace(/\s+/g, '')}`}
                        >
                          {toPascalCase(tag.replace(/\s+/g, ""))}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DetailArticlePage;
