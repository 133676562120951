import { Form, Formik } from "formik";
import useCreateDonationsVideos from "./useCreateDonationsVideos";
import { DonationsVideosFormModel } from "../../types/DonationsVideosModel";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
} from "@mui/material";
import { ArrowCircleLeftOutlined } from "@mui/icons-material";
import FormikTextField from "@/components/formik/FormikTextField";
import FormikSwitchField from "@/components/formik/FormikSwitchField";
import FormikImageField from "@/components/formik/FormikImageField";
import FormikVideoField from "@/components/formik/FormikVideoField";

const CreateDonationsVideos = () => {
  const { validationSchema, mutation, navigate } = useCreateDonationsVideos();
  return (
    <Formik<DonationsVideosFormModel>
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        Title: "",
        Description: "",
        IsActive: false,
        ThumbnailFile: null,
        VideoFile: null,
      }}
      onSubmit={(values) => {
        mutation.mutate(values);
      }}
    >
      {({ handleSubmit }) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: 2, sm: 3, md: 4 },
            width: { xs: "90%", sm: "85%", md: "90%" },
            marginX: "auto",
            marginTop: {
              xs: "4rem",
              sm: "6rem",
              md: "8rem",
              lg: "10rem",
              xl: "12rem",
            },
            paddingX: { xs: 2, sm: 3, md: 4 },
          }}
        >
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3.5} xl={3}>
                <Card>
                  <CardHeader
                    style={{ paddingBottom: 0 }}
                    avatar={
                      <IconButton size="small" onClick={() => navigate(-1)}>
                        <ArrowCircleLeftOutlined />
                      </IconButton>
                    }
                    title="Tambah Video Donasi"
                    titleTypographyProps={{
                      fontSize: 24,
                    }}
                    subheader="Formulir Video Donasi"
                    subheaderTypographyProps={{
                      fontSize: 16,
                    }}
                    action={
                      mutation.isPending ? (
                        <CircularProgress size="1.5rem" />
                      ) : null
                    }
                  />
                  <CardContent
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <FormikTextField
                      name="Title"
                      label="Judul"
                      disabled={mutation.isPending}
                    />
                    <FormikImageField
                      name="ThumbnailFile"
                      label="Thumbnail"
                      disabled={mutation.isPending}
                    />
                    <FormikVideoField
                      name="VideoFile"
                      label="Video"
                      disabled={mutation.isPending}
                    />
                    <FormikSwitchField
                      name="IsActive"
                      label="Aktif"
                      disabled={mutation.isPending}
                    />
                    <Button
                      disabled={mutation.isPending}
                      startIcon={
                        mutation.isPending ? (
                          <CircularProgress size="1rem" />
                        ) : null
                      }
                      sx={{ mt: 2 }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Submit
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={8.5} xl={9}>
                <Card>
                  <CardContent
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <FormikTextField
                      name="Description"
                      label="Description"
                      multiline
                      rows={32}
                      disabled={mutation.isPending}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default CreateDonationsVideos;
