import { PeriodeFormModel, PeriodeModel } from "../types/PeriodeModel";
import { APIResponse } from "@/types/APIResponse";
import { PostQuery } from "@/types/PostQuery";
import { BaseAPI } from "@/utility/BaseAPI";
class PeriodeService {
  baseURL: string;
  baseURLGuest: string;

  baseAPI: BaseAPI;
  constructor(type: string) {
    this.baseAPI = new BaseAPI();
    this.baseURL = `${
      import.meta.env.VITE_BASE_URL
    }/api/v1/cms/periode_${type}`;
    this.baseURLGuest = `${
      import.meta.env.VITE_BASE_URL
    }/api/v1/web/periode_${type}`;
  }

  public async create(
    data: PeriodeFormModel
  ): Promise<APIResponse<PeriodeModel>> {
    return this.baseAPI.create(this.baseURL, data);
  }
  public async update(
    id: number,
    data: PeriodeFormModel
  ): Promise<APIResponse<PeriodeModel>> {
    return this.baseAPI.update(this.baseURL, id, data);
  }

  public async getDetail(id: number): Promise<APIResponse<PeriodeModel>> {
    return this.baseAPI.getDetail(this.baseURL, id);
  }
  async all(postQuery: PostQuery): Promise<APIResponse<PeriodeModel[]>> {
    return this.baseAPI.all(this.baseURL, postQuery);
  }
  async list(): Promise<APIResponse<PeriodeModel[]>> {
    return this.baseAPI.list(this.baseURL);
  }
  async listGuest(): Promise<APIResponse<PeriodeModel[]>> {
    return this.baseAPI.listGuest(this.baseURLGuest);
  }
  async delete(ids: string[]): Promise<APIResponse<any>> {
    return this.baseAPI.delete(this.baseURL, ids);
  }
}
export default PeriodeService;
