import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useHeader = () => {
  const { t } = useTranslation("general");
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const handleMobileNav = () => {
    setIsMobile(!isMobile);
  };
  useEffect(() => {
    if (isMobile) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }
  }, [isMobile]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const dropdowns = document.querySelectorAll(".dropdown");

    const handleDropdownClick = (event: any) => {
      event.preventDefault();
      const dropdownMenu = event.currentTarget.parentElement.querySelector("ul");
      const dropdownBtn = event.currentTarget;
      if (
        dropdownMenu.style.display === "none" ||
        !dropdownMenu.style.display
      ) {
        dropdownMenu.style.display = "block";
        event.currentTarget.parentElement.classList.add("current");
        if (dropdownBtn) {
          dropdownBtn.classList.add("open");
        }
      } else {
        dropdownMenu.style.display = "none";
        event.currentTarget.parentElement.classList.remove("current");
        if (dropdownBtn) {
          dropdownBtn.classList.remove("open");
        }
      }
    };

    dropdowns.forEach((dropdown) => {
      const dropdownBtn = dropdown.querySelector(".dropdown-btn");
      if (dropdownBtn) {
        dropdownBtn.addEventListener("click", handleDropdownClick);
      }
    });

    return () => {
      dropdowns.forEach((dropdown) => {
        const dropdownBtn = dropdown.querySelector(".dropdown-btn");
        if (dropdownBtn) {
          dropdownBtn.removeEventListener("click", handleDropdownClick);
        }
      });
    };
  }, []);
  return { isScrolled, isMobile, handleMobileNav, t };
};

export default useHeader;
