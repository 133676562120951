import { Navigate } from "react-router-dom";
import useAuthStore from "@/stores/authStore";

const AuthGuard = ({ children }: { children: React.ReactNode }) => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  return !isAuthenticated ? (
    <Navigate to={`/${import.meta.env.VITE_ADMIN_LOGIN_SECRET}`} />
  ) : (
    children
  );
};

export default AuthGuard;
