import { useParams } from "react-router-dom";
import { satuanKerjaData } from "./constants";
import { useMemo } from "react";
import MiniHero from "@/components/minihero";
import { useTranslation } from "react-i18next";
const SatuanKerjaDetail = () => {
  const { id } = useParams();
  const satuanKerja = useMemo(
    () => satuanKerjaData.find((item: any) => item.id === id),
    [id]
  );
  const { t } = useTranslation("satuan_kerja");
  const { t: tGeneral } = useTranslation("general");
  return (
    <>
      <MiniHero
        title={satuanKerja?.title ? t(satuanKerja.title) : ""}
        breadcrumb={[
          { name: tGeneral("beranda"), link: "/" },
          { name: t("satuan_kerja"), link: "/satuan-kerja" },
          { name: satuanKerja?.title ? t(satuanKerja.title) : "" },
        ]}
      />
      <section className="sidebar-page-container sec-pad-2">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-12 content-side">
              <div className="blog-details-content">
                <div className="news-block-one">
                  <div className="inner-box">
                    <figure className="image-box">
                      <img src={satuanKerja?.imgSrc} alt={satuanKerja?.title} />
                    </figure>
                    <div>
                      <div className=" ybrs-flex ybrs-flex-col ybrs-gap-4 ybrs-mt-8">
                        <h1 className="ybrs-text-primary ybrs-text-[32px] ybrs-font-bold">
                          {satuanKerja?.title
                            ? t(satuanKerja.title)
                            : "Tidak ada data"}
                        </h1>
                        <p className="ybrs-text-justify">
                          {satuanKerja?.description
                            ? t(satuanKerja.description)
                            : "Tidak ada data"}
                        </p>
                        <h2 className="ybrs-text-primary ybrs-text-[24px] ybrs-font-bold">
                          {t("visi")}
                        </h2>
                        <p className="ybrs-text-base">
                          {satuanKerja?.visi
                            ? t(satuanKerja.visi)
                            : "Tidak ada data"}
                        </p>
                        <h2 className="ybrs-text-primary ybrs-text-[24px] ybrs-font-bold">
                          {t("misi")}
                        </h2>
                        {satuanKerja?.misi &&
                            satuanKerja?.misi?.length > 0 && (
                              <ul className="list-style-one clearfix ybrs-mt-3">
                                {satuanKerja?.misi?.map(
                                  (misi: string,index) => (  
                                    <li key={index}> {t(misi)}</li>
                                  )
                                )}
                              </ul>
                            )}

                        {satuanKerja?.nilai &&
                          satuanKerja?.nilai?.length > 0 && (
                            <h2 className="ybrs-text-primary ybrs-text-[24px] ybrs-font-bold">
                              {t("nilai")} :
                            </h2>
                          )}
                        {satuanKerja?.nilai &&
                          satuanKerja?.nilai?.length > 0 && (
                            <ul className="list-style-one clearfix ybrs-mt-3">
                              {satuanKerja?.nilai.map(
                                (nilai: string) => (
                                  <li>{t(nilai)}</li>
                                )
                              )}
                            </ul>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="post-tags">
                  <ul className="tags-list clearfix">
                    <li>
                      <h3>Website:</h3>
                      <a href={satuanKerja?.link} target="_blank">
                        {satuanKerja?.title ? t(satuanKerja.title) : ""}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SatuanKerjaDetail;
