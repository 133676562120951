import { Form, useParams } from "react-router-dom";
import useUpdateArticle from "./useUpdateArticle";
import FormikImageField from "@/components/formik/FormikImageField";
import FormikRichText from "@/components/formik/FormikRichText";
import FormikSwitchField from "@/components/formik/FormikSwitchField";
import FormikTextField from "@/components/formik/FormikTextField";
import { ArrowCircleLeftOutlined } from "@mui/icons-material";
import {
  Box,
  Card,
  CardHeader,
  IconButton,
  CircularProgress,
  CardContent,
  Button,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import TagPreview from "../../components/TagPreview";
import { ArticleFormModel } from "../../types/ArticleModel";

const UpdateArticle = () => {
  const { id } = useParams();
  const { validationSchema, mutation, navigate, initialValues, articleData } =
    useUpdateArticle(Number(id));
  return (
    <Formik<ArticleFormModel>
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        values.Tags = values.Tags.toLowerCase();
        mutation.mutate(values);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: 2, sm: 3, md: 4 },
            width: { xs: "90%", sm: "85%", md: "90%" },
            marginX: "auto",
            marginTop: {
              xs: "4rem",
              sm: "6rem",
              md: "8rem",
              lg: "10rem",
              xl: "12rem",
            },
            paddingX: { xs: 2, sm: 3, md: 4 },
          }}
        >
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3.5} xl={3}>
                <Card>
                  <CardHeader
                    style={{ paddingBottom: 0 }}
                    avatar={
                      <IconButton size="small" onClick={() => navigate(-1)}>
                        <ArrowCircleLeftOutlined />
                      </IconButton>
                    }
                    title="Tambah Artikel"
                    titleTypographyProps={{
                      fontSize: 24,
                    }}
                    subheader="Formulir Artikel"
                    subheaderTypographyProps={{
                      fontSize: 16,
                    }}
                    action={
                      mutation.isPending ? (
                        <CircularProgress size="1.5rem" />
                      ) : null
                    }
                  />
                  <CardContent
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <FormikTextField
                      name="Judul"
                      label="Judul"
                      placeholder="Masukkan judul artikel"
                      disabled={mutation.isPending}
                    />
                    <FormikTextField
                      name="Tags"
                      label="Tags"
                      placeholder="Masukkan tag ex: Health, Care, Hospital"
                      disabled={mutation.isPending}
                    />
                    <TagPreview
                      tags={values.Tags}
                      onDelete={(tag) => {
                        const newTags = values.Tags.split(",")
                          .filter((t) => t !== tag)
                          .join(",");
                        setFieldValue("Tags", newTags);
                      }}
                    />
                    <FormikImageField
                      name="ImageFile"
                      label="Thumbnail"
                      defaultSource={articleData?.imageFilePath ?? null}
                      disabled={mutation.isPending}
                    />

                    <Box
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <FormikSwitchField
                        name="IsPublish"
                        label="Publish"
                        disabled={mutation.isPending}
                      />
                    </Box>
                    <Button
                      disabled={mutation.isPending}
                      startIcon={
                        mutation.isPending ? (
                          <CircularProgress size="1rem" />
                        ) : null
                      }
                      sx={{ mt: 2 }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Submit
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={8.5} xl={9}>
                <Card>
                  <CardContent
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <FormikRichText
                      name="Deskripsi"
                      label="Deskripsi"
                      disabled={mutation.isPending}
                      height={500}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default UpdateArticle;
