import { Card, CardContent, Typography, CardActions, Box } from "@mui/material";
import HeroImagesAction from "./HeroImagesAction";
import { HeroImagesModel } from "../types/HeroImagesModel";
import ImagePreview from "@/components/media/ImagePreview";

interface HeroImagesKanbanProps {
  index: number;
  row: HeroImagesModel;
}

const HeroImagesKanban = ({ index, row }: HeroImagesKanbanProps) => {
  return (
    <Card
      key={index}
      elevation={1}
      sx={{ width: "100%", position: "relative", height: "100%" }}
    >
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box position="relative">
          <Box
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              color: "white",
              padding: "4px 8px",
              borderRadius: "4px",
              zIndex: 10,
            }}
          >
            {row.isActive ? "Active" : "Inactive"}
          </Box>
          <ImagePreview
            imageStyle={{
              width: "100%",
              height: "320px",
              objectFit: "cover",
            }}
            src={row.imageFilePath}
            alt={row.id.toString()}
          />
          <Typography variant="body1">{row.deskripsi}</Typography>
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <HeroImagesAction data={row} />
      </CardActions>
    </Card>
  );
};

export default HeroImagesKanban;
