import { Box, IconButton, InputLabel, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import ImagePreview from "../media/ImagePreview";
import { AddAPhoto, DeleteOutline, Preview } from "@mui/icons-material";

export interface DefaultSource {
  id?: number;
  source: string;
}

interface FormikMultipleImageFieldProps {
  name: string;
  label?: string;
  disabled?: boolean;
  size?: number;
  defaultSource?: DefaultSource[];
  deleteIdsName?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormikMultipleImageField = <T,>({
  name,
  disabled = false,
  label = "Label",
  size = 200,
  defaultSource = [],
  deleteIdsName = "deleteIds",
  onChange,
}: FormikMultipleImageFieldProps) => {
  const { setFieldValue, values } = useFormikContext<T>();
  const [imagePreviews, setImagePreviews] = React.useState<string[]>([]);
  const [imageDefaultPreviews, setImageDefaultPreviews] = React.useState<
    DefaultSource[]
  >([]);

  const [files, setFiles] = React.useState<File[]>([]);

  React.useEffect(() => {
    if (defaultSource && defaultSource.length > 0) {
      setImageDefaultPreviews(defaultSource);
    }
  }, [defaultSource]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    const filesArray = target.files ? Array.from(target.files) : [];
    if (filesArray.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...filesArray]);
      setFieldValue(name, [...files, ...filesArray]);
      const previews = filesArray.map((file) => URL.createObjectURL(file));
      setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);

      if (onChange) {
        onChange(event);
      }
    }
  };
  const handleDeleteDefaultImage = (index: number) => {
    setFieldValue(deleteIdsName, [
      ...((values[deleteIdsName as keyof T] as number[]) || []),
      imageDefaultPreviews[index].id ?? 0,
    ]);
    setImageDefaultPreviews((prevPreviews) => {
      const updatedPreviews = prevPreviews.filter((_, i) => i !== index);
      return updatedPreviews;
    });
  };
  const handleDeleteImage = (index: number) => {
    setFieldValue(
      name,
      (values[name as keyof T] as File[]).filter(
        (_: any, i: number) => i !== index
      )
    );

    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i !== index); // Menghapus file yang sesuai
      return updatedFiles;
    });
    setImagePreviews((prevPreviews) => {
      const updatedPreviews = prevPreviews.filter((_, i) => i !== index);
      return updatedPreviews;
    });
  };

  // const handleImageReorder = (fromIndex: number, toIndex: number) => {
  //   const reorderedPreviews = Array.from(imagePreviews);
  //   const reorderedFiles = Array.from(files);
  //   const [movedImage] = reorderedPreviews.splice(fromIndex, 1);
  //   const [movedFile] = reorderedFiles.splice(fromIndex, 1);

  //   reorderedPreviews.splice(toIndex, 0, movedImage);
  //   reorderedFiles.splice(toIndex, 0, movedFile);

  //   setImagePreviews(reorderedPreviews);
  //   setFiles(reorderedFiles);
  //   setFieldValue(name, reorderedFiles);
  // };

  const totalFileSize = useMemo(() => {
    return files.reduce((acc, file) => acc + file.size, 0);
  }, [files]);
  const handleFileSize = (index: number) => {
    if (files[index]) {
      return (files[index].size / (1024 * 1024)).toFixed(2);
    }
    return "0.00";
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <InputLabel>{label}</InputLabel>
        <Typography variant="body2" color="textSecondary">
          {defaultSource.length === 0 && (
            <span>
              Total Upload: {(totalFileSize / (1024 * 1024)).toFixed(2)} MB{" "}
            </span>
          )}
          ({imagePreviews.length + imageDefaultPreviews.length} files)
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 1,
          display: "grid",
          gridTemplateColumns: `repeat(auto-fill, minmax(${size}px, 1fr))`,
          gap: "10px",
        }}
      >
        {imageDefaultPreviews.map((preview, index) => (
          <Box
            key={index}
            sx={{
              height: `${size}px`,
              position: "relative",
              cursor: "move",
            }}
          >
            <ImagePreview src={preview.source} alt={`preview-${index}`} />
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                position: "absolute",
                bottom: 5,
                left: 5,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                color: "white",
                padding: "2px 5px",
                borderRadius: "3px",
              }}
            >
              Current
            </Typography>
            <Box
              sx={{
                position: "absolute",
                top: 5,
                left: 5,
                width: "30px",
                height: "30px",
                backgroundColor: "primary.main",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                padding: "5px",
                zIndex: 10,
                "&:hover": {
                  backgroundColor: "primary.main",
                },
              }}
            >
              {index + 1}
            </Box>
            <IconButton
              onClick={() => handleDeleteDefaultImage(index)}
              sx={{
                position: "absolute",
                top: 5,
                right: 5,
                backgroundColor: "error.main",
                borderRadius: "50%",
                padding: "5px",
                zIndex: 10,
                "&:hover": {
                  backgroundColor: "error.main",
                },
              }}
            >
              <DeleteOutline sx={{ fontSize: "24px", color: "white" }} />
            </IconButton>
          </Box>
        ))}
        {imagePreviews.map((preview, index) => (
          <Box
            key={index}
            sx={{
              height: `${size}px`,
              position: "relative",
              cursor: "move",
            }}
          >
            <ImagePreview src={preview} alt={`preview-${index}`} />
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                position: "absolute",
                bottom: 5,
                left: 5,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                color: "white",
                padding: "2px 5px",
                borderRadius: "3px",
              }}
            >
              {handleFileSize(index)} MB
            </Typography>
            <IconButton
              onClick={() => handleDeleteImage(index)}
              sx={{
                position: "absolute",
                top: 5,
                right: 5,
                backgroundColor: "error.main",
                borderRadius: "50%",
                padding: "5px",
                zIndex: 10,
                "&:hover": {
                  backgroundColor: "error.main",
                },
              }}
            >
              <DeleteOutline sx={{ fontSize: "24px", color: "white" }} />
            </IconButton>
          </Box>
        ))}
        <Box
          sx={{
            border: "1px dashed gray",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: `${size}px`,
            cursor: "pointer",
          }}
          onClick={() =>
            document.getElementById("upload-multiple-images")?.click()
          }
        >
          <input
            type="file"
            accept="image/*"
            multiple
            disabled={disabled}
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="upload-multiple-images"
          />
          <AddAPhoto sx={{ fontSize: 40, color: "gray" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default FormikMultipleImageField;
