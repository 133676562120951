import { useNavigate } from "react-router-dom";
import ArticleService from "../../services/ArticleService";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { ArticleFormModel } from "../../types/ArticleModel";
import { useSnackbar } from "@/hooks/useSnackbar";
const useCreateArticle = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const articleService = new ArticleService();
  const validationSchema = Yup.object().shape({
    Judul: Yup.string().required("Judul wajib diisi"),
    Deskripsi: Yup.string().required("Deskripsi wajib diisi"),
    Tags: Yup.string().required("Tags wajib diisi"),
    IsPublish: Yup.boolean().required("IsPublish wajib diisi"),
    ImageFile: Yup.mixed().nullable().required("ImageFile wajib diisi"),
  });
  const mutation = useMutation({
    mutationFn: (data: ArticleFormModel) => articleService.create(data),
    onSuccess: () => {
      showSnackbar("Article berhasil ditambahkan", "success");
      navigate(-1);
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  return {
    validationSchema,
    mutation,
    navigate,
  };
};

export default useCreateArticle;
