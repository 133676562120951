import MiniHero from "@/components/minihero";

import KegiatanItem from "../../components/KegiatanItem";
import { useTranslation } from "react-i18next";
import { Pagination } from "@mui/material";
import useAllKegiatan from "./useAllKegiatan";
import FormikTextField from "@/components/formik/FormikTextField";
import { Formik } from "formik";
import { debounce } from "lodash";
const KegiatanPage = () => {
  const { t } = useTranslation("informasi");
  const { t: tGeneral } = useTranslation("general");
  const {
    listKegiatan,
    handlePaginationChange,
    totalRows,
    currentPage,
    handleSearch,
  } = useAllKegiatan();
  return (
    <>
      <MiniHero
        title="Kumpulan Kegiatan"
        breadcrumb={[
          { name: tGeneral("beranda"), link: "/" },
          { name: t("kumpulan_kegiatan") },
        ]}
      />
      <section className="news-section sec-pad bg-color-1">
        <div className="auto-container">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div className="ybrs-w-full md:ybrs-w-[30rem]">
            <Formik initialValues={{ search: "" }} onSubmit={handleSearch}>
              {({ setFieldValue, handleSubmit }) => (
                <FormikTextField
                  label="Cari Kegiatan"
                  name="search"
                  fullWidth
                  placeholder="Cari Kegiatan"
                  onChange={debounce((e) => {
                    setFieldValue("search", e.target.value);
                    handleSubmit();
                  }, 300)}
                />
              )}
              </Formik>
            </div>
          </div>
          <div className="row clearfix ybrs-mt-3">
            {listKegiatan.map((kegiatan, index) => (
              <KegiatanItem kegiatan={kegiatan} index={index} />
            ))}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              count={totalRows}
              page={currentPage}
              onChange={(_, newPage: number) => handlePaginationChange(newPage)}
            />
          </div>
          {/* <div className="pagination-wrapper mt_20 centred">
            <ul className="pagination clearfix">
              {currentPage > 1 && (
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePaginationChange(currentPage - 1);
                    }}
                  >
                    <i className="icon-36"></i>
                  </a>
                </li>
              )}
              {Array.from({ length: totalRows }, (_, index) => (
                <li key={index}>
                  <a
                    className={currentPage === index + 1 ? "current" : ""}
                    onClick={() => handlePaginationChange(index + 1)}
                  >
                    {index + 1}
                  </a>
                </li>
              ))}
              {currentPage < totalRows && (
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePaginationChange(currentPage + 1);
                    }}
                  >
                    <i className="icon-36"></i>
                  </a>
                </li>
              )}
            </ul>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default KegiatanPage;
