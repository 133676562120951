import MiniHero from "@/components/minihero";

import ArticleItem from "../../components/ArticleItem";
import { useTranslation } from "react-i18next";
import useAllArticle from "./useAllArtikel";
import { debounce, Pagination } from "@mui/material";
import FormikTextField from "@/components/formik/FormikTextField";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";
import toPascalCase from "@/utility/pascalCase";

const ArticlesPage = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const queryParams = useQuery();
  const tag = queryParams.get("tag");

  const {
    listArticle,
    handlePaginationChange,
    totalRows,
    currentPage,
    handleSearch,
  } = useAllArticle(tag);
  const { t } = useTranslation("informasi");
  const { t: tGeneral } = useTranslation("general");
  return (
    <>
      <MiniHero
        title={t("kumpulan_artikel")}
        breadcrumb={[
          { name: tGeneral("beranda"), link: "/" },
          { name: t("kumpulan_artikel") },
        ]}
      />
      <section className="news-section sec-pad bg-color-1">
        <div className="auto-container">
          <div className="ybrs-flex ybrs-justify-content-between ybrs-align-items-center ybrs-w-100 ybrs-flex-col md:ybrs-flex-row ybrs-justify-between ybrs-items-start md:ybrs-items-center ">
            {tag ? (
              <h1 className="ybrs-text-2xl md:ybrs-text-3xl lg:ybrs-text-4xl ybrs-font-bold">
                Tag : #{toPascalCase(tag)}
              </h1>
            ) : (
              <div></div>
            )}
            <div className="ybrs-w-full md:ybrs-w-[30rem]">
              <Formik initialValues={{ search: "" }} onSubmit={handleSearch}>
                {({ setFieldValue, handleSubmit }) => (
                  <FormikTextField
                  label="Cari Artikel"
                  name="search"
                  fullWidth
                  placeholder="Cari Artikel"
                  onChange={debounce((e) => {
                    setFieldValue("search", e.target.value);
                    handleSubmit();
                  }, 300)}
                />
              )}
            </Formik>
            </div>
          </div>

          <div className="row clearfix ybrs-mt-3">
            {listArticle.map((article, index) => (
              <ArticleItem article={article} index={index} />
            ))}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              count={totalRows}
              page={currentPage}
              onChange={(_, newPage: number) => handlePaginationChange(newPage)}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ArticlesPage;
