import { useNavigate } from "react-router-dom";
import { KegiatanModel } from "../../types/KegiatanModel";
import { ColumnType } from "@/components/table/types/ColumnModel";
import { PostQuery } from "@/types/PostQuery";
import ImagePreview from "@/components/media/ImagePreview";
import KegiatanService from "../../services/KegiatanService";
import { CalendarToday } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";
import KegiatanAction from "../../components/KegiatanAction";

const useListKegiatan = () => {
  const uniqKey = "kegiatan";
  const kegiatanService = new KegiatanService();
  const navigate = useNavigate();
  // const { showConfirmationDialog } = useConfirmationDialog();
  const columns: ColumnType<KegiatanModel>[] = [
    { field: "judul", headerName: "Judul", width: 200, type: "string" },
    {
      field: "thumbnail",
      headerName: "Thumbnail",
      hideFilter: true,
      hideSort: true,
      renderCell: (row: KegiatanModel) => (
        <ImagePreview
          imageStyle={{ width: "120px", height: "120px", objectFit: "cover" }}
          src={row.imageFilePath}
          alt="kegiatan"
        />
      ),
    },
    {
      field: "tanggal",
      headerName: "Tanggal",
      type: "date",
      renderCell: (row: KegiatanModel) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <CalendarToday />
          {new Date(row.tanggal).toLocaleDateString("id-ID")}
        </Box>
      ),
    },
    {
      field: "isPublish",
      headerName: "Is Publish",
      type: "boolean",
      renderCell: (row: KegiatanModel) => (
        <Chip label={row.isPublish ? "Publish" : "Draft"} />
      ),
    },
    {
      field: "actions",
      type: "actions",
    
      headerName: "Actions",
      renderCell: (data: KegiatanModel) => {
        return <KegiatanAction data={data} />;
      },
    },
  ];
  const handleAddButtonClick = () => {
    navigate("/cms-ybrs/kegiatan/tambah");
  };

  const service = (postQuery: PostQuery) => {
    return kegiatanService.all(postQuery);
  };
  return {
    uniqKey,
    columns,
    handleAddButtonClick,
    service,
  };
};

export default useListKegiatan;
