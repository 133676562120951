import shape7 from "@/assets/images/shape/shape-7.png";
import shape8 from "@/assets/images/shape/shape-8.png";
import shape9 from "@/assets/images/shape/shape-9.png";
import shape10 from "@/assets/images/shape/shape-10.png";
import shape11 from "@/assets/images/shape/shape-11.png";
import news2 from "@/assets/images/news/news-2.jpg";
import aboutImg1 from "@/assets/images/resource/about-1.jpg";
import logo from "@/assets/images/logo.png";
import MiniHero from "@/components/minihero";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import {
  DefaultAudioLayout,
  defaultLayoutIcons,
} from "@vidstack/react/player/layouts/default";
import audio from "@/assets/audio/mars-ybrsgkp.mp3";
import video from "@/assets/video/mars-ybrsgkp.mp4";
import { useEffect } from "react";
import { scroller } from "react-scroll";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const scrollToSection = (section: string) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const { hash } = useLocation();

  useEffect(() => {
    scrollToSection(hash.substring(1));
  }, [hash]);
  const { t } = useTranslation("about");
  const { t: tGeneral } = useTranslation("general");

  return (
    <>
      <MiniHero
        title={t("tentang_kami")}
        breadcrumb={[
          { name: tGeneral("beranda"), link: "/" },
          { name: t("tentang_kami") },
        ]}
      />
      <section
        id="arti-logo"
        className="about-section pt_120 pb_120 bg-color-1"
      >
        <div className="pattern-layer">
          <div
            className="pattern-1 rotate-me"
            style={{ backgroundImage: `url(${shape8})` }}
          ></div>
          <div
            className="pattern-2 rotate-me"
            style={{ backgroundImage: `url(${shape8})` }}
          ></div>
          <div
            className="pattern-3 rotate-me"
            style={{ backgroundImage: `url(${shape9})` }}
          ></div>
          <div
            className="pattern-4"
            style={{ backgroundImage: `url(${shape10})` }}
          ></div>
          <div
            className="pattern-5"
            style={{ backgroundImage: `url(${shape11})` }}
          ></div>
        </div>
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_one">
                <div className="image-box">
                  <div
                    className="shape float-bob-x"
                    style={{ backgroundImage: `url(${shape7})` }}
                  ></div>
                  <figure className="">
                    <img src={logo} alt="" />
                  </figure>
                  {/* <div className="icon-one">
                  <i className="icon-13"></i>
                </div>
                <div className="icon-two">
                  <i className="icon-14"></i>
                </div> */}
                  {/* <div className="text-box">
                  <h3>Wade Warren</h3>
                  <span>Medical Assistant</span>
                </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_one">
                <div className="content-box ml_30">
                  <div className="sec-title mb_15">
                    <span className="sub-title">YBRS GKP</span>
                    <h2>{t("arti_logo")}</h2>
                  </div>
                  <div className="text-box mb_40">
                    <p>
                      <span className="ybrs-font-bold ybrs-text-accent">
                        {t("arti_logo_makna_teks")}:
                      </span>
                      <br />
                      <ul className="list-style-one clearfix ybrs-mt-3">
                        <li>{t("arti_logo_makna_teks_1")}</li>
                        <li>{t("arti_logo_makna_teks_2")}</li>
                        <li>{t("arti_logo_makna_teks_3")}</li>
                        <li>{t("arti_logo_makna_teks_4")}</li>
                      </ul>
                    </p>
                    <p>
                      <span className="ybrs-font-bold ybrs-text-accent">
                        Makna Warna:
                      </span>
                      <br />
                      <ul className="list-style-one clearfix ybrs-mt-3">
                        <li>{t("makna_warna_1")}</li>
                        <li>{t("makna_warna_2")}</li>
                        <li>{t("makna_warna_3")}</li>
                      </ul>
                    </p>
                  </div>
                  {/* <div className="btn-box">
                  <Link className="secondary-btn btn-one" to="/tentang-kami">
                    <span>Tentang Kami</span>
                  </Link>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="visi-misi" className="about-style-two pt_120 pb_120">
        <div className="pattern-layer">
          <div
            className="pattern-1 rotate-me"
            style={{ backgroundImage: `url(${shape8})` }}
          ></div>
          <div
            className="pattern-2 rotate-me"
            style={{ backgroundImage: `url(${shape8})` }}
          ></div>
          <div
            className="pattern-3 rotate-me"
            style={{ backgroundImage: `url(${shape9})` }}
          ></div>
          <div
            className="pattern-4"
            style={{ backgroundImage: `url(${shape10})` }}
          ></div>
          <div
            className="pattern-5"
            style={{ backgroundImage: `url(${shape11})` }}
          ></div>
        </div>
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_one">
                <div className="image-box">
                  <div
                    className="shape float-bob-x"
                    style={{ backgroundImage: `url(${shape7})` }}
                  ></div>
                  <figure className="image">
                    <img src={news2} alt="" />
                  </figure>
                  <div className="icon-one">
                    <i className="icon-13"></i>
                  </div>
                  <div className="icon-two">
                    <i className="icon-14"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_one">
                <div className="content-box ml_30">
                  <div className="sec-title mb_15">
                    <span className="sub-title">YBRS GKP</span>
                    <h2>{t("visi_misi")}</h2>
                  </div>
                  <div className="text-box mb_40">
                    <p>
                      <span className="ybrs-font-bold ybrs-text-accent">
                        {t("visi")}
                      </span>
                      <br />
                      {t("visi_desc")}
                    </p>
                    <span className="ybrs-font-bold ybrs-text-accent">
                      {t("misi")}
                    </span>
                    <br />
                    <div className="ybrs-mt-3 ybrs-list-decimal ybrs-list-inside">
                      <div className="ybrs-flex ybrs-items-start">
                        <span>1.</span>
                        <p className="ybrs-ml-2">{t("misi_1")}</p>
                      </div>
                      <div className="ybrs-flex ybrs-items-start">
                        <span>2.</span>
                        <p className="ybrs-ml-2">{t("misi_2")}</p>
                      </div>
                      <div className="ybrs-flex ybrs-items-start">
                        <span>3.</span>
                        <p className="ybrs-ml-2">{t("misi_3")}</p>
                      </div>
                      <div className="ybrs-flex ybrs-items-start">
                        <span>4.</span>
                        <p className="ybrs-ml-2">{t("misi_4")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="sejarah" className="about-style-two pt_120 pb_120">
        <div className="pattern-layer">
          <div
            className="pattern-1 rotate-me"
            style={{ backgroundImage: `url(${shape8})` }}
          ></div>
          <div
            className="pattern-2 rotate-me"
            style={{ backgroundImage: `url(${shape8})` }}
          ></div>
          <div
            className="pattern-3 rotate-me"
            style={{ backgroundImage: `url(${shape9})` }}
          ></div>
          <div
            className="pattern-4"
            style={{ backgroundImage: `url(${shape10})` }}
          ></div>
          <div
            className="pattern-5"
            style={{ backgroundImage: `url(${shape11})` }}
          ></div>
        </div>
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_one">
                <div className="image-box">
                  <div
                    className="shape float-bob-x"
                    style={{ backgroundImage: `url(${shape7})` }}
                  ></div>
                  <figure className="image">
                    <img src={aboutImg1} alt="" />
                  </figure>
                  <div className="icon-one">
                    <i className="icon-13"></i>
                  </div>
                  <div className="icon-two">
                    <i className="icon-14"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_one">
                <div className="content-box ml_30">
                  <div className="sec-title mb_15">
                    <span className="sub-title">{t("tentang_kami")}</span>
                    <h2>{t("sejarah")}</h2>
                  </div>
                  <div className="text-box mb_40">
                    <p>{t("sejarah_1")}</p>
                    <p>{t("sejarah_2")}</p>
                    <p>{t("sejarah_3")}</p>
                    <p>{t("sejarah_4")}</p>
                    <p>{t("sejarah_5")}</p>
                    <p>{t("sejarah_6")}</p>
                    <p>{t("sejarah_7")}</p>
                    <p>{t("sejarah_8")}</p>
                    <p>{t("sejarah_9")}</p>
                    <p>{t("sejarah_10")}</p>
                    <p>{t("sejarah_11")}</p>
                    <p>{t("sejarah_12")}</p>

                    {/* <h3 className="ybrs-font-bold ybrs-text-accent">
                      Visi & Misi YBRS GKP:
                    </h3>
                    <br />
                    <p>
                      <span className="ybrs-font-bold ybrs-text-secondary">
                        Visi
                      </span>
                      <br />
                      Menjadi penyelenggara pelayanan Kesehatan yang tumbuh dan
                      berkembang secara profesional etis berlandaskan kasih
                      Allah
                    </p>
                    <span className="ybrs-font-bold ybrs-text-secondary">
                      Misi
                    </span>
                    <br />
                    <div className="ybrs-mt-3 ybrs-list-decimal ybrs-list-inside">
                      <div className="ybrs-flex ybrs-items-start">
                        <span>1.</span>
                        <p className="ybrs-ml-2">
                          Mendorong pelaksanaan kebijakan pengelolaan dan
                          pengembangan unit pelayanan kesehatan secara
                          komprehensif, profesional, etis bertumbuh dan
                          berkembang serta berkesinambungan, berlandaskan Kasih
                          Allah.
                        </p>
                      </div>
                      <div className="ybrs-flex ybrs-items-start">
                        <span>2.</span>
                        <p className="ybrs-ml-2">
                          Menyelenggarakan program pendidikan profesi medis dan
                          kesehatan serta diklat SDM untuk meningkatkan kualitas
                          keunggulan kompetensi
                        </p>
                      </div>
                      <div className="ybrs-flex ybrs-items-start">
                        <span>3.</span>
                        <p className="ybrs-ml-2">
                          Mengkoordinasikan kegiatan sosial kemanusiaan yang
                          bersinergi dengan program gereja, pemerintah dan
                          Lembaga sosial lainnya sebagai wujud ucapan syukur
                          atas berkat Allah
                        </p>
                      </div>
                      <div className="ybrs-flex ybrs-items-start">
                        <span>4.</span>
                        <p className="ybrs-ml-2">
                          Memfasilitasi Kerjasama kemitraan untuk pengembangan
                          pelayanan unggulan dan terpandang di lingkup local,
                          nasional, maupun international
                        </p>
                      </div>
                    </div> */}
                    <ul className="list-style-one clearfix">
                      {/* <li>Layanan 24 Jam</li>
                      <li>Rawat Inap dan Rawat Jalan</li>
                      <li>Home Care</li>
                      <li>Klinik</li> */}
                      <li>{t("rumah_sakit")}</li>
                      <li>{t("klinik")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="rambu-utama" className="ybrs-bg-gray-100 ybrs-py-12">
        <div className="ybrs-container ybrs-mx-auto">
          <div className="ybrs-text-center ybrs-mb-8 ybrs-text-black">
            <img
              src={logo}
              alt="Logo"
              className="ybrs-mx-auto ybrs-mb-4 ybrs-w-16"
            />
            <h2 className="ybrs-text-[42px] md:ybrs-text-[60px]  ybrs-font-semibold ybrs-text-black">
              {t("rambu_utama")}
            </h2>
            <p className="ybrs-text-[16px] md:ybrs-text-[32px]  ybrs-text-black ">
              {t("penyelenggaraan")}
            </p>
            <p className="ybrs-text-[16px] md:ybrs-text-[32px]  ybrs-text-black ">
              {t("bidang_keselahan")}
            </p>
            <p className="ybrs-text-[16px] md:ybrs-text-[32px]  ybrs-text-black ">
              {t("yayasan")}
            </p>
          </div>
          <div className="ybrs-grid ybrs-grid-cols-1 ybrs-gap-4 ybrs-mb-8">
            <div className="ybrs-bg-yellow-300 ybrs-p-4 ybrs-text-center ybrs-border ybrs-border-black">
              <h3 className="ybrs-font-bold ybrs-text-2xl">
                {t("budaya_kerja")}
              </h3>
              <p className="ybrs-text-lg ybrs-text-black">
                {t("good_corporate_governance")} &{" "}
                {t("good_clinical_governance")}
              </p>
            </div>
            <div className="ybrs-grid ybrs-grid-cols-1 md:ybrs-grid-cols-2 lg:ybrs-grid-cols-3 xl:ybrs-grid-cols-5 ybrs-gap-4">
              <div className="ybrs-bg-[#00af50] ybrs-p-4 ybrs-text-center ybrs-border ybrs-border-black">
                <h4 className="ybrs-font-bold ybrs-text-white">
                  {t("empathetic")}
                </h4>
                <ul className=" ybrs-text-white ybrs-mt-3">
                  <li className="ybrs-list-disc ybrs-list-inside">
                    {t("respectful")}
                  </li>
                  <li className="ybrs-list-disc ybrs-list-inside">
                    {t("care")}
                  </li>
                  <li className="ybrs-list-disc ybrs-list-inside">
                    {t("sacrifice")}
                  </li>
                </ul>
              </div>
              <div className="ybrs-bg-[#c05045] ybrs-p-4 ybrs-text-center ybrs-border ybrs-border-black">
                <h4 className="ybrs-font-bold ybrs-text-white">
                  {t("credible")}
                </h4>
                <ul className="ybrs-text-white ybrs-mt-3">
                  <li className="ybrs-list-disc ybrs-list-inside">
                    {t("agility")}
                  </li>
                  <li className="ybrs-list-disc ybrs-list-inside">
                    {t("caring")}
                  </li>
                  <li className="ybrs-list-disc ybrs-list-inside">
                    {t("accurate")}
                  </li>
                </ul>
              </div>
              <div className="ybrs-bg-[#30859a] ybrs-p-4 ybrs-text-center ybrs-border ybrs-border-black">
                <h4 className="ybrs-font-bold ybrs-text-white">{t("clean")}</h4>
                <ul className="ybrs-text-white ybrs-mt-3">
                  <li className="ybrs-list-disc ybrs-list-inside">
                    {t("safe")}
                  </li>
                  <li className="ybrs-list-disc ybrs-list-inside">
                    {t("secure")}
                  </li>
                  <li className="ybrs-list-disc ybrs-list-inside">
                    {t("solutive")}
                  </li>
                </ul>
              </div>
              <div className="ybrs-bg-[#bf9100] ybrs-p-4 ybrs-text-center ybrs-border ybrs-border-black">
                <h4 className="ybrs-font-bold ybrs-text-white">
                  {t("creative")}
                </h4>
                <ul className="ybrs-text-white ybrs-mt-3">
                  <li className="ybrs-list-disc ybrs-list-inside">
                    {t("innovative")}
                  </li>
                  <li className="ybrs-list-disc ybrs-list-inside">
                    {t("resilient")}
                  </li>
                  <li className="ybrs-list-disc ybrs-list-inside">
                    {t("solutive")}
                  </li>
                </ul>
              </div>
              <div className="ybrs-bg-[#d79692] ybrs-p-4 ybrs-text-center ybrs-border ybrs-border-black">
                <h4 className="ybrs-font-bold ybrs-text-white">
                  {t("synergic")}
                </h4>
                <ul className="ybrs-text-white ybrs-mt-3">
                  <li className="ybrs-list-disc ybrs-list-inside">
                    {t("sense_of_belonging")}
                  </li>
                  <li className="ybrs-list-disc ybrs-list-inside">
                    {t("collaborative")}
                  </li>
                  <li className="ybrs-list-disc ybrs-list-inside">
                    {t("effective")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="ybrs-bg-[#ffc000] ybrs-p-4  ybrs-text-center ybrs-mb-8">
            <h3 className="ybrs-font-bold ybrs-text-2xl">{t("etos_kerja")}</h3>
            <div className="ybrs-border ybrs-border-black ybrs-bg-white  ybrs-p-4 ybrs-mb-2 ybrs-mt-3 ">
              <p className="ybrs-font-bold ybrs-text-black">
                1. {t("kerja_adalah_anugerah_tuhan")}
              </p>
              <p className="ybrs-text-black">
                ({t("panggilan")}, {t("kepercayaan")}, {t("amanat_tuhan")})
              </p>
            </div>
            <div className="ybrs-border ybrs-border-black ybrs-mb-2 ybrs-bg-white ybrs-p-4">
              <p className="ybrs-font-bold ybrs-text-black">
                2. {t("kerja_adalah_pengabdian_dan_aktualisasi_diri")}
              </p>
              <p className="ybrs-text-black">
                ({t("kepada_depan_tuhan")}, {t("sesama")}, {t("lembaga")},{" "}
                {t("keluarga")})
              </p>
            </div>
            <div className="ybrs-border ybrs-border-black ybrs-bg-white ybrs-p-4">
              <p className="ybrs-font-bold ybrs-text-black">
                3. {t("kerja_adalah_saluran_berkat")}
              </p>
              <p className="ybrs-text-black">
                ({t("bagi_sesama")}, {t("diri_keluarga")}, {t("lembaga")})
              </p>
            </div>
          </div>
          <div className="ybrs-bg-[#7030a0]  ybrs-p-8 ybrs-text-center ybrs-mb-8 ybrs-border ybrs-border-black">
            <h3 className="ybrs-font-bold ybrs-text-[24px] md:ybrs-text-[32px] lg:ybrs-text-[40px] ybrs-text-white">
              {t("visi")}, {t("misi")}, {t("renstra")},{" "}
              {t("rencana_induk_pengembangan")}, {t("program_yayasan")}{" "}
              {t("brs_gkp")}
            </h3>
          </div>
          <div className="ybrs-text-center ybrs-mb-8 ybrs-text-black ybrs-border ybrs-border-black ybrs-p-4 ybrs-bg-[#f1dcdb]">
            <h3 className="ybrs-font-bold ybrs-text-2xl ybrs-text-black">
              {t("pilat_etis")}
            </h3>
            <div className="ybrs-grid ybrs-grid-cols-1 sm:ybrs-grid-cols-2 md:ybrs-grid-cols-3 lg:ybrs-grid-cols-4 xl:ybrs-grid-cols-5 ybrs-gap-4 ybrs-my-8">
              <div className="ybrs-bg-blue-200 ybrs-p-4 ybrs-flex ybrs-items-center ybrs-justify-center ybrs-text-center ybrs-border ybrs-border-black ybrs-h-[200px]">
                <h4 className="ybrs-font-bold ybrs-text-lg">
                  {t("inclusive")}
                </h4>
              </div>
              <div className="ybrs-bg-blue-200 ybrs-p-4 ybrs-flex ybrs-items-center ybrs-justify-center ybrs-text-center ybrs-border ybrs-border-black ybrs-h-[200px]">
                <h4 className="ybrs-font-bold ybrs-text-lg">{t("safety")}</h4>
              </div>
              <div className="ybrs-bg-blue-200 ybrs-p-4 ybrs-flex ybrs-items-center ybrs-justify-center ybrs-text-center ybrs-border ybrs-border-black ybrs-h-[200px]">
                <h4 className="ybrs-font-bold ybrs-text-lg">{t("holistic")}</h4>
              </div>
              <div className="ybrs-bg-blue-200 ybrs-p-4 ybrs-flex ybrs-items-center ybrs-justify-center ybrs-text-center ybrs-border ybrs-border-black ybrs-h-[200px]">
                <h4 className="ybrs-font-bold ybrs-text-lg">
                  {t("excellent")}
                </h4>
              </div>
              <div className="ybrs-bg-blue-200 ybrs-p-4 ybrs-flex ybrs-items-center ybrs-justify-center ybrs-text-center ybrs-border ybrs-border-black ybrs-h-[200px]">
                <h4 className="ybrs-font-bold ybrs-text-lg">{t("sustain")}</h4>
              </div>
            </div>
          </div>
          <div className="ybrs-text-center ybrs-mb-8 ybrs-border ybrs-border-black ybrs-p-4 ybrs-bg-[#fff2cd]">
            <h3 className="ybrs-font-bold ybrs-text-2xl ybrs-text-black">
              {t("dasar")}
            </h3>
            <div className="ybrs-bg-[#fe0000] ybrs-p-4 ybrs-mt-3">
              <h3 className="ybrs-font-bold ybrs-text-2xl ybrs-text-white">
                {t("kasih")}
              </h3>
            </div>
          </div>
        </div>
      </section>
      <section id="mars" className="about-section pt_120 pb_120 bg-color-1">
        <div className="pattern-layer">
          <div
            className="pattern-1 rotate-me"
            style={{ backgroundImage: `url(${shape8})` }}
          ></div>
          <div
            className="pattern-2 rotate-me"
            style={{ backgroundImage: `url(${shape8})` }}
          ></div>
          <div
            className="pattern-3 rotate-me"
            style={{ backgroundImage: `url(${shape9})` }}
          ></div>
          <div
            className="pattern-4"
            style={{ backgroundImage: `url(${shape10})` }}
          ></div>
          <div
            className="pattern-5"
            style={{ backgroundImage: `url(${shape11})` }}
          ></div>
        </div>
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_one">
                <MediaPlayer
                  title="Mars YBRS GKP"
                  poster={logo}
                  src={video}
                  controls
                  preload="auto"
                >
                  <MediaProvider />
                </MediaPlayer>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_one">
                <div className="content-box ml_30">
                  <div className="sec-title mb_15">
                    <span className="sub-title">YBRS GKP</span>
                    <h2>{t("mars_ybrs_gkp")}</h2>
                  </div>
                  <MediaPlayer title="Mars YBRS GKP" src={audio}>
                    <MediaProvider />
                    <DefaultAudioLayout icons={defaultLayoutIcons} />
                  </MediaPlayer>
                  <div className="text-box mb_40 ybrs-mt-8">
                    <div className="ybrs-flex ybrs-justify-end ybrs-text-base md:ybrs-text-lg ybrs-text-secondary">
                      <span className="ybrs-block">
                        Budi Tegas Priyanto 2019
                      </span>
                    </div>
                    <div className="ybrs-flex ybrs-flex-col ybrs-text-base md:ybrs-text-lg  ybrs-text-black  ybrs-gap-4  ybrs-w-[550px]">
                      <span className="ybrs-block ybrs-font-bold ybrs-text-base md:ybrs-text-lg  ybrs-text-accent">
                        Bait 1
                      </span>
                      <div className="ybrs-flex ybrs-flex-col ybrs-gap-4 ybrs-ml-3 md:ybrs-ml-6"> 
                        <span className="ybrs-block">
                          Pengelolaan dan pengembangan
                        </span>
                        <span className="ybrs-block">
                          Seluruh Pelayanan Kesehatan
                        </span>
                        <span className="ybrs-block">
                          Menjadi Pemersatu Rumah Sakit
                        </span>
                        <span className="ybrs-block">
                          'Tuk Meningkatkan Kualitas Unggul
                        </span>

                        <span className="ybrs-block ybrs-font-bold ybrs-text-[16px] ybrs-text-primary">
                          Refrein
                        </span>
                        <div className="ybrs-flex ybrs-flex-col ybrs-gap-4 ybrs-ml-3 md:ybrs-ml-6">
                          <span className="ybrs-block">
                            Yayasan Badan Rumah Sakit GKP
                          </span>
                          <span className="ybrs-block">
                            Penyelenggara Pelayanan Kesehatan
                          </span>
                          <span className="ybrs-block">
                            S'lalu Berkembang Secara Profesional
                          </span>
                          <span className="ybrs-block">
                            Etis dan Berlandaskan Kasih Allah
                          </span>
                        </div>
                      </div>
                      <span className="ybrs-block ybrs-font-bold ybrs-text-[14px] md:ybrs-text-[16px] md:ybrs-text-lg ybrs-text-accent ybrs-mt-3">
                        Bait 2
                      </span>
                      <div className="ybrs-flex ybrs-flex-col ybrs-gap-4 ybrs-ml-3 md:ybrs-ml-6">
                        <span className="ybrs-block">
                          Mengkoordinasi Kegiatan
                        </span>
                        <span className="ybrs-block">
                          Program Gereja dan Pemerintahan
                        </span>
                        <span className="ybrs-block">
                          Menyediakan Pelayanan unggul
                        </span>
                        <span className="ybrs-block">
                          Di Lingkup Nasional, Internasional
                        </span>

                        <span className="ybrs-block ybrs-font-bold ybrs-text-[14px] md:ybrs-text-[16px] ybrs-text-primary">
                          Refrein
                        </span>
                        <div className="ybrs-flex ybrs-flex-col ybrs-gap-4 ybrs-ml-3 md:ybrs-ml-6">
                          <span className="ybrs-block">
                            Yayasan Badan Rumah Sakit GKP
                          </span>
                          <span className="ybrs-block">
                            Penyelenggara Pelayanan Kesehatan
                          </span>
                          <span className="ybrs-block">
                            Slalu berkembang secara Profesional
                          </span>
                          <span className="ybrs-block">
                            Etis dan berlandaskan Kasih Allah
                          </span>
                        </div>
                        <span className="ybrs-block ybrs-font-bold ybrs-text-[14px] md:ybrs-text-[16px] ybrs-text-primary">
                          Coda
                        </span>
                        <div className="ybrs-flex ybrs-flex-col ybrs-gap-4 ybrs-ml-3 md:ybrs-ml-6">
                          <span className="ybrs-block">
                            Etis dan Berlandaskan Kasih Allah
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="btn-box">
                  <Link className="secondary-btn btn-one" to="/tentang-kami">
                    <span>Tentang Kami</span>
                  </Link>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutPage;
