import CreateKegiatan from "./pages/create";
import ListKegiatan from "./pages/list";
import { Route } from "@/types/RouteModel";
import UpdateKegiatan from "./pages/update";

const kegiatanRoutes: Route[] = [
  {
    path: "kegiatan",
    element: <ListKegiatan />,
  },
  {
    path: "kegiatan/tambah",
    element: <CreateKegiatan />,
  },
  {
    path: "kegiatan/ubah/:id",
    element: <UpdateKegiatan />,
  },
];

export default kegiatanRoutes;
