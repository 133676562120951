import { useSnackbar } from "@/hooks/useSnackbar";
import AuthService from "@/services/AuthService";
import useAuthStore from "@/stores/authStore";
import { LoginRequest } from "@/types/AuthModel";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import * as Yup from "yup";

const useLogin = () => {
  const authService = new AuthService();
  const { authenticate } = useAuthStore();
  const { showSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const brandName = import.meta.env.VITE_BRAND_NAME;
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const mutation = useMutation({
    mutationKey: ["login"],
    mutationFn: async (request: LoginRequest) => {
      const response = await authService.login(request);
      return response;
    },
    onSuccess: (data) => {
      showSnackbar(data.message, "success");
      authenticate(data.data);
    },
    onError: () => {
      showSnackbar("Login failed", "error");
    },
  });
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  return {
    showPassword,
    mutation,
    validationSchema,
    brandName,
    handleClickShowPassword,
  };
};
export default useLogin;
