import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import React from "react";

import { PostFilter } from "@/types/PostQuery";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { useFormikContext } from "formik";
import FormikTextField from "@/components/formik/FormikTextField";
import { InputType } from "../constants/filterConfig";
import FormikSwitchField from "@/components/formik/FormikSwitchField";

export function InputBetweenFilterField(props: {
  type: InputType;
  prefix?: string;
}) {
  const { type, prefix } = props;
  const { setFieldValue } = useFormikContext<PostFilter>();

  return (
    <div className="ybrs-flex ybrs-flex-col ybrs-gap-4">
      <FormikTextField
        name={`${prefix ? `${prefix}.` : ""}values[0]`}
        margin="none"
        label={`From ${type.charAt(0).toUpperCase() + type.slice(1)}`}
        placeholder={`Enter From`}
        type={type === "string" ? "text" : type}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = event.target.value;
          const value =
            type === "date" ? moment(newValue).format("YYYY-MM-DD") : newValue;
          setFieldValue("values[0]", value);
        }}
      />
      <FormikTextField
        name={`${prefix ? `${prefix}.` : ""}values[1]`}
        margin="none"
        placeholder={`Enter To`}
        label={`To ${type.charAt(0).toUpperCase() + type.slice(1)}`}
        type={type === "string" ? "text" : type}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = event.target.value;
          const value =
            type === "date" ? moment(newValue).format("YYYY-MM-DD") : newValue;
          setFieldValue("values[1]", value);
        }}
      />
    </div>
  );
}

export function InputFilterField(props: { type: InputType , prefix?: string}) {
  const { type , prefix} = props;
  const { setFieldValue } = useFormikContext<PostFilter>();
  return type === "boolean" ? (
    <FormikSwitchField
      name={`${prefix ? `${prefix}.` : ""}values[0]`}
      label={type.charAt(0).toUpperCase() + type.slice(1)}
      valueToString={true}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        setFieldValue(`${prefix ? `${prefix}.` : ""}values[0]`, newValue.toString());  
      }}
    />
  ) : (
    <FormikTextField
      name={`${prefix ? `${prefix}.` : ""}values[0]`}
      margin="none"
      label={type.charAt(0).toUpperCase() + type.slice(1)}
      placeholder={`Enter ${type.charAt(0).toUpperCase() + type.slice(1)}`}
      type={type === "string" ? "text" : type}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        const formattedValue =
          type === "date" ? moment(newValue).format("YYYY-MM-DD") : newValue;
        setFieldValue(`${prefix ? `${prefix}.` : ""}values[0]`, formattedValue);
      }}
    />
  );
}

export function DynamicInputFilterFields(props: { type: InputType , prefix?: string}) {
  const { type , prefix} = props;

  const { values, setFieldValue } = useFormikContext<PostFilter>();

  const addField = () => {
    const newValues = [...(values.values || []), ""];
    setFieldValue("values", newValues);
  };

  const deleteField = (index: number) => {
    const newValues = values.values?.filter((_, i) => i !== index) || [];
    setFieldValue("values", newValues);
  };

  return (
    <div className="ybrs-flex ybrs-flex-col ybrs-items-center ybrs-w-full ybrs-gap-4">
      {values.values?.map((_value, index) => (
        <div
          key={index}
          className="ybrs-flex ybrs-flex-row ybrs-items-center ybrs-w-full ybrs-gap-2"
        >
          <FormikTextField
            name={`${prefix ? `${prefix}.` : ""}values[${index}]`}
            margin="none"
            label={`${type.charAt(0).toUpperCase() + type.slice(1)} ${
              index + 1
            }`}
            placeholder={`Enter ${
              type.charAt(0).toUpperCase() + type.slice(1)
            }`}
            type={type === "string" ? "text" : type}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newValue = event.target.value;
              const formattedValue =
                type === "date"
                  ? moment(newValue).format("YYYY-MM-DD")
                  : newValue;
              setFieldValue(`${prefix ? `${prefix}.` : ""}values[${index}]`, formattedValue);
            }}
          />
          <IconButton
            color="error"
            onClick={() => {
              deleteField(index);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      ))}

      <IconButton
        color="primary"
        onClick={() => {
          addField();
        }}
      >
        <AddCircleOutlineOutlined />
      </IconButton>
    </div>
  );
}
