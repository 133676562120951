import { Box, Card, CardContent, Typography } from "@mui/material";
import Logo from "@/assets/images/logo.png";
import { useNavigate } from "react-router-dom";
const DashboardPage = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,

        height: "calc(100vh - 75px)",
      }}
    >
      <Card
        sx={{
          mt: 1,
          mx: 1,
          height: "100%",
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: { xs: 180, sm: 270, md: 360 },
              width: { xs: 180, sm: 270, md: 360 },
              mb: 2,
            }}
            alt="Logo"
            src={Logo}
          />
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            sx={{ fontSize: { xs: "2rem", sm: "3rem", md: "4rem" } }}
          >
            Selamat Datang di CMS YBRS GKP!
          </Typography>
     
        </CardContent>
      </Card>
    </Box>
  );
};

export default DashboardPage;
