const buildRoute = (route: any) => {
  const { GuardComponent, element, children, ...rest } = route;
  return {
    ...rest,
    element: GuardComponent ? (
      <GuardComponent>{element}</GuardComponent>
    ) : (
      element
    ),
    children: children ? children.map(buildRoute) : undefined,
  };
};

export default buildRoute;
