import HeroSection from "./components/hero";
// import SubscribeSection from "./components/subscribe";
// import TeamSection from "./components/team";
// import ProcessSection from "./components/process";
import ArticlesSection from "./components/articles";
import KegiatanSection from "./components/kegiatan";
import SatuanKerjaSection from "./components/satuankerja";
const HomePage = () => {
  return (
    <>
      <HeroSection />
      {/* <FeatureSection /> */}
      {/* <AboutSection /> */}
      {/* <ServiceSection /> */}
      {/* <LogoSection /> */}
      {/* <PilaretisSection /> */}
      {/* <TeamSection />
      <ProcessSection /> */}
      {/* <TestimonialSection /> */}
      <KegiatanSection />
      <SatuanKerjaSection />
      <ArticlesSection />
      {/* <SubscribeSection /> */}
    </>
  );
};

export default HomePage;
