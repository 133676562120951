import { useSnackbar } from "@/hooks/useSnackbar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import ProgramService from "../../services/ProgramService";
import { ProgramFormModel } from "../../types/ProgramModel";
import * as Yup from "yup";
import { useMemo } from "react";

const useUpdateProgram = (id: number) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const programService = new ProgramService();
  const validationSchema = Yup.object().shape({
    tahun: Yup.string().required("Tahun wajib diisi"),
    deskripsi: Yup.string().required("Deskripsi wajib diisi"),
    isActive: Yup.boolean().required("IsActive wajib diisi"),
  });
  const mutation = useMutation({
    mutationFn: (data: ProgramFormModel) => programService.update(id, data),
    onSuccess: () => {
      showSnackbar("Program berhasil ditambahkan", "success");
      navigate(-1);
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  const programQuery = useQuery({
    queryKey: ["program-detail", id],
    queryFn: () => programService.getDetail(id),    
  });
  const programData = useMemo(() => {
    return programQuery.data?.data ?? null;
  }, [programQuery.isSuccess, programQuery.data]);
  const initialValues = useMemo(() => {
    return {
      tahun: programData?.tahun ?? "",
      deskripsi: programData?.deskripsi ?? "",
      isActive: programData?.isActive ?? false,
    };
  }, [programData]);
  return {
    validationSchema,
    mutation,
    navigate,
    initialValues,
    programData,
  };
};

export default useUpdateProgram;
