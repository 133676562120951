import * as Yup from "yup";
import { useSnackbar } from "@/hooks/useSnackbar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import KegiatanService from "../../services/KegiatanService";
import { KegiatanFormModel } from "../../types/KegiatanModel";
import { useMemo } from "react";

const useUpdateKegiatan = (id: number) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const kegiatanService = new KegiatanService();
  const validationSchema = Yup.object().shape({
    Tanggal: Yup.string().required("Tanggal wajib diisi"),
    Judul: Yup.string().required("Judul wajib diisi"),
    Deskripsi: Yup.string().required("Deskripsi wajib diisi"),
    IsPublish: Yup.boolean().required("IsPublish wajib diisi"),
    ThumbnailFile: Yup.mixed().nullable(),
    Galleries: Yup.array().of(Yup.mixed()).nullable(),
    DeleteGalleriesIds: Yup.array().of(Yup.number()).nullable(),
  });
  const mutation = useMutation({
    mutationFn: (data: KegiatanFormModel) => kegiatanService.update(id, data),  
    onSuccess: () => {
      showSnackbar("Kegiatan berhasil diupdate", "success");
      navigate(-1);
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  const kegiatanQuery = useQuery({
    queryKey: ["kegiatan-detail", id],
    queryFn: () => kegiatanService.getDetail(id),
  });
  const kegiatanData = useMemo(() => {
    return kegiatanQuery.data?.data ?? null;
  }, [kegiatanQuery.isSuccess, kegiatanQuery.data]);

  const galleriesQuery = useQuery({
    queryKey: ["galleries", id],
    queryFn: () => kegiatanService.getGalleries(id),
  });
  const galleriesData = useMemo(() => {
    return galleriesQuery.data?.data ?? null;
  }, [galleriesQuery.isSuccess, galleriesQuery.data]);
  const initialValues = useMemo(() => {
    return {
      Tanggal: kegiatanData?.tanggal ?? "",
      Judul: kegiatanData?.judul ?? "",
      Deskripsi: kegiatanData?.deskripsi ?? "",
      IsPublish: kegiatanData?.isPublish ?? false,
      ThumbnailFile: null,
      Galleries: [],
      DeleteGalleriesIds: [],
    };
  }, [kegiatanData]);
  return {
    validationSchema,
    mutation,
    navigate,
    initialValues,
    kegiatanData,
    galleriesData,
  };
};

export default useUpdateKegiatan;
