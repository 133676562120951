import { useSnackbar } from "@/hooks/useSnackbar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import ContactUsService from "../../services/ContactUsService";
import { ContactUsFormModel } from "../../types/ContactUsModel";
import * as Yup from "yup";
import { useMemo } from "react";

const useUpdateContactUs = (id: number) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const contactusService = new ContactUsService();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().required("Email is required"),
    subject: Yup.string().required("Subject is required"),
    phone: Yup.string()
      .required("Phone is required")
      .matches(/^(\+62|62|0)8[1-9][0-9]{6,9}$/, "Phone number is not valid"),
    pesan: Yup.string().required("Pesan is required"),
  });
  const mutation = useMutation({
    mutationFn: (data: ContactUsFormModel) => contactusService.update(id, data),
    onSuccess: () => {
      showSnackbar("ContactUs berhasil ditambahkan", "success");
      navigate(-1);
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  const contactusQuery = useQuery({
    queryKey: ["contactus-detail", id],
    queryFn: () => contactusService.getDetail(id),
  });
  const contactusData = useMemo(() => {
    return contactusQuery.data?.data ?? null;
  }, [contactusQuery.isSuccess, contactusQuery.data]);
  const initialValues = useMemo(() => {
    return {
      firstName: contactusData?.firstName ?? "",
      lastName: contactusData?.lastName ?? "",
      email: contactusData?.email ?? "",
      phone: contactusData?.phone ?? "",
      subject: contactusData?.subject ?? "",
      pesan: contactusData?.pesan ?? "",
      token: null,
    };
  }, [contactusData]);
  return {
    validationSchema,
    mutation,
    navigate,
    initialValues,
    contactusData,
  };
};

export default useUpdateContactUs;
