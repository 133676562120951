import PeriodeService from "@/page/admin/organ/services/PeriodeService";
import PersonService from "@/page/admin/organ/services/PersonService";
import { PostQuery } from "@/types/PostQuery";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";

const useOrgan = (type: string) => {
  const isAPIActive = import.meta.env.VITE_API_ACTIVE === "true";
  const [periode, setPeriode] = useState<{
    label: string | null;
    value: string | null;
  } | null>(null);
  const periodeService = new PeriodeService(type);
  const persionService = new PersonService(type);

  const { data: periodeQuery } = useQuery({
    queryKey: ["periode-list-guest", type],
    refetchOnWindowFocus: false,
    enabled: !!type,
    queryFn: () => periodeService.listGuest(),
  });

  const postQuery = useMemo(
    () => ({
      keywords: "",
      filters: [
        {
          key: "PeriodeId",
          operator: "EQUAL",
          values: [periode?.value ?? "-1"],
        },
      ],
      sorts: [
        {
          key: "Order",
          order: "ASC",
        },
      ],
      page: 0,
      size: 0,
    }),
    [periode]
  );

  const personQuery = useQuery({
    queryKey: ["person-list-guest", postQuery],
    refetchOnWindowFocus: false,
    queryFn: () => persionService.allGuest(postQuery as PostQuery),
  });

  const personData = useMemo(() => {
    if (isAPIActive && personQuery.isSuccess) {
      return personQuery.data.data.map((person) => ({
        ...person,
        imageFilePath: `${import.meta.env.VITE_BASE_URL}/${
          person.imageFilePath
        }`,
      }));
    } else {
      return [];
    }
  }, [isAPIActive, personQuery.isSuccess, personQuery.data]);

  const autocompleteOptions = useMemo(() => {
    return periodeQuery?.data.map((item) => ({
      label: item.periodeRange,
      value: item.id.toString(),
    }));
  }, [periodeQuery]);

  useEffect(() => {
    if (autocompleteOptions?.length !== 0) {
      setPeriode(autocompleteOptions?.[0] ?? null);
    } else {
      setPeriode(null);
    }
  }, [autocompleteOptions]);
  return {
    periode,
    autocompleteOptions,
    setPeriode,
    personData,
    personQuery,
  };
};

export default useOrgan;
