import { Form, useParams } from "react-router-dom";
import useUpdatePerson from "./useUpdatePerson";
import { ArrowCircleLeftOutlined } from "@mui/icons-material";
import {
  Box,
  Card,
  CardHeader,
  IconButton,
  CircularProgress,
  CardContent,
  Button,
} from "@mui/material";
import { Formik } from "formik";
import { PersonFormModel } from "../../types/PersonModel";
import FormikImageField from "@/components/formik/FormikImageField";
import FormikTextField from "@/components/formik/FormikTextField";
import toPascalCase from "@/utility/pascalCase";

const UpdatePerson = () => {
  const { id, type, personId } = useParams();
  const { validationSchema, mutation, navigate, initialValues,personData } =
    useUpdatePerson(Number(id), String(type), Number(personId));
  return (
    <Formik<PersonFormModel>
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        mutation.mutate(values);
      }}
    >
      {({ handleSubmit }) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: 2, sm: 3, md: 4 },
            width: { xs: "35%", sm: "30%", md: "25%" },
            marginX: "auto",
            marginTop: {
              xs: "4rem",
              sm: "6rem",
              md: "8rem",
              lg: "10rem",
              xl: "12rem",
            },
            paddingX: { xs: 2, sm: 3, md: 4 },
          }}
        >
          <Form onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                style={{ paddingBottom: 0 }}
                avatar={
                  <IconButton size="small" onClick={() => navigate(-1)}>
                    <ArrowCircleLeftOutlined />
                  </IconButton>
                }
                title="Update Person"
                titleTypographyProps={{
                  fontSize: 24,
                }}
                subheader="Formulir Person"
                subheaderTypographyProps={{
                  fontSize: 16,
                }}
                action={
                  mutation.isPending ? <CircularProgress size="1.5rem" /> : null
                }
              />
              <CardContent
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <FormikTextField
                  name="Name"
                  label={`Nama ${toPascalCase(type)}`}
                  placeholder={`Masukkan nama ${type}`}
                  disabled={mutation.isPending}
                />
                <FormikTextField
                  name="Jabatan"
                  label={`Jabatan ${toPascalCase(type)}`}
                  placeholder={`Masukkan jabatan ${type}`}
                  disabled={mutation.isPending}
                />

                <FormikTextField
                  toInteger
                  name="Order"
                  label={`Urutan ${toPascalCase(type)}`}
                  placeholder={`Masukkan urutan ${type}`}
                  type="number"
                  disabled={mutation.isPending}
                />
                <FormikImageField
                defaultSource={personData?.imageFilePath}
                  name="ImageFile"
                  label={`Foto ${toPascalCase(type)}`}
                  disabled={mutation.isPending}
                />
                <Button
                  disabled={mutation.isPending}
                  startIcon={
                    mutation.isPending ? <CircularProgress size="1rem" /> : null
                  }
                  sx={{ mt: 2 }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default UpdatePerson;
