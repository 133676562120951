import { Link } from "react-router-dom";
import ArticleItem from "@/page/main/informasi/articles/components/ArticleItem";
import { useTranslation } from "react-i18next";
import useListArticles from "@/page/main/home/components/articles/useListArtikel";
const ArticlesSection = () => {
  const { t } = useTranslation("general");
  const { listArticle } = useListArticles();

  return (
    <section className="news-section sec-pad bg-color-1">
      <div className="auto-container">
        <div className="sec-title mb_50 centred">
          <h2>{t("artikel")}</h2>
        </div>
        <div className="ybrs-flex ybrs-justify-end ybrs-mb-4">
          <Link
            to="/informasi/artikel"
            className="ybrs-text-primary ybrs-text-base sm:ybrs-text-lg ybrs-font-semibold ybrs-underline"
          >
            {t("lihat_semua")} {t("artikel")}
          </Link>
        </div>
        <div className="row clearfix">
          {listArticle &&
            listArticle.map((article, index) => (
              <ArticleItem article={article} index={index} />
            ))}
        </div>
        {/* <div className="pagination-wrapper mt_20 centred">
          <ul className="pagination clearfix">
            <li>
              <a className="current" href="service">
                1
              </a>
            </li>
            <li>
              <a href="service">2</a>
            </li>
            <li>
              <a href="service">3</a>
            </li>
            <li>
              <a href="service">
                <i className="icon-36"></i>
              </a>
            </li>
          </ul>
        </div> */}
      </div>
    </section>
  );
};

export default ArticlesSection;
