import { APIResponse } from "@/types/APIResponse";
import { BaseAPI } from "@/utility/BaseAPI";
import { ChangePasswordModel } from "../types/ChangePasswordModel";

class SecurityService {
  private baseURL = `${import.meta.env.VITE_BASE_URL}/api/v1/user`;
  baseAPI: BaseAPI;
  constructor() {
    this.baseAPI = new BaseAPI();
    }

  public async changePassword(
    data: ChangePasswordModel
  ): Promise<APIResponse<any>> {
    return this.baseAPI.post(this.baseURL + "/update_password", data);
  }
}
export default SecurityService;
  