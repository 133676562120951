import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "@/hooks/useSnackbar";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { ContactUsFormModel } from "@/page/admin/contact_us/types/ContactUsModel";
import ContactUsService from "@/page/admin/contact_us/services/ContactUsService";

const useContact = () => {
  const { t } = useTranslation("contact");
  const { t: tGeneral } = useTranslation("general");
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const contactService = new ContactUsService();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().required("Email is required"),
    subject: Yup.string().required("Subject is required"),
    phone: Yup.string()
      .required("Phone is required")
      .matches(/^(\+62|62|0)8[1-9][0-9]{6,9}$/, "Phone number is not valid"),
    pesan: Yup.string().required("Pesan is required"),
  });
  const mutation = useMutation({
    mutationFn: (data: ContactUsFormModel) => contactService.createGuest(data),
    onSuccess: () => {
      showSnackbar("ContactUs berhasil ditambahkan", "success");
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  return {
    recaptchaRef,
    validationSchema,
    mutation,
    navigate,
    showSnackbar,
    t,
    tGeneral,
  };
};

export default useContact;
