import error from "@/assets/images/icons/error-1.png";
import { Link } from "react-router-dom";
import MiniHero from "@/components/minihero";
const NotFoundPage = () => {
  return (
    <>
      <MiniHero
        title="Halaman tidak ditemukan"
        breadcrumb={[
          { name: "Beranda", link: "/" },
          { name: "Halaman tidak ditemukan" },
        ]}
      />
      <section className="error-section sec-pad-2 centred">
        <div className="auto-container">
          <div className="inner-box">
            <figure className="error-image">
              <img src={error} alt="" />
            </figure>
            <h2>
              Oops, halaman tidak <br />
              ditemukan!
            </h2>
            <p>Halaman yang Anda cari tidak dapat ditemukan.</p>
            <Link className="theme-btn btn-one" to="/">
              <span>Kembali ke Beranda</span>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFoundPage;
