import horizontalLogo from "@/assets/images/horizontal_logo.png";
import useHeader from "./useHeader";
import { Link, useLocation } from "react-router-dom";
import LanguageSwitcher from "../language";

const Header = () => {
  const { isScrolled, handleMobileNav, t } = useHeader();

  const location = useLocation();
  const menuItems = [
    { label: t("beranda"), href: "/beranda" },
    {
      label: t("tentang_kami"),
      href: "/tentang-kami",
      hasLink: true,
      subMenu: [
        {
          label: t("arti_logo"),
          href: "/tentang-kami/#arti-logo",
        },
        {
          label: t("visi_misi"),
          href: "/tentang-kami/#visi-misi",
        },
        {
          label: t("sejarah"),
          href: "/tentang-kami/#sejarah",
        },
        {
          label: t("rambu_utama"),
          href: "/tentang-kami/#rambu-utama",
        },
        {
          label: t("mars"),
          href: "/tentang-kami/#mars",
        },
      ],
    },
    {
      label: t("organ"),
      href: "#",
      hasLink: false,
      subMenu: [
        {
          label: t("organ_pembina"),
          href: "/organ/pembina",
        },
        {
          label: t("organ_pengawas"),
          href: "/organ/pengawas",
        },
        {
          label: t("organ_pengurus"),
          href: "/organ/pengurus",
        },
      ]
    },
    {
      label: t("satuan_kerja"),
      href: "/satuan-kerja",
      hasLink: true,
      subMenu: [
        {
          label: t("rumah_sakit_immanuel"),
          href: "/satuan-kerja/rsimmanuel",
        },
        {
          label: t("rumah_sakit_bayukarta"),
          href: "/satuan-kerja/rsbayukarta",
        },
        {
          label: t("rumah_sakit_ridogalih"),
          href: "/satuan-kerja/rsridogalih",
        },
        {
          label: t("klinik_silih_asih"),
          href: "/satuan-kerja/kliniksilihasih",
        },
      ],
    },
    // { label: t("donasi"), href: "/donasi" },
    {
      label: t("informasi"),
      href: "#",
      hasLink: false,
      subMenu: [
        { label: t("artikel"), href: "/informasi/artikel" },
        { label: t("kegiatan"), href: "/informasi/kegiatan" },
        // { label: t("program_kami"), href: "/informasi/program-kami" },
      ],
    },
    { label: t("hubungi_kami"), href: "/hubungi-kami" },
  ];

  const isActive = (href: string) => {
    return location.pathname.includes(href);
  };

  return (
    <>
      <div className="header-top">
        <div className="auto-container">
          <div className="top-inner">
            <ul className="info-list clearfix">
              <li>
                <i className="icon-26"></i>Email:{" "}
                <a href={`mailto: ${import.meta.env.VITE_EMAIL}`}>
                  {import.meta.env.VITE_EMAIL}
                </a>
              </li>
              <li>
                <i className="icon-2"></i>
                {import.meta.env.VITE_PHONE}
              </li>
              <li>
                <i className="fas fa-map-marker-alt"></i>{" "}
                {import.meta.env.VITE_ADDRESS_SHORT}
              </li>
            </ul>
            <ul className="social-links clearfix">
              {/* <li>
                <a href={import.meta.env.VITE_INSTAGRAM}>
                  <i className="icon-4"></i>
                </a>
              </li>
              <li>
                <a href={import.meta.env.VITE_TWITTER}>
                  <i className="icon-5"></i>
                </a>
              </li>
              <li>
                <a href={import.meta.env.VITE_LINKEDIN}>
                  <i className="icon-6"></i>
                </a>
              </li> */}
              <li>
                <a target="_blank" href={import.meta.env.VITE_YOUTUBE}>
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header className={`main-header ${isScrolled ? "fixed-header" : ""}`}>
        <div className="header-lower">
          <div className="outer-container">
            <div className="auto-container">
              <div className="outer-box">
                <div className="logo-box">
                  <figure className="logo">
                    <Link to="/">
                      <img
                        src={horizontalLogo}
                        className="ybrs-w-[180px] ybrs-h-[60px]"
                        alt=""
                      />
                    </Link>
                  </figure>
                </div>
                <div className="menu-area">
                  <div onClick={handleMobileNav} className="mobile-nav-toggler">
                    <i className="icon-bar"></i>
                    <i className="icon-bar"></i>
                    <i className="icon-bar"></i>
                  </div>
                  <nav className="main-menu navbar-expand-md navbar-light clearfix">
                    <div className="collapse navbar-collapse show clearfix">
                      <ul className="navigation clearfix">
                        {menuItems.map((item, index) => (
                          <li
                            key={index}
                            className={`${item.subMenu ? "dropdown" : ""} ${
                              isActive(item.href) ? "active" : ""
                            }`}
                          >
                            {item.subMenu && !item.hasLink ? (
                              <a className="ybrs-cursor-pointer">
                                {item.label}
                              </a>
                            ) : "isRedirect" in item && item.isRedirect ? (
                              <a
                                href={item.href}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.label}
                              </a>
                            ) : (
                              <Link to={item.href}>{item.label}</Link>
                            )}
                            {item.subMenu && (
                              <ul>
                                {item.subMenu.map((subItem, subIndex) => (
                                  <li
                                    key={subIndex}
                                    className={`${
                                      isActive(subItem.href) ? "active" : ""
                                    }`}
                                  >
                                    {"isRedirect" in subItem &&
                                    subItem.isRedirect ? (
                                      <a
                                        href={subItem.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {subItem.label}
                                      </a>
                                    ) : (
                                      <Link to={subItem.href}>
                                        {subItem.label}
                                      </Link>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </nav>
                </div>

                <div className="btn-box">
                  <LanguageSwitcher />
                  <a
                    className="theme-btn btn-whatsapp"
                    href={`https://wa.me/${import.meta.env.VITE_WHATSAPP}`}
                  >
                    <span>
                      {" "}
                      <i className="fab fa-whatsapp ybrs-text-[16px] ybrs-mr-2"></i>
                      {t("whatsapp_kami")}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-header">
          <div className="auto-container">
            <div className="outer-box">
              <div className="logo-box">
                <figure className="logo">
                  <Link to="/">
                    <img
                      src={horizontalLogo}
                      alt=""
                      className="ybrs-w-[180px] ybrs-h-[60px]"
                    />
                  </Link>
                </figure>
              </div>
              <nav className="main-menu navbar-expand-md navbar-light clearfix">
                <div
                  className="collapse navbar-collapse show clearfix"
                  id="navbarSupportedContent"
                >
                  <ul className="navigation clearfix">
                    {menuItems.map((item, index) => (
                      <li
                        key={index}
                        className={item.subMenu ? "dropdown" : ""}
                      >
                        {item.subMenu && !item.hasLink ? (
                          <a className="ybrs-cursor-pointer">{item.label}</a>
                        ) : "isRedirect" in item && item.isRedirect ? (
                          <a
                            href={item.href}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.label}
                          </a>
                        ) : (
                          <Link to={item.href}>{item.label}</Link>
                        )}
                        {item.subMenu && (
                          <ul>
                            {item.subMenu.map((subItem, subIndex) => (
                              <li
                                key={subIndex}
                                className={`${
                                  isActive(subItem.href) ? "active" : ""
                                }`}
                              >
                                {"isRedirect" in subItem &&
                                subItem.isRedirect ? (
                                  <a
                                    href={subItem.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {subItem.label}
                                  </a>
                                ) : (
                                  <Link to={subItem.href}>{subItem.label}</Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>
              <ul className="menu-right-content">
                <LanguageSwitcher />
                <div className="btn-box">
                  <a
                    className="theme-btn btn-whatsapp"
                    href={`https://wa.me/${import.meta.env.VITE_WHATSAPP}`}
                  >
                    <span>
                      {" "}
                      <i className="fab fa-whatsapp ybrs-text-[16px] ybrs-mr-2"></i>
                      {t("whatsapp_kami")}
                    </span>
                  </a>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="mobile-menu">
          <div className="menu-backdrop"></div>
          <div onClick={handleMobileNav} className="close-btn">
            <span className="far fa-times"></span>
          </div>
          <nav className="menu-box">
            <div className="nav-logo">
              <Link to="/">
                <img
                  src={horizontalLogo}
                  className="ybrs-w-[180px] ybrs-h-[60px]"
                  alt=""
                />
              </Link>
            </div>
            <div className="menu-outer">
            <div className="ybrs-text-right">
              <LanguageSwitcher />
            </div>
              <div
                className="collapse navbar-collapse show clearfix ybrs-mt-3"
                id="navbarSupportedContent"
              >
                <ul className="navigation clearfix">
                  {menuItems.map((item, index) => (
                    <li key={index} className={item.subMenu ? "dropdown" : ""}>
                      {item.subMenu && !item.hasLink ? (
                        <a className="ybrs-cursor-pointer"> {item.label}</a>
                      ) : "isRedirect" in item && item.isRedirect ? (
                        <a
                          href={item.href}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.label}
                        </a>
                      ) : (
                        <Link onClick={handleMobileNav} to={item.href}>
                          {item.label}
                        </Link>
                      )}
                      {item.subMenu && (
                        <ul style={{ display: "none" }}>
                          {item.subMenu.map((subItem, subIndex) => (
                            <li
                              key={subIndex}
                              className={`${
                                isActive(subItem.href) ? "active" : ""
                              }`}
                            >
                              {"isRedirect" in subItem && subItem.isRedirect ? (
                                <a
                                  href={subItem.href}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {subItem.label}
                                </a>
                              ) : (
                                <Link
                                  onClick={handleMobileNav}
                                  to={subItem.href}
                                >
                                  {subItem.label}
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                      {item.subMenu && (
                        <div className="dropdown-btn">
                          <span className="fa fa-angle-right"></span>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className=" ybrs-flex ybrs-items-center ybrs-justify-center  ybrs-p-4">
              <a
                className="theme-btn btn-whatsapp ybrs-w-full"
                href={`https://wa.me/${import.meta.env.VITE_WHATSAPP}`}
              >
                <span>
                  {" "}
                  <i className="fab fa-whatsapp ybrs-text-[16px] ybrs-mr-2"></i>
                  {t("whatsapp_kami")}
                </span>
              </a>
              
            </div>
            <div className="contact-info">
              <h4>{t("kontak_kami")}</h4>
              <ul>
                <li>{import.meta.env.VITE_ADDRESS_SHORT}</li>
                <li>
                  <a href={`tel:${import.meta.env.VITE_PHONE}`}>
                    {import.meta.env.VITE_PHONE}
                  </a>
                </li>
                <li>
                  <a href={`mailto:${import.meta.env.VITE_EMAIL}`}>
                    {import.meta.env.VITE_EMAIL}
                  </a>
                </li>
              </ul>
            </div>
            <div className="social-links">
              <ul className="clearfix">
          
                {/* <li>
                  <a href={import.meta.env.VITE_TWITTER}>
                    <span className="fab fa-twitter"></span>
                  </a>
                </li>
                <li>
                  <a href={import.meta.env.VITE_FACEBOOK}>
                    <span className="fab fa-facebook-square"></span>
                  </a>
                </li> */}

                <li>
                  <a target="_blank" href={import.meta.env.VITE_YOUTUBE}>
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="nav-overlay" style={{ display: "none" }}></div>
      </header>
    </>
  );
};

export default Header;
