import { Route } from "@/types/RouteModel";
import ListProgram from "./pages/list";
import CreateProgram from "./pages/create";
import UpdateProgram from "./pages/update";

const programRoutes: Route[] = [
  {
    path: "program",
    element: <ListProgram />,
  },
  {
    path: "program/tambah",
    element: <CreateProgram />,
  },
  {
    path: "program/ubah/:id",
    element: <UpdateProgram />,
  },
];

export default programRoutes;
