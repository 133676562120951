import { Form, Formik } from "formik";
import useCreateOrgan from "./useCreateOrgan";
import { PeriodeFormModel } from "../../types/PeriodeModel";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { ArrowCircleLeftOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import FormikDateField from "@/components/formik/FormikDateField";
import FormikSwitchField from "@/components/formik/FormikSwitchField";

const CreatePeriode = () => {
  const { type } = useParams();
  const { validationSchema, mutation, navigate } = useCreateOrgan(String(type));
  return (
    <Formik<PeriodeFormModel>
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        fromYear: null,
        toYear: null,
        isActive: true,
      }}
      onSubmit={(values) => {
        mutation.mutate(values);
      }}
    >
      {({ handleSubmit }) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: 2, sm: 3, md: 4 },
            width: { xs: "35%", sm: "30%", md: "25%" },
            marginX: "auto",
            marginTop: {
              xs: "4rem",
              sm: "6rem",
              md: "8rem",
              lg: "10rem",
              xl: "12rem",
            },
            paddingX: { xs: 2, sm: 3, md: 4 },
          }}
        >
          <Form onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                style={{ paddingBottom: 0 }}
                avatar={
                  <IconButton size="small" onClick={() => navigate(-1)}>
                    <ArrowCircleLeftOutlined />
                  </IconButton>
                }
                title="Tambah Periode"
                titleTypographyProps={{
                  fontSize: 24,
                }}
                subheader="Formulir Periode"
                subheaderTypographyProps={{
                  fontSize: 16,
                }}
                action={
                  mutation.isPending ? <CircularProgress size="1.5rem" /> : null
                }
              />
              <CardContent
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <FormikDateField
                  toInteger
                  name="fromYear"
                  label="Dari Tahun"
                  dataFormat="yyyy"
                  displayFormat="yyyy"
                  placeholder="Pilih tahun"
                  openTo="year"
                  views={["year"]}
                  disabled={mutation.isPending}
                />
                <FormikDateField
                  toInteger
                  name="toYear"
                  label="Sampai Tahun"
                  dataFormat="yyyy"
                  displayFormat="yyyy"
                  placeholder="Pilih tahun"
                  openTo="year"
                  views={["year"]}
                  disabled={mutation.isPending}
                />

                <Box display="flex" justifyContent="flex-end">
                  <FormikSwitchField
                    name="isActive"
                    label="Active"
                    disabled={mutation.isPending}
                  />
                </Box>
                <Button
                  disabled={mutation.isPending}
                  startIcon={
                    mutation.isPending ? <CircularProgress size="1rem" /> : null
                  }
                  sx={{ mt: 2 }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default CreatePeriode;
