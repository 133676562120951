import MiniHero from "@/components/minihero";
import contactImg from "@/assets/images/resource/contact-1.jpg";
import FormikTextField from "@/components/formik/FormikTextField";
import { Form, Formik } from "formik";
import useContact from "./useContact";
import ReCAPTCHA from "react-google-recaptcha";
import { ContactUsFormModel } from "@/page/admin/contact_us/types/ContactUsModel";

const ContactPage = () => {
  const { validationSchema, mutation, recaptchaRef, showSnackbar, t, tGeneral } = useContact();

  return (
    <>
      <MiniHero
        title={t("hubungi_kami")}
        breadcrumb={[
          { name: tGeneral("beranda"), link: "/" },
          { name: t("hubungi_kami") },
        ]}
      />
      <section className="contact-info-section pt_120">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-column ">
              <div className="info-block-one ybrs-min-h-[180px]">
                <h3>{t("hubungi_kami")}</h3>
                <div className="inner-box">
                  <div className="icon-box">
                    <i className="icon-2"></i>
                  </div>
                  <p>
                    Kantor Pusat: <br />
                    <a href={`tel:${import.meta.env.VITE_PHONE}`}>
                      {import.meta.env.VITE_PHONE}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-column">
              <div className="info-block-one ybrs-min-h-[180px]">
                <h3>{t("alamat_email")}</h3>
                <div className="inner-box">
                  <div className="icon-box">
                    <i className="icon-26"></i>
                  </div>
                  <p>
                    Mail: <br />
                    <a href={`mailto:${import.meta.env.VITE_EMAIL}`}>
                      {import.meta.env.VITE_EMAIL}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-column">
              <div className="info-block-one ybrs-min-h-[180px]">
                <h3>{t("alamat")}</h3>
                <div className="inner-box">
                  <div className="icon-box">
                    <i className="fas fa-map-marker-alt"></i>
                  </div>
                  <p>{import.meta.env.VITE_ADDRESS}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-style-three pt_90 pb_120">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-8 col-md-12 col-sm-12 form-column">
              <div className="form-inner mr_40">
                <div className="sec-title mb_50">
                  <h2>{t("kirim_pesan")}</h2>
                </div>
                <Formik<ContactUsFormModel>
                  validationSchema={validationSchema}
                  enableReinitialize
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    subject: "",
                    phone: "",
                    pesan: "",
                    token: "",
                  }}
                  onSubmit={(values, { resetForm }) => {
                    const recaptchaValue = recaptchaRef.current?.getValue();
                    if (recaptchaValue) {
                      values.token = recaptchaValue;
                      mutation.mutate(values);
                      resetForm();
                      recaptchaRef.current?.reset();
                    } else {
                      showSnackbar("Recaptcha is required", "error");
                    }
                  }}
                >
                  {({ handleSubmit }) => (
                    <Form
                      onSubmit={handleSubmit}
                      id="contact-form"
                      className="default-form"
                    >
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12 ">
                          <FormikTextField
                            placeholder={t("nama_depan")}
                            name="firstName"
                            label={t("nama_depan")}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 ">
                          <FormikTextField
                            placeholder={t("nama_belakang")}
                            name="lastName"
                            label={t("nama_belakang")}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 ">
                          <FormikTextField
                            placeholder={t("email")}
                            type="email"
                            name="email"
                            label={t("email")}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 ">
                          <FormikTextField
                            placeholder={t("nomor_telepon")}  
                            type="text"
                            name="phone"
                            label={t("nomor_telepon")}
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 ">
                          <FormikTextField
                            placeholder={t("subjek")}
                            type="text"
                            name="subject"
                            label={t("subjek")}
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 ">
                          <FormikTextField
                            placeholder={t("pesan")}
                            name="pesan"
                            label={t("pesan")}
                            rows={4}
                            multiline
                          />
                        </div>
                        <div className=" ybrs-flex ybrs-flex-wrap-reverse ybrs-gap-4  md:ybrs-flex-nowrap  ybrs-items-center ybrs-justify-between ybrs-w-full   ">
                          <button
                            disabled={mutation.isPending}
                            className="theme-btn btn-one ybrs-w-full md:ybrs-w-auto"
                            type="submit"
                            name="submit-form"
                          >
                            <span>{t("kirim_pesan")}</span>
                          </button>
                          <ReCAPTCHA
                          
                            ref={recaptchaRef}
                            sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 image-column">
              <figure className="image-box">
                <img src={contactImg} alt="" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="google-map-section">
        <div className="map-inner">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2038.3338330378497!2d107.5967601413943!3d-6.93684342336044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e8bbdcf6666d%3A0xeafc08663780b95a!2sYayasan%20Badan%20Rumah%20Sakit%20Gereja%20Kristen%20Pasundan%20(Yayasan%20BRS%20GKP)!5e0!3m2!1sid!2sid!4v1728635644152!5m2!1sid!2sid"
            height="570"
            style={{ border: "0px", width: "100%" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </>
  );
};

export default ContactPage;
