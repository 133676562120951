import { LoginRequest } from "@/types/AuthModel";
import useLogin from "./useLogin";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
  Box,
  Container,
  Link,
} from "@mui/material";
import { Formik } from "formik";
import { Form } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FormikTextField from "@/components/formik/FormikTextField";
import logo from "@/assets/images/logo.png";
import curveBottom from "@/assets/images/curve-bottom.png";
import curveTop from "@/assets/images/curve-top.png";
import loginLeft from "@/assets/images/login-left.png";
import { Link as RouterLink } from "react-router-dom";
import FormikSwitchField from "@/components/formik/FormikSwitchField";

const LoginPage = () => {
  const {
    mutation,
    validationSchema,
    showPassword,
    handleClickShowPassword,
  } = useLogin();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        height: "100vh",
        width: "100%",
        // backgroundImage: `url(${loginRight})`,
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          // backgroundColor: "rgba(128, 128, 128, 0.6)",
        }}
      ></Box>
      <img
        src={logo}
        alt="logo"
        style={{
          position: "absolute",
          top: 15,
          right: 15,
          width: "80px",
          height: "80px",
        }}
      />

      <Box
        sx={{
          display: { xs: "none", lg: "flex" },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: { xs: "100%" },
          gap: 4,
          backgroundImage: `url(${loginLeft})`,
          backgroundSize: "cover",
          position: "relative",
          zIndex: 10,
        }}
      >
      
      
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { xs: "100%", lg: "50%" },
          height: "100%",
          zIndex: 10,
          marginTop: { xs: "auto", lg: 0 },
          marginBottom: { xs: "auto", lg: 0 },
        }}
      >
        <img
          src={curveTop}
          alt="logo"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 10,
          }}
        />
        <img
          src={curveBottom}
          alt="logo"
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            zIndex: 10,
          }}
        />
        <Container
          sx={{
            zIndex: 20,
            width: {
              xs: "100%",
              sm: "80%",
              md: "60%",
              lg: "30rem",
            },
          }}
        >
          <Box sx={{ p: 4, borderRadius: 2 }}>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></Box> */}
            <Typography
              component="p"
              sx={{
                textAlign: "start",
                fontWeight: 100,
                mr: 1,
                color: "#1f2937",
                fontSize: { xs: "2.5rem", lg: "3rem", lineHeight: "1.2" },
              }}
            >
              Hello
            </Typography>
            <Typography
              component="p"
              sx={{
                textAlign: "start",
                fontWeight: 900,
                color: "#1f2937",
                fontSize: { xs: "2.5rem", lg: "2.75rem", lineHeight: "1" },
              }}
            >
              Welcome Back!
            </Typography>
            {/* <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#1f2937",
                fontSize: { xs: "1.25rem", lg: "1.75rem" },
              }}
            >
              {t("login")}
            </Typography> */}
            <Typography
              variant="body1"
              sx={{
                mt: 2,
                color: "#4b5563",
                fontSize: { xs: "0.875rem", lg: "1.1rem" },
              }}
            >
              
            </Typography>
            <Formik<LoginRequest>
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                mutation.mutate(values);
              }}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <FormikTextField
                    name="email"
                    label="Email"
                    placeholder="Email"
                  />

                  <FormikTextField
                    name="password"
                    label="Password"
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormikSwitchField name="rememberMe" label="Remember me" />
                    {/* <Link
                      component={RouterLink}
                      to="/forgot-password"
                      color="primary"
                      variant="body2"
                      sx={{ fontSize: { xs: "0.875rem", lg: "1rem" } }}
                    >
                      Lupa Password?
                    </Link> */}
                  </Box>
                  <Button
                    sx={{ marginTop: "0.5rem" }}
                    disabled={mutation.isPending}
                    startIcon={
                      mutation.isPending ? (
                        <CircularProgress size="1rem" />
                      ) : null
                    }
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Login
                  </Button>
                  {/* <Box
                    display="flex"
                    justifyContent="center"
                    marginTop="0.5rem"
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: { xs: "0.875rem", lg: "1rem" },
                        color: "#4b5563",
                      }}
                    >
                      Belum memiliki akun?
                      <Link
                        sx={{ ml: 1 }}
                        component={RouterLink}
                        to="/register"
                        color="primary"
                      >
                        Daftar sekarang
                      </Link>
                    </Typography>
                  </Box> */}
                </Form>
              )}
            </Formik>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default LoginPage;
