import { useSnackbar } from "@/hooks/useSnackbar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import OrganService from "../../services/PeriodeService";
import { PeriodeFormModel } from "../../types/PeriodeModel";
import * as Yup from "yup";
import { useMemo } from "react";

const useUpdateOrgan = (id: number, type: string) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const organService = new OrganService(type);
  const validationSchema = Yup.object().shape({
    fromYear: Yup.number()
      .required("Tahun harus diisi")
      .test(
        "from-smaller-than-to",
        "Tahun awal harus lebih kecil dari tahun akhir",
        function (value) {
          const { toYear } = this.parent;
          return value < toYear;
        }
      ),
    toYear: Yup.number()
      .required("Tahun harus diisi")
      .test(
        "to-greater-than-from",
        "Tahun akhir harus lebih besar dari tahun awal",
        function (value) {
          const { fromYear } = this.parent;
          return value > fromYear;
        }
      ),
    isActive: Yup.boolean().required("Status harus diisi"),
  });
  const mutation = useMutation({
    mutationFn: (data: PeriodeFormModel) => organService.update(id, data),
    onSuccess: () => {
      showSnackbar("Organ berhasil ditambahkan", "success");
      navigate(-1);
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  const organQuery = useQuery({
    queryKey: ["organ-detail", id],
    queryFn: () => organService.getDetail(id),
  });
  const organData = useMemo(() => {
    return organQuery.data?.data ?? null;
  }, [organQuery.isSuccess, organQuery.data]);
  const initialValues = useMemo(() => {
    return {
      fromYear: organData?.tahunAwal ?? null,
      toYear: organData?.tahunAkhir ?? null,
      isActive: organData?.isActive ?? true,
    };
  }, [organData]);
  return {
    validationSchema,
    mutation,
    navigate,
    initialValues,
    organData,
  };
};

export default useUpdateOrgan;
