import { useSnackbar } from "@/hooks/useSnackbar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import DonationsVideosService from "../../services/DonationsVideosService";
import { DonationsVideosFormModel } from "../../types/DonationsVideosModel";
import * as Yup from "yup";
import { useMemo } from "react";

const useUpdateDonationsVideos = (id: number) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const donationsvideosService = new DonationsVideosService();
  const validationSchema = Yup.object().shape({
    Title: Yup.string().required("Title is required"),
    Description: Yup.string().required("Description is required"),
    IsActive: Yup.boolean().required("Is Active is required"),
    ThumbnailFile: Yup.mixed().nullable(),
    VideoFile: Yup.mixed().nullable(),
  });
  const mutation = useMutation({
    mutationFn: (data: DonationsVideosFormModel) => donationsvideosService.update(id, data),
    onSuccess: () => {
      showSnackbar("DonationsVideos berhasil ditambahkan", "success");
      navigate(-1);
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  const donationsvideosQuery = useQuery({
    queryKey: ["donationsvideos-detail", id],
    queryFn: () => donationsvideosService.getDetail(id),
  });
  const donationsvideosData = useMemo(() => {
    return donationsvideosQuery.data?.data ?? null;
  }, [donationsvideosQuery.isSuccess, donationsvideosQuery.data]);
  const initialValues = useMemo(() => {
    return {
      Title: donationsvideosData?.title ?? "",
      Description: donationsvideosData?.description ?? "",
      IsActive: donationsvideosData?.isActive ?? false,
      ThumbnailFile: null,
      VideoFile: null,
    };
  }, [donationsvideosData]);
  return {
    validationSchema,
    mutation,
    navigate,
    initialValues,
    donationsvideosData,
  };
};

export default useUpdateDonationsVideos;
