import ImagePreview from "@/components/media/ImagePreview";
import RichTextView from "@/components/richtext_view";
import toPascalCase from "@/utility/pascalCase";
import richtextDefaultFormater from "@/utility/richtextFormater";
import {
  Card,
  CardContent,
  Typography,
  Chip,
  CardActions,
  Box,
} from "@mui/material";
import ArticleAction from "./ArticleAction";
import { ArticleModel } from "../types/ArticleModel";

interface ArticleKanbanProps {
  index: number;
  row: ArticleModel;
}

const ArticleKanban = ({ index, row }: ArticleKanbanProps) => {
  return (
    <Card
      key={index}
      elevation={1}
      sx={{ width: "100%", position: "relative", height: "100%" }}
    >
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box position="relative">
          <Box
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              color: "white",
              padding: "4px 8px",
              borderRadius: "4px",
              zIndex: 10,
            }}
          >
            {row.isPublish ? "Publish" : "Draft"}
          </Box>
          <ImagePreview
            imageStyle={{
              width: "100%",
              height: "320px",
              objectFit: "cover",
            }}
            src={row.imageFilePath}
            alt={row.judul}
          />
        </Box>

        <Typography variant="h5">{row.judul}</Typography>
        <RichTextView
          content={row.deskripsi}
          formater={richtextDefaultFormater}
          maxLength={100}
        />
        <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          {row.tags.split(",").map((tag) => (
            <Chip key={tag} label={toPascalCase(tag.trim())} />
          ))}
        </div>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <ArticleAction data={row} />
      </CardActions>
    </Card>
  );
};

export default ArticleKanban;
