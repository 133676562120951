import { useConfirmationDialog } from "@/hooks/useConfirmationDialog";
import { useSnackbar } from "@/hooks/useSnackbar";
import {
  EditOutlined,
  DeleteOutlined,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { ArticleModel } from "../types/ArticleModel";
import ArticleService from "../services/ArticleService";

interface ArticleActionProps {
  data: ArticleModel;
}

const ArticleAction = ({ data }: ArticleActionProps) => {
  const queryClient = useQueryClient();
  const { showConfirmationDialog } = useConfirmationDialog();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const articleService = new ArticleService();
  const mutation = useMutation({
    mutationFn: (ids: string[]) => {
      return articleService.delete(ids);
    },
    onSuccess: () => {
      showSnackbar("Artikel berhasil dihapus", "success");
      queryClient.refetchQueries({
        queryKey: ["articles"],
      });
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  return (
    <div className="ybrs-flex ybrs-flex-row ybrs-gap-2 ">
     

      <IconButton
        color="warning"
        onClick={() => {
          navigate(`/cms-ybrs/artikel/ubah/${data.id}`);
        }}
      >
        <EditOutlined />
      </IconButton>
      <IconButton
        color="error"
        onClick={() => {
          showConfirmationDialog(
            "Apakah Anda yakin ingin menghapus detail ini?",
            "Apakah Anda yakin ingin menghapus detail ini? Tindakan ini tidak dapat dibatalkan.",

            () => {
              mutation.mutate([data.id.toString()]);
            },
            () => {
              console.log("cancel button clicked");
            },
            {
              acceptLabel: "Ya, Hapus",
              rejectLabel: "Tidak, Batal",
            }
          );
        }}
      >
        <DeleteOutlined />
      </IconButton>
    </div>
  );
};

export default ArticleAction;
