import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { KegiatanModel } from "@/page/admin/kegiatan/types/KegiatanModel";
const KegiatanItem = ({
  kegiatan,
  index,
}: {
  kegiatan: KegiatanModel;
  index: number;
}) => {
  const { t } = useTranslation("general");
  return (
    <Link
      to={`/informasi/kegiatan/${kegiatan.id}`}
      key={index}
      className="col-lg-4 col-md-6 col-sm-12 news-block "
    >
      <div
        className="news-block-one wow fadeInUp animated "
        data-wow-delay={`${index * 300}ms`}
        data-wow-duration="1500ms"
        style={{
          visibility: "visible",
          animationDuration: "1500ms",
          animationDelay: `${index * 300}ms`,
        }}
      >
        <div className="inner-box ">
          <figure className="image-box">
            <a href="blog-details">
              <img
                style={{ height: "300px" }}
                src={kegiatan.imageFilePath}
                alt=""
              />
            </a>
          </figure>
          <div className="lower-content ">
            <ul className="post-info mb_15 clearfix">
              <li>{moment(kegiatan.tanggal).format("DD/MM/YYYY")}</li>
            </ul>
            <h3
              style={{
                height: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <a href="blog-details">
                {kegiatan.judul.length > 80
                  ? `${kegiatan.judul.substring(0, 77)}...`
                  : kegiatan.judul}
              </a>
            </h3>

            <div className="link">
              <a href="blog-details">
                <span>{t("baca_selengkapnya")}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default KegiatanItem;
