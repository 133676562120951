import CreateArticle from "./pages/create";
import ListArticles from "./pages/list";
import { Route } from "@/types/RouteModel";
import UpdateArticle from "./pages/update";

const articlesRoutes: Route[] = [
  {
    path: "artikel",
    element: <ListArticles />,
  },
  {
    path: "artikel/tambah",
    element: <CreateArticle />,
  },
  {
    path: "artikel/ubah/:id",
    element: <UpdateArticle />,
  },
];

export default articlesRoutes;
