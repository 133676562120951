import { APIResponse } from "../types/APIResponse";
import axios from "axios";
import AuthService from "@/services/AuthService";
import { ResponseHandler } from "@/utility/ResponseHandler";

export class BaseAPI {
  private authService: AuthService;
  constructor() {
    this.authService = new AuthService();
  }
  public async create<T, S>(baseURL: string, data: T): Promise<APIResponse<S>> {
    try {
      const auth = this.authService.getAuthenticated();
      if (auth !== null) {
        const response = await axios.post<APIResponse<S>>(
          `${baseURL}/create`,
          data,
          {
            headers: {
              Authorization: `Bearer ${auth.accessToken}`,
            },
          }
        );
        return response.data;
      }
      throw new Error("User not authenticated");
    } catch (error) {
      ResponseHandler.handleErrorResponse(error);
    }
  }
  public async createGuest<T, S>(
    baseURL: string,
    data: T
  ): Promise<APIResponse<S>> {
    try {
      const response = await axios.post<APIResponse<S>>(
        `${baseURL}/create`,
        data
      );
      return response.data;
    } catch (error) {
      ResponseHandler.handleErrorResponse(error);
    }
  }
  public async update<T, S>(
    baseURL: string,
    id: number,
    data: T
  ): Promise<APIResponse<S>> {
    try {
      const auth = this.authService.getAuthenticated();
      if (auth !== null) {
        const response = await axios.put<APIResponse<S>>(
          `${baseURL}/update/${id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${auth.accessToken}`,
            },
          }
        );
        return response.data;
      }
      throw new Error("User not authenticated");
    } catch (error) {
      ResponseHandler.handleErrorResponse(error);
    }
  }
  public async createMultipart<T, S>(
    baseURL: string,
    data: T
  ): Promise<APIResponse<S>> {
    try {
      const auth = this.authService.getAuthenticated();
      if (auth !== null) {
        const formData = new FormData();
        const keys = Object.keys(data as object);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const value = data[key as keyof T];
          if (Array.isArray(value)) {
            if (value.length > 0) {
              for (let j = 0; j < value.length; j++) {
                const file = value[j];
                formData.append(`${key}`, file);
              }
            }
          } else if (value) {
            formData.append(key, value as any);
          }
        }

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.accessToken}`,
          },
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
        };

        const response = await axios.post<APIResponse<S>>(
          `${baseURL}/create`,
          formData,
          config
        );
        return response.data;
      }
      throw new Error("User not authenticated");
    } catch (error) {
      ResponseHandler.handleErrorResponse(error);
    }
  }
  public async updateMultipart<T, S>(
    baseURL: string,
    id: number,
    data: T
  ): Promise<APIResponse<S>> {
    try {
      const auth = this.authService.getAuthenticated();
      if (auth !== null) {
        const formData = new FormData();
        const keys = Object.keys(data as object);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const value = data[key as keyof T];
          if (Array.isArray(value)) {
            if (value.length > 0) {
              for (let j = 0; j < value.length; j++) {
                const file = value[j];
                formData.append(`${key}`, file);
              }
            }
          } else if (value) {
            formData.append(key, value as any);
          }
        }
        const response = await axios.put<APIResponse<S>>(
          `${baseURL}/update/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth.accessToken}`,
            },
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
          }
        );
        return response.data;
      }
      throw new Error("User not authenticated");
    } catch (error) {
      ResponseHandler.handleErrorResponse(error);
    }
  }
  async all<T, S>(baseURL: string, postQuery: T): Promise<APIResponse<S>> {
    try {
      const auth = this.authService.getAuthenticated();
      if (!auth) {
        throw new Error("Auth is not authenticated");
      }
      const response = await axios.post<APIResponse<S>>(
        `${baseURL}/all`,
        postQuery,
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      ResponseHandler.handleErrorResponse(error);
    }
  }

  async allGuest<T, S>(baseURL: string, postQuery: T): Promise<APIResponse<S>> {
    try {
      const response = await axios.post<APIResponse<S>>(
        `${baseURL}/all`,
        postQuery
      );
      return response.data;
    } catch (error) {
      ResponseHandler.handleErrorResponse(error);
    }
  }

  async list<S>(baseURL: string): Promise<APIResponse<S>> {
    try {
      const auth = this.authService.getAuthenticated();
      if (!auth) {
        throw new Error("Auth is not authenticated");
      }
      const response = await axios.get<APIResponse<S>>(`${baseURL}/list`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      ResponseHandler.handleErrorResponse(error);
    }
  }
  public async listGuest<S>(baseURL: string): Promise<APIResponse<S>> {
    try {
      const response = await axios.get<APIResponse<S>>(`${baseURL}/list`);
      return response.data;
    } catch (error) {
      ResponseHandler.handleErrorResponse(error);
    }
  }
  public async getDetail<S>(
    baseURL: string,
    id: number
  ): Promise<APIResponse<S>> {
    try {
      const auth = this.authService.getAuthenticated();
      if (auth !== null) {
        const response = await axios.get<APIResponse<S>>(`${baseURL}/${id}`, {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        });
        return response.data;
      }
      throw new Error("User not authenticated");
    } catch (error) {
      ResponseHandler.handleErrorResponse(error);
    }
  }
  public async getDetailGuestWithoutId<S>(
    baseURL: string
  ): Promise<APIResponse<S>> {
    try {
      const response = await axios.get<APIResponse<S>>(`${baseURL}`);
      return response.data;
    } catch (error) {
      ResponseHandler.handleErrorResponse(error);
    }
  }
  public async getDetailGuest<S>(
    baseURL: string,
    id: number
  ): Promise<APIResponse<S>> {
    try {
      const response = await axios.get<APIResponse<S>>(`${baseURL}/${id}`);
      return response.data;
    } catch (error) {
      ResponseHandler.handleErrorResponse(error);
    }
  }

  async post<T, S>(baseURL: string, data: T): Promise<APIResponse<S>> {
    const auth = this.authService.getAuthenticated();
    if (!auth) {
      throw new Error("User not authenticated");
    }
    try {
      const response = await axios.post<APIResponse<S>>(baseURL, data, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      ResponseHandler.handleErrorResponse(error);
    }
  }

  async delete<S>(baseURL: string, ids: string[]): Promise<APIResponse<S>> {
    const auth = this.authService.getAuthenticated();
    if (!auth) {
      throw new Error("User not authenticated");
    }
    try {
      const response = await axios.post<APIResponse<S>>(
        `${baseURL}/delete`,
        {
          ids: ids,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      ResponseHandler.handleErrorResponse(error);
    }
  }
}
