import { Card, CardContent, Typography, CardActions } from "@mui/material";
import PersonAction from "./PersonAction";
import { PersonModel } from "../types/PersonModel";

interface PersonKanbanProps {
  index: number;
  row: PersonModel;
  type: string;
  id: number;
}

const PersonKanban = ({ index, row, type,id }: PersonKanbanProps) => {
  return (
    <Card
      key={index}
      elevation={1}
      sx={{ width: "100%", position: "relative", height: "100%" }}
    >
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h5">{row.name}</Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <PersonAction id={id} type={type} data={row} />
      </CardActions>
    </Card>
  );
};

export default PersonKanban;
