import CreateDonationsVideos from "./pages/create";
import ListDonationsVideos from "./pages/list";
import { Route } from "@/types/RouteModel";
import UpdateDonationsVideos from "./pages/update";

const donationsvideosRoutes: Route[] = [
  {
    path: "donations-videos",
    element: <ListDonationsVideos />,
  },
  {
    path: "donations-videos/tambah",
    element: <CreateDonationsVideos />,
  },
  {
    path: "donations-videos/ubah/:id",
    element: <UpdateDonationsVideos />,
  },
];

export default donationsvideosRoutes;
