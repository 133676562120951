import { ColumnType } from "@/components/table/types/ColumnModel";
import { PostQuery } from "@/types/PostQuery";
import { useNavigate } from "react-router-dom";
import DonationsVideosAction from "../../components/DonationsVideosAction";
import DonationsVideosService from "../../services/DonationsVideosService";
import { DonationsVideosModel } from "../../types/DonationsVideosModel";
import VideoPreview from "@/components/media/VideoPreview";
import ImagePreview from "@/components/media/ImagePreview";
import { Chip } from "@mui/material";

const useListDonationsVideos = () => {
  const uniqKey = "donationsvideos";
  const donationsvideosService = new DonationsVideosService();
  const navigate = useNavigate();
  const columns: ColumnType<DonationsVideosModel>[] = [
    {
      field: "title",
      headerName: "Judul",
      type: "string",
    },
    {
      field: "thumbnailFilePath",
      headerName: "Thumbnail",
      type: "string",
      hideFilter: true,
      hideSort: true,
      renderCell: (data: DonationsVideosModel) => {
        return (
          <ImagePreview
            imageStyle={{ width: "120px", height: "120px" }}
            src={data.thumbnailFilePath}
            alt="thumbnail"
          />
        );
      },
    },
    {
      field: "video",
      headerName: "Video",
      type: "string",
      hideFilter: true,
      hideSort: true,
      renderCell: (data: DonationsVideosModel) => {
        return (
          <VideoPreview
            videoStyle={{ width: "200px", height: "100px" }}
            src={data.videoFilePath}
          />
        );
      },
    },
    {
      field: "description",
      headerName: "Deskripsi",
      type: "string",
    },
    {
      field: "isActive",
      headerName: "Aktif",
      type: "boolean",
      width: 350,
      renderCell: (row: DonationsVideosModel) => (
        <Chip label={row.isActive ? "Aktif" : "Tidak Aktif"} />
      ),
    },

    {
      field: "actions",
      type: "actions",

      headerName: "Actions",
      renderCell: (data: DonationsVideosModel) => {
        return <DonationsVideosAction data={data} />;
      },
    },
  ];
  const handleAddButtonClick = () => {
    navigate("/cms-ybrs/donations-videos/tambah");
  };

  const service = (postQuery: PostQuery) => {
    return donationsvideosService.all(postQuery);
  };
  return {
    uniqKey,
    columns,
    handleAddButtonClick,
    service,
  };
};

export default useListDonationsVideos;
