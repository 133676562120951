import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import banner1 from "@/assets/images/banner/christmas/christmas1.png";
import banner2 from "@/assets/images/banner/christmas/christmas2.png";
import banner3 from "@/assets/images/banner/christmas/christmas3.png";
import banner4 from "@/assets/images/banner/christmas/christmas4.png";
import banner5 from "@/assets/images/banner/christmas/christmas5.png";
import HeroImagesService from "@/page/admin/hero_images/services/HeroImagesService";
import { useQuery } from "@tanstack/react-query";

const useHero = () => {
  const heroimagesService = new HeroImagesService();
  const isAPIActive =  import.meta.env.VITE_API_ACTIVE === "true";
  const { t } = useTranslation("home");

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const heroimagesQuery = useQuery({
    queryKey: ["heroimages-guest"],
    queryFn: () => heroimagesService.listGuest(),
  });

  const images = useMemo(() => {
    if (isAPIActive && heroimagesQuery.isSuccess) {
      return heroimagesQuery.data.data.map(
        (image) => `${import.meta.env.VITE_BASE_URL}/${image.imageFilePath}`
      );
    } else {
      return [banner1, banner2, banner3, banner4, banner5];
    }
  }, [isAPIActive, heroimagesQuery.isSuccess, heroimagesQuery.data]);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex + 1 === images.length ? 0 : prevIndex + 1
      );
    }, 9500);

    return () => clearInterval(interval);
  }, [images]);

  return {  t, images, currentImageIndex };
};

export default useHero;
