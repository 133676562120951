import { Link } from "react-router-dom";
import KegiatanItem from "@/page/main/informasi/kegiatan/components/KegiatanItem";
import shape8 from "@/assets/images/shape/shape-8.png";
import shape9 from "@/assets/images/shape/shape-9.png";
import shape10 from "@/assets/images/shape/shape-10.png";
import shape11 from "@/assets/images/shape/shape-11.png";
import { useTranslation } from "react-i18next";
import useListKegiatan from "@/page/main/home/components/kegiatan/useListKegiatan";
const KegiatanSection = () => {
  const { t } = useTranslation("general");
  const { listKegiatan } = useListKegiatan();
  return (
    <section className="about-style-two  ybrs-mt-[120px] ">
      <div className="pattern-layer">
        <div
          className="pattern-1 rotate-me"
          style={{ backgroundImage: `url(${shape8})` }}
        ></div>
        <div
          className="pattern-2 rotate-me"
          style={{ backgroundImage: `url(${shape8})` }}
        ></div>
        <div
          className="pattern-3 rotate-me"
          style={{ backgroundImage: `url(${shape9})` }}
        ></div>
        <div
          className="pattern-6"
          style={{ backgroundImage: `url(${shape10})` }}
        ></div>
        <div
          className="pattern-7"
          style={{ backgroundImage: `url(${shape11})` }}
        ></div>
      </div>
      <div className="auto-container">
        <div className="sec-title mb_50 centred">
          <h2>{t("kegiatan")}</h2>
        </div>
        <div className="ybrs-flex ybrs-justify-end ybrs-mb-4">
          <Link
            to="/informasi/kegiatan"
            className="ybrs-text-primary ybrs-text-base sm:ybrs-text-lg ybrs-font-semibold ybrs-underline"
          >
            {t("lihat_semua")} {t("kegiatan")}
          </Link>
        </div>
        <div className="row clearfix">
          {listKegiatan.map((kegiatan, index) => (
            <KegiatanItem kegiatan={kegiatan} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default KegiatanSection;
