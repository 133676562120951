import { useSnackbar } from "@/hooks/useSnackbar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import ArticleService from "../../services/ArticleService";
import { ArticleFormModel } from "../../types/ArticleModel";
import * as Yup from "yup";
import { useMemo } from "react";

const useUpdateArticle = (id: number) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const articleService = new ArticleService();
  const validationSchema = Yup.object().shape({
    Judul: Yup.string().required("Judul wajib diisi"),
    Deskripsi: Yup.string().required("Deskripsi wajib diisi"),
    Tags: Yup.string().required("Tags wajib diisi"),
    IsPublish: Yup.boolean().required("IsPublish wajib diisi"),
    ImageFile: Yup.mixed().nullable(),    
  });
  const mutation = useMutation({
    mutationFn: (data: ArticleFormModel) => articleService.update(id, data),
    onSuccess: () => {
      showSnackbar("Article berhasil diubah", "success");
      navigate(-1);
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  const articleQuery = useQuery({
    queryKey: ["article-detail", id],
    queryFn: () => articleService.getDetail(id),
  });
  const articleData = useMemo(() => {
    return articleQuery.data?.data ?? null;
  }, [articleQuery.isSuccess, articleQuery.data]);
  const initialValues = useMemo(() => {
    return {
      Judul: articleData?.judul ?? "",
      Deskripsi: articleData?.deskripsi ?? "",
      Tags: articleData?.tags ?? "",
      IsPublish: articleData?.isPublish ?? false,
      ImageFile: null,
    };
  }, [articleData]);
  return {
    validationSchema,
    mutation,
    navigate,
    initialValues,
    articleData,
  };
};

export default useUpdateArticle;
