import { Route } from "@/types/RouteModel";
import ChangePassword from "./pages/change-password";

const securityRoutes: Route[] = [
  {
    path: "security/change-password",
    element: <ChangePassword />,
  },
];

export default securityRoutes;
