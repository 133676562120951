import { Card, CardContent, Typography, CardActions } from "@mui/material";
import ContactUsAction from "./ContactUsAction";
import { ContactUsModel } from "../types/ContactUsModel";

interface ContactUsKanbanProps {
  index: number;
  row: ContactUsModel;
}

const ContactUsKanban = ({ index, row }: ContactUsKanbanProps) => {
  return (
    <Card
      key={index}
      elevation={1}
      sx={{ width: "100%", position: "relative", height: "100%" }}
    >
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        
        <Typography variant="body1">{row.firstName}</Typography>
        <Typography variant="body1">{row.lastName}</Typography>
        <Typography variant="body1">{row.email}</Typography>
        <Typography variant="body1">{row.phone}</Typography>
        <Typography variant="body1">{row.subject}</Typography>
        <Typography variant="body1">{row.pesan}</Typography>
      </CardContent>
      <CardActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
        <ContactUsAction data={row} />
      </CardActions>
    </Card>
  );
};

export default ContactUsKanban;
