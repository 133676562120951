import ImagePreview from "@/components/media/ImagePreview";
import RichTextView from "@/components/richtext_view";
import richtextDefaultFormater from "@/utility/richtextFormater";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
} from "@mui/material";
import KegiatanAction from "./KegiatanAction";
import { KegiatanModel } from "../types/KegiatanModel";

interface KegiatanKanbanProps {
  index: number;
  row: KegiatanModel;
}

const KegiatanKanban = ({ index, row }: KegiatanKanbanProps) => {
  return (
    <Card
      key={index}
      elevation={1}
      sx={{ width: "100%", position: "relative", height: "100%" }}
    >
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <ImagePreview
          imageStyle={{
            width: "100%",
            height: "320px",
            objectFit: "cover",
          }}
          src={row.imageFilePath}
          alt={row.judul}
        />
        <Typography variant="h5">{row.judul}</Typography>
        <RichTextView
          content={row.deskripsi ?? ""}
          formater={richtextDefaultFormater}
          maxLength={100}
        />
       
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <KegiatanAction data={row} />
      </CardActions>
    </Card>
  );
};

export default KegiatanKanban;
