import { PostFilter, QueryOperator } from "@/types/PostQuery";
import {
  FilterAlt,
  FilterAltOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import { Box, Button, IconButton, Menu, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { FilterType } from "../types/FilterModel";
import { allowedOperator } from "../constants/filterConfig";
import { Formik } from "formik";
import OperatorField from "./OperatorField";
import { ColumnType } from "../types/ColumnModel";
interface FilterDropdownProps<T> {
  defaultFilter: PostFilter[];

  initialFilter: PostFilter[];
  filterConfigs: FilterType[];
  columns: ColumnType<T>[];
  onApply: (filters: PostFilter) => void;
  resetAllFilter: () => void;
}

const FilterKanban = <T,>({
  defaultFilter,
  initialFilter,
  filterConfigs,
  columns,
  onApply,
  resetAllFilter,
}: FilterDropdownProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [tempFilter, setTempFilter] = useState<PostFilter[]>([]);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const memoizedColumns = useMemo(() => {
    return columns.filter(
      (column) => !column.hideFilter && column.type !== "actions"
    );
  }, [columns]);
  const submit = (values: any) => {
    Object.values(values).forEach((v: any) => {
      if (
        !(v.key === "judul" && v.operator === "EQUAL" && v.values.length === 0)
      ) {
        onApply(JSON.parse(JSON.stringify(v)));
      }
    });

    handleClose();
  };

  const reset = () => {
    resetAllFilter();
    handleClose();
  };
  const initialFilterValues = useMemo(() => {
    return memoizedColumns.reduce((acc: Record<string, PostFilter>, column) => {
      const existingFilter = JSON.parse(JSON.stringify(initialFilter)).find(
        (filter: PostFilter) => filter.key === column.field
      );
      acc[column.field] = existingFilter ?? {
        key: column.field,
        operator: "EQUAL",
        values: [],
      };
      return acc;
    }, {});
  }, [memoizedColumns, initialFilter]);
  const isActive = useMemo(() => {
    return initialFilter.length > 0 && !defaultFilter.some((df) => df.key === initialFilter[0].key);
  }, [initialFilter, defaultFilter]);
  return (
    <>
      <IconButton
        aria-controls="filter-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {isActive? (
          <FilterAlt  color="primary" />
        ) : (
          <FilterAltOutlined  />
        )}
      </IconButton>

      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Boolean(anchorEl) && (
          <Formik
            initialValues={initialFilterValues}
            enableReinitialize={true}
            onSubmit={(values) => submit(values)}
          >
            {({ handleSubmit, values }) => {
              return (
                <Box display="flex" flexDirection="column" gap={2} p={2}>
                  <div className="ybrs-flex ybrs-flex-row ybrs-items-center ybrs-justify-between">
                    <p className="ybrs-font-bold ybrs-mb-2 ybrs-text-[24px]">
                      Filter{" "}
                    </p>
                    <IconButton color="primary" onClick={reset}>
                      <RefreshOutlined  />
                    </IconButton>
                  </div>

                  {memoizedColumns.map((column) => {
                    const getTemplateFromOperator = (
                      operator: QueryOperator
                    ) => {
                      const template = filterConfigs.find(
                        (config) => config.value === operator
                      );
                      if (!template) return <></>;
                      const Component = template.component;

                      return (
                        <Component prefix={column.field} type={column.type} />
                      );
                    };
                    const operatorsMapping = filterConfigs.filter((config) =>
                      allowedOperator[
                        column.type as keyof typeof allowedOperator
                      ].includes(config.value as QueryOperator)
                    );
                    // const filter = tempFilter.find(
                    //   (filter) => filter.key === column.field
                    // ) ?? {
                    //   key: column.field,
                    //   operator: "EQUAL",
                    //   values: [],
                    // };

                    return (
                      // <FilterKanbanItem
                      //   key={column.field}
                      //   initialFilter={
                      //     filter ?? {
                      //       key: column.field,
                      //       operator: "EQUAL",
                      //       values: [],
                      //     }
                      //   }
                      //   headerName={column.headerName}
                      //   field={column.field}
                      //   type={column.type}
                      //   filterConfigs={filterConfigs}
                      //   onApply={handleApply}
                      // />
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        width="100%"
                      >
                        <Typography flex={1}>{column.headerName}</Typography>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(2, 1fr)"
                          alignItems="center"
                          gap={2}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="start"
                            alignItems="center"
                            height="100%"
                          >
                            <OperatorField
                              prefix={column.field}
                              filterConfigs={operatorsMapping}
                            />
                          </Box>
                          {getTemplateFromOperator(
                            values[column.field]?.operator as QueryOperator
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={() => handleSubmit()}
                    >
                      Apply Filter
                    </Button>
                  </Box>
                </Box>
              );
            }}
          </Formik>
        )}
      </Menu>
    </>
  );
};

export default FilterKanban;
