import React from "react";
import banner from "@/assets/images/background/page-title.png";
import shape14 from "@/assets/images/shape/shape-14.png";
import shape15 from "@/assets/images/shape/shape-15.png";
import shape16 from "@/assets/images/shape/shape-16.png";
import { Link } from "react-router-dom";

interface MiniHeroProps {

  title: string;
  breadcrumb: { name: string; link?: string }[];
}

const MiniHero: React.FC<MiniHeroProps> = ({
 
  title,
  breadcrumb,
}) => {
  return (
    <section className="page-title p_relative centred ybrs-mt-[100px] md:ybrs-mt-[0px]">
      <div
        className="bg-layer"
        style={{ backgroundImage: `url(${banner})` }}
      ></div>
      <div className="pattern-layer">
        <div
          className="pattern-1"
          style={{ backgroundImage: `url(${shape14})` }}
        ></div>
        <div
          className="pattern-2"
          style={{ backgroundImage: `url(${shape14})` }}
        ></div>
        <div
          className="pattern-3 rotate-me"
          style={{ backgroundImage: `url(${shape15})` }}
        ></div>
        <div
          className="pattern-4 float-bob-y"
          style={{ backgroundImage: `url(${shape16})` }}
        ></div>
      </div>
      <div className="auto-container">
        <div className="content-box">
          <h1>{title}</h1>
          <ul className="bread-crumb clearfix">
            {breadcrumb.map((item, index) => (
              <li key={index}>
                {item.link ? <Link to={item.link}>{item.name}</Link> : item.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default MiniHero;
