import { useSnackbar } from "@/hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import ProgramService from "../../services/ProgramService";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";
import { ProgramFormModel } from "../../types/ProgramModel";
const useCreateProgram = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const programService = new ProgramService();
  const validationSchema = Yup.object().shape({
    tahun: Yup.string().required("Tahun wajib diisi"),
    deskripsi: Yup.string().required("Deskripsi wajib diisi"),
    isActive: Yup.boolean().required("IsActive wajib diisi"),
  });
  const mutation = useMutation({
    mutationFn: (data: ProgramFormModel) => programService.create(data),
    onSuccess: () => {
      showSnackbar("Program berhasil ditambahkan", "success");
      navigate(-1);
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  return {
    validationSchema,
    mutation,
    navigate,
  };
};

export default useCreateProgram;
