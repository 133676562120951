import DashboardPage from "@/page/admin/dashboard";
import LoginPage from "@/page/auth/login";
import { Route } from "@/types/RouteModel";
import GuestGuard from "../guard/GuestGuard";
import AuthGuard from "../guard/AuthGuard";
import { Navigate } from "react-router-dom";
import CMSybrs from "@/page/admin/cmsybrs";
import articlesRoutes from "@/page/admin/articles/routes";
import kegiatanRoutes from "@/page/admin/kegiatan/routes";
import programRoutes from "@/page/admin/program/routes";
import contactusRoutes from "@/page/admin/contact_us/routes";
import heroimagesRoutes from "@/page/admin/hero_images/routes";
import donationsvideosRoutes from "@/page/admin/donations_videos/routes";
import securityRoutes from "@/page/admin/security/routes";
import organRoutes from "@/page/admin/organ/routes";

export default [
  {
    path: `/${import.meta.env.VITE_ADMIN_LOGIN_SECRET}`,
    GuardComponent: GuestGuard,
    element: <LoginPage />,
  },
  {
    path: `/cms-ybrs`,
    GuardComponent: AuthGuard,
    element: <CMSybrs />,
    children: [
      {
        index: true,
        element: <Navigate to={`dashboard`} />,
      },
      {
        path: "*",
        element: <>Not Found</>,
      },
      {
        path: "dashboard",
        element: <DashboardPage />,
      },
      ...articlesRoutes,
      ...kegiatanRoutes,
      ...programRoutes,
      ...contactusRoutes,
      ...heroimagesRoutes,
      ...donationsvideosRoutes,
      ...securityRoutes,
      ...organRoutes,
    ],
  },
] as Route[];
