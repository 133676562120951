import { kegiatanDummy } from "@/constants/kegiatan_dummy";
import KegiatanService from "@/page/admin/kegiatan/services/KegiatanService";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

const useDetailKegiatan = (id: number) => {
  const isAPIActive = import.meta.env.VITE_API_ACTIVE === "true";
  const kegiatanService = new KegiatanService();
  const kegiatanQuery = useQuery({
    queryKey: ["kegiatan-guest", id],
    queryFn: () => kegiatanService.detailGuest(id),
  });

  const kegiatanDetail = useMemo(() => {
    if (isAPIActive && kegiatanQuery.isSuccess) {
      return {
        ...kegiatanQuery.data.data,
        imageFilePath: `${import.meta.env.VITE_BASE_URL}/${
          kegiatanQuery.data.data.imageFilePath
        }`,
        galleries: kegiatanQuery.data.data.galleries.map((gallery) => ({
          ...gallery,
          imageFilePath: `${import.meta.env.VITE_BASE_URL}/${
            gallery.imageFilePath
          }`,
        })),
      };
    } else {
      const kegiatan = kegiatanDummy.find((kegiatan) => kegiatan.id === id);
      return kegiatan;
    }
  }, [isAPIActive, kegiatanQuery.isSuccess, kegiatanQuery.data]);

  return { kegiatanDetail };
};

export default useDetailKegiatan;
