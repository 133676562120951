import {
  ArticleOutlined,
  DashboardOutlined,
  EventOutlined,
  FilePresentOutlined,
  ImageAspectRatioOutlined,
  GroupsOutlined,
  GroupOutlined,
  InfoOutlined,
  VideoLabelOutlined,
  VideoCameraFrontOutlined,
  ContactsOutlined,
} from "@mui/icons-material";

const menu = [
  {
    t: "Dashboard",
    icon: <DashboardOutlined />,
    route: "/cms-ybrs/dashboard",
  },

  {
    t: "Informasi",
    icon: <InfoOutlined />,
    nestedItems: [
      {
        t: "Artikel",
        icon: <ArticleOutlined />,
        route: "/cms-ybrs/artikel",
      },
      {
        t: "Kegiatan",
        icon: <EventOutlined />,
        route: "/cms-ybrs/kegiatan",
      },
    ],
  },

  // {
  //   t: "Program",
  //   icon: <WorkOutlineOutlined />,
  //   route: "/cms-ybrs/program",
  //   // nestedItems: [
  //   //   {
  //   //     t: "list-pic",
  //   //     icon: <ListAltOutlined />,
  //   //     route: "/cms-ybrs/pic",
  //   //   },
  //   //   {
  //   //     t: "create-pic",
  //   //     icon: <AddBoxOutlined />,
  //   //     route: "/cms-ybrs/pic/create",
  //   //   },
  //   // ],
  // },
  {
    t: "Kontak Kami",
    icon: <ContactsOutlined />,
    route: "/cms-ybrs/contact-us",
  },
  {
    t: "Gambar Hero",
    icon: <ImageAspectRatioOutlined />,
    route: "/cms-ybrs/hero-images",
  },
  {
    t: "Vidio Donasi",
    icon: <VideoCameraFrontOutlined />,
    route: "/cms-ybrs/donations-videos",
  },
  {
    t: "Organisasi",
    icon: <GroupsOutlined />,
    nestedItems: [
      {
        t: "Pembina",
        icon: <GroupOutlined />,
        route: "/cms-ybrs/organ/pembina",
      },
      {
        t: "Pengawas",
        icon: <GroupOutlined />,
        route: "/cms-ybrs/organ/pengawas",
      },
      {
        t: "Pengurus",
        icon: <GroupOutlined />,
        route: "/cms-ybrs/organ/pengurus",
      },
    ],
  },
];

export default menu;
