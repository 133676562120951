import CreateHeroImages from "./pages/create";
import ListHeroImages from "./pages/list";
import { Route } from "@/types/RouteModel";
import UpdateHeroImages from "./pages/update";

const heroimagesRoutes: Route[] = [
  {
    path: "hero-images",
    element: <ListHeroImages />,
  },
  {
    path: "hero-images/tambah",
    element: <CreateHeroImages />,
  },
  {
    path: "hero-images/ubah/:id",
    element: <UpdateHeroImages />,
  },
];

export default heroimagesRoutes;
