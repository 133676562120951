import { kegiatanDummy } from "@/constants/kegiatan_dummy";
import KegiatanService from "@/page/admin/kegiatan/services/KegiatanService";
import { PostQuery } from "@/types/PostQuery";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";

const useAllKegiatan = () => {
  const [postQuery, setPostQuery] = useState<PostQuery>({
    keywords: "",
    filters: [],
    sorts: [{ key: "createdDate", order: "DESC" }],
    page: 1,
    size: 6,
  });
  const isAPIActive = import.meta.env.VITE_API_ACTIVE === "true";
  const kegiatanService = new KegiatanService();
  const kegiatanQuery = useQuery({
    queryKey: [
      "kegiatan-all-guest",
      postQuery.page,
      postQuery.keywords,
      postQuery.size,
    ],
    queryFn: () => kegiatanService.allGuest(postQuery),
  });

  const listKegiatan = useMemo(() => {
    if (isAPIActive && kegiatanQuery.isSuccess) {
      return kegiatanQuery.data.data.map((kegiatan) => ({
        ...kegiatan,
        imageFilePath: `${import.meta.env.VITE_BASE_URL}/${
          kegiatan.imageFilePath
        }`,
      }));
    } else {
      return kegiatanDummy;
    }
  }, [isAPIActive, kegiatanQuery.isSuccess, kegiatanQuery.data]);

  const totalRows = kegiatanQuery.data?.page?.totalPage || 0;
  const currentPage = kegiatanQuery.data?.page?.current || 1;

  const handlePaginationChange = (page: number) => {
    setPostQuery({ ...postQuery, page: page });
  };

  const handleSearch = (values: any) => {
    setPostQuery({ ...postQuery, keywords: values.search, page: 1 });
  };

  return {
    listKegiatan,
    handlePaginationChange,
    totalRows,
    currentPage,
    handleSearch,
  };
};

export default useAllKegiatan;
