const richtextDefaultFormater = (content: string) => {
  return (
    content
      // Alignment
      .replace(/ql-align-center/g, "ybrs-text-center")
      .replace(/ql-align-right/g, "ybrs-text-right")
      .replace(/ql-align-justify/g, "ybrs-text-justify")
      .replace(/ql-align-left/g, "") // Default, no class needed

      // Text size
      .replace(/ql-size-small/g, "ybrs-text-sm")
      .replace(/ql-size-large/g, "ybrs-text-lg")
      .replace(/ql-size-huge/g, "ybrs-text-xl")

      // Text styles
      .replace(/ql-bold/g, "ybrs-font-bold")
      .replace(/ql-italic/g, "ybrs-italic")
      .replace(/ql-underline/g, "ybrs-underline")
      .replace(/ql-strike/g, "ybrs-line-through")
      .replace(
        /ql-code-block/g,
        "ybrs-font-mono ybrs-bg-gray-100 ybrs-p-2 ybrs-rounded"
      )

      // Lists
      .replace(/ql-list-ordered/g, "ybrs-list-decimal ybrs-list-inside")
      .replace(/ql-list-bullet/g, "ybrs-list-disc ybrs-list-inside")

      // Indentation
      .replace(/ql-indent-1/g, "ybrs-pl-4")
      .replace(/ql-indent-2/g, "ybrs-pl-8")
      .replace(/ql-indent-3/g, "ybrs-pl-12")
      .replace(/ql-indent-4/g, "ybrs-pl-16")
      .replace(/ql-indent-5/g, "ybrs-pl-20")
      .replace(/ql-indent-6/g, "ybrs-pl-24")
      .replace(/ql-indent-7/g, "ybrs-pl-28")
      .replace(/ql-indent-8/g, "ybrs-pl-32")
      .replace(/ql-indent-9/g, "ybrs-pl-36")

      // Text direction
      .replace(/ql-direction-rtl/g, "ybrs-direction-rtl")

      // Blockquote
      .replace(
        /<blockquote class="ql-quote">/g,
        '<blockquote class="ybrs-border-l-4 ybrs-border-gray-300 ybrs-pl-4 ybrs-italic ybrs-my-4">'
      )
      // Video
      .replace(
        /<iframe class="ql-video"/g,
        '<iframe class="ybrs-w-full ybrs-aspect-video"'
      )
      // Links
      .replace(
        /<a class="ql-link"/g,
        '<a class="ybrs-text-system-link ybrs-underline hover:ybrs-text-blue-800"'
      )

      // Images
      .replace(
        /<img class="ql-image"/g,
        '<img class="ybrs-max-w-full ybrs-h-auto ybrs-rounded"'
      )

      // Formula (if you're using KaTeX or MathJax)
      .replace(
        /ql-formula/g,
        "ybrs-font-mono ybrs-bg-gray-100 ybrs-p-1 ybrs-rounded"
      )

      // Block elements
      .replace(/<p>/g, '<p class="">')
      .replace(/<h1>/g, '<h1 class="ybrs-text-4xl ">')
      .replace(/<h2>/g, '<h2 class="ybrs-text-3xl ">')
      .replace(/<h3>/g, '<h3 class="ybrs-text-2xl ">')
      .replace(/<h4>/g, '<h4 class="ybrs-text-xl ">')
      .replace(/<h5>/g, '<h5 class="ybrs-text-lg ">')
      .replace(/<h6>/g, '<h6 class="ybrs-text-base ">')
      .replace(
        /<ul>/g,
        '<ul class="ybrs-list-disc ybrs-list-inside ybrs-mb-4 ybrs-pl-5">'
      )
      .replace(
        /<ol>/g,
        '<ol class="ybrs-list-decimal ybrs-list-inside ybrs-mb-4 ybrs-pl-5">'
      )
      .replace(/<li>/g, '<li class="ybrs-mb-1">')
      .replace(
        /<blockquote>/g,
        '<blockquote class="ybrs-border-l-4 ybrs-border-gray-300 ybrs-pl-4 ybrs-italic ybrs-mb-4">'
      )
      .replace(
        /<pre>/g,
        '<pre class="ybrs-bg-gray-100 ybrs-p-4 ybrs-rounded ybrs-mb-4 ybrs-overflow-x-auto">'
      )
      .replace(
        /<hr>/g,
        '<hr class="ybrs-my-8 ybrs-border-t ybrs-border-gray-300">'
      )

      // Inline elements
      .replace(
        /<a /g,
        '<a class="ybrs-text-system-link ybrs-underline hover:ybrs-text-blue-800" '
      )
      .replace(/<strong>/g, '<strong class="ybrs-font-bold">')
      .replace(/<em>/g, '<em class="ybrs-italic">')
      .replace(
        /<code>/g,
        '<code class="ybrs-bg-gray-100 ybrs-px-1 ybrs-rounded">'
      )
      .replace(
        /<img /g,
        '<img class="ybrs-max-w-full ybrs-h-auto ybrs-rounded" '
      )

      // Tables
      .replace(
        /<table>/g,
        '<table class="ybrs-w-full ybrs-border-collapse ybrs-mb-4">'
      )
      .replace(
        /<th>/g,
        '<th class="ybrs-border ybrs-border-gray-300 ybrs-p-2 ybrs-bg-gray-100">'
      )
      .replace(
        /<td>/g,
        '<td class="ybrs-border ybrs-border-gray-300 ybrs-p-2">'
      )

      // Forms
      .replace(
        /<input /g,
        '<input class="ybrs-border ybrs-border-gray-300 ybrs-rounded ybrs-px-3 ybrs-py-2" '
      )
      .replace(
        /<textarea>/g,
        '<textarea class="ybrs-border ybrs-border-gray-300 ybrs-rounded ybrs-px-3 ybrs-py-2 ybrs-w-full">'
      )
      .replace(
        /<select>/g,
        '<select class="ybrs-border ybrs-border-gray-300 ybrs-rounded ybrs-px-3 ybrs-py-2">'
      )
      .replace(
        /<button>/g,
        '<button class="ybrs-bg-blue-500 ybrs-text-white ybrs-px-4 ybrs-py-2 ybrs-rounded hover:ybrs-bg-system-ybrs-text-system-link">'
      )
      .replace(/ql-script-sub/g, "ybrs-align-sub")
      .replace(/ql-script-super/g, "ybrs-align-super")

      // Checklist
      .replace(/ql-list-check/g, "ybrs-list-check")

      // Color and Background
      .replace(/ql-color-(\w+)/g, "ybrs-text-$1")
      .replace(/ql-bg-(\w+)/g, "ybrs-bg-$1")

      // Font
      .replace(/ql-font-(\w+)/g, "ybrs-font-$1")

      // Clean (remove formatting)
      .replace(/ql-clean/g, "")
  );
};

export default richtextDefaultFormater;
