import { useNavigate } from "react-router-dom";
import KegiatanService from "../../services/KegiatanService";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { KegiatanFormModel } from "../../types/KegiatanModel";
import { useSnackbar } from "@/hooks/useSnackbar";
const useCreateKegiatan = () => {   
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const kegiatanService = new KegiatanService();
  const validationSchema = Yup.object().shape({
    Tanggal: Yup.string().required("Tanggal wajib diisi"),
    Judul: Yup.string().required("Judul wajib diisi"),
    Deskripsi: Yup.string().required("Deskripsi wajib diisi"),
    IsPublish: Yup.boolean().required("IsPublish wajib diisi"),
    ThumbnailFile: Yup.mixed().nullable().required("ThumbnailFile wajib diisi"),
    Galleries: Yup.array().of(Yup.mixed().nullable()).required("Galleries wajib diisi"),
  });
  const mutation = useMutation({
    mutationFn: (data: KegiatanFormModel) => kegiatanService.create(data),
    onSuccess: () => {
      showSnackbar("Kegiatan berhasil ditambahkan", "success");
      navigate(-1);
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  return {
    validationSchema,
    mutation,
    navigate,
  };
};

export default useCreateKegiatan;
