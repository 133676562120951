import useRootStore from "@/stores/rootStore";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useSidebar = () => {
  const brandName = import.meta.env.VITE_BRAND_NAME;
  const {
    desktopOpen,
    mobileOpen,
    nestedOpen,
    closeDrawer,
    transitionEnd,
    toggleNestedOpen,
    setToggleDrawer,
  } = useRootStore((state) => state);


  const [isHover, setIsHover] = useState(false);


  useEffect(() => {
    if(isHover){
      setToggleDrawer(true)
    } else {
      setToggleDrawer(false)
    }
  },[isHover])

  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerClose = () => {
    closeDrawer();
  };

  const handleDrawerTransitionEnd = () => {
    transitionEnd();
  };

  const handleClick = (item: any) => {
    if (item.route) {
      navigate(item.route);
    } else {
      toggleNestedOpen(item.t);
    }
  };

  const handleNestedClick = (key: string, route?: string) => {
    if (route) {
      navigate(route);
    } else {
      toggleNestedOpen(key);
    }
  };
  return {
    brandName,
    desktopOpen,
    mobileOpen,
    nestedOpen,
    navigate,
    location,
    handleDrawerClose,
    handleDrawerTransitionEnd,
    handleClick,
    handleNestedClick,
    setToggleDrawer,
    isHover,
    setIsHover,
  };
};

export default useSidebar;
