import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import AppBar from "./components/appbar";
import Sidebar from "./components/sidebar";
import { Box, Toolbar } from "@mui/material";
import StyledMain from "./components/StyledMain";
import { useEffect } from "react";
import useCMSybrs from "./useCMSybrs";
import bgHeader from "@/assets/images/layout-header.jpg";

interface Props {
  window?: () => Window;
}

const CMSybrs = ({ window }: Props) => {
  const { refreshTokenMutation, desktopOpen, auth, container } = useCMSybrs({
    window,
  });

  useEffect(() => {
    if (auth !== null) {
      const initialDelay = setTimeout(() => {
        refreshTokenMutation.mutate();
      }, 5000);

      return () => {
        clearTimeout(initialDelay);
      };
    }
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar />
      <Sidebar container={container} />
      <StyledMain open={desktopOpen}>
        <Box
          sx={{
            position: "fixed",
            backgroundPositionY: "50%",
            zIndex: -50,
            width: "100%",
            top: 0,
            backgroundColor: "content",
            backgroundImage: `url(${bgHeader})`,
            minHeight: "18.75rem",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "primary.main",
              opacity: 0.6,
            }}
          ></Box>
        </Box>
        <Toolbar />
        <Outlet />
      </StyledMain>
    </Box>
  );
};

export default CMSybrs;
