import RichTextView from "@/components/richtext_view";
import richtextDefaultFormater from "@/utility/richtextFormater";
import { Card, CardContent, Typography, CardActions } from "@mui/material";
import ProgramAction from "./ProgramAction";
import { ProgramModel } from "../types/ProgramModel";

interface ProgramKanbanProps {
  index: number;
  row: ProgramModel;
}

const ProgramKanban = ({ index, row }: ProgramKanbanProps) => {
  return (
    <Card
      key={index}
      elevation={1}
      sx={{ width: "100%", position: "relative", height: "100%" }}
    >
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        
        <Typography variant="h5">{row.tahun}</Typography>
        <RichTextView
          content={row.deskripsi}
          formater={richtextDefaultFormater}
          maxLength={100}
        />
      </CardContent>
      <CardActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
        <ProgramAction data={row} />
      </CardActions>
    </Card>
  );
};

export default ProgramKanban;
