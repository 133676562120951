import MiniHero from "@/components/minihero";

import SatuanKerjaItem from "./components/SatuanKerjaItem";
import { satuanKerjaData } from "./constants";
import { useTranslation } from "react-i18next";
const SatuanKerja = () => {
  const { t } = useTranslation("satuan_kerja"); 
  const { t: tGeneral } = useTranslation("general");
  return (
    <>
      <MiniHero
        title={t("satuan_kerja")}
        breadcrumb={[
          { name: tGeneral("beranda"), link: "/" },
          { name: t("satuan_kerja") },
        ]}
      />
      <section className="news-section sec-pad bg-color-1">
        <div className="auto-container">
          <div className="ybrs-grid ybrs-grid-cols-1 sm:ybrs-grid-cols-2 md:ybrs-grid-cols-3 lg:ybrs-grid-cols-4 ybrs-gap-4 ybrs-w-full">
            {satuanKerjaData.map((satuanKerja, index) => (
              <SatuanKerjaItem
                satuanKerja={satuanKerja}
                index={index}
                key={index}
              />
            ))}
          </div>
          {/* <div className="row clearfix">
            <div className="col-12 content-side">
              <div className="blog-details-content">
                <div className="news-block-one">
                  <div className="inner-box">
                    <div className="lower-content">
                      <h2>
                        Saat ini unit-unit dibawah YBRS GKP telah memiliki
                        fasilitas-fasilitas Kesehatan sebagai berikut :
                      </h2>
                      <ul className=" ybrs-flex ybrs-flex-col ybrs-gap-4">
                        <li>
                          <strong className="ybrs-text-accent ybrs-text-[24px]">
                            RS Immanuel :
                          </strong>
                          <ul className=" ybrs-flex ybrs-flex-col ybrs-gap-2 ybrs-ml-8">
                            <li>
                              <strong className="ybrs-text-primary">
                                OUTPATIENT DIAGNOSTIC CENTER :
                              </strong>
                              <ul>
                                <li>
                                  INTERNAL DISEASE, GERIATRICS, RENAL
                                  HYPERTENSION, ENDOCRINE, GASTRO, CHILD CLINIC,
                                  OB-GYN, SURGERY, NERVE, NUTRITION CLINIC,
                                  RHEUMATISM, HEART/CARDIOLOGY, ONCOLOGY,
                                  PSYCHOLOGY, GENERAL, DENTAL CLINIC, ALLERGY,
                                  ACUPUNCTURE, SLEEP LAB CLINIC, SLIMMING
                                  CLINIC, PAIN CLINIC
                                </li>
                              </ul>
                            </li>
                            <li>
                              <strong className="ybrs-text-primary ">
                                MEDIC CENTER :
                              </strong>
                              <ul>
                                <li>
                                  INTERNAL DISEASE, HEART, CHILD, DOTS, OB-GYN,
                                  SURGERY, DEPRESSION/SOUL, ENT,
                                  OPHTHALMOLOGIST, DERMATOLOGIST, PULMONOLOGIST,
                                  FAMILY PLANNING, KIA, PSYCHOLOGY, GENERAL AND
                                  DENTAL, MMPI, EKG, USG, NEBULIZER
                                </li>
                              </ul>
                            </li>
                            <li>
                              <strong className="ybrs-text-primary">
                                TOTAL BED :
                              </strong>
                              <ul>
                                <li>2113</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <strong className="ybrs-text-accent ybrs-text-[24px]">
                            RS Bayukarta :
                          </strong>
                          <ul className=" ybrs-flex ybrs-flex-col ybrs-gap-2 ybrs-ml-8">
                            <li>
                              <strong className="ybrs-text-primary">
                                SPECIALIST CLINIC :
                              </strong>
                              <ul>
                                <li>
                                  CHILD, OB-GYN, INTERNAL DIS., SURGEON, ORAL
                                  SURGEON, ORTHOPEDIC, PEDIATRIC SURG.,
                                  DIGESTIVE SURGEON, NEUROSURGEON, NEUROLOGIST,
                                  ENT SPECIALIST, DERMATOLOGIST, PULMONOLOGIST,
                                  CARDIOLOGIST, UROLOGIST, MENTAL HEALTH CLINIC,
                                  GROWTH AND DEVELOPMENT, NUTRITION CONSULTING,
                                  MEDICAL REHABILITATION, DOTS, EYE CENTER,
                                  PHYSIOTHERAPY, ENDOSCOPY, HEMODIALYSIS, ESWL.
                                </li>
                              </ul>
                            </li>
                            <li>
                              <strong className="ybrs-text-primary">
                                PUBLIC CLINIC :
                              </strong>
                              <ul>
                                <li>GENERAL, DENTAL/KIDS DENTAL, ACUPUNTURE</li>
                              </ul>
                            </li>
                            <li>
                              <strong className="ybrs-text-primary">
                                TOTAL BED :
                              </strong>
                              <ul>
                                <li>322</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <strong className="ybrs-text-accent ybrs-text-[24px]">
                            RS Ridogalih :
                          </strong>
                          <ul className="ybrs-flex ybrs-flex-col ybrs-gap-2 ybrs-ml-8">
                            <li>
                              <strong className="ybrs-text-primary">
                                OUTPATIENT :
                              </strong>
                              <ul>
                                <li>
                                  GENERAL CLINIC, DENTAL CLINIC, PEDIATRIC,
                                  INTERNIST, SURGEON, OBGYN, ENT, NEUROLOGIST
                                </li>
                              </ul>
                            </li>
                            <li>
                              <strong className="ybrs-text-primary">
                                TOTAL BED :
                              </strong>
                              <ul>
                                <li>124</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <strong className="ybrs-text-accent ybrs-text-[24px]">
                            Klinik Silih Asih :
                          </strong>
                          <ul className="ybrs-flex ybrs-flex-col ybrs-gap-2 ybrs-ml-8">
                            <li>
                              <strong className="ybrs-text-primary">
                                FACILITY :
                              </strong>
                              <ul>
                                <li>PHYSIOTHERAPY, USG, OUTPATIENT</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default SatuanKerja;
