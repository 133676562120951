import { useSnackbar } from "@/hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import HeroImagesService from "../../services/HeroImagesService";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";
import { HeroImagesFormModel } from "../../types/HeroImagesModel";
const useCreateHeroImages = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const heroimagesService = new HeroImagesService();
  const validationSchema = Yup.object().shape({
    Deskripsi: Yup.string().required("Description is required"),
    IsActive: Yup.boolean().required("Is Active is required"),
    ImageFile: Yup.mixed().required("Image is required"),
  });
  const mutation = useMutation({
    mutationFn: (data: HeroImagesFormModel) => heroimagesService.create(data),
    onSuccess: () => {
      showSnackbar("HeroImages berhasil ditambahkan", "success");
      navigate(-1);
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  return {
    validationSchema,
    mutation,
    navigate,
  };
};

export default useCreateHeroImages;
