import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import router from "@/router/routes";
import queryClient from "./constants/app_query_client";
import { Suspense } from "react";
import { ThemeProvider } from "@emotion/react";
import { muiTheme } from "./constants/app_theme_constant";
import InjectAllProvider from "./hooks/hooks";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import LanguageInjection from "@/components/language/injection";
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<div>Loading...</div>}>
        <ThemeProvider theme={muiTheme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <InjectAllProvider>
              <LanguageInjection>
                <RouterProvider router={router} />
              </LanguageInjection>
            </InjectAllProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;
