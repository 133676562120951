import SatuanKerjaItem from "@/page/main/satuankerja/components/SatuanKerjaItem";
import { satuanKerjaData } from "@/page/main/satuankerja/constants";
import shape8 from "@/assets/images/shape/shape-8.png";
import shape9 from "@/assets/images/shape/shape-9.png";
import shape10 from "@/assets/images/shape/shape-10.png";
import shape11 from "@/assets/images/shape/shape-11.png";
import { useTranslation } from "react-i18next";

const SatuanKerjaSection = () => {
  const { t } = useTranslation("general");
  return (
    <section className="about-style-two ybrs-mt-[120px] ">
      <div className="pattern-layer">
        <div
          className="pattern-1 rotate-me"
          style={{ backgroundImage: `url(${shape8})` }}
        ></div>
        <div
          className="pattern-2 rotate-me"
          style={{ backgroundImage: `url(${shape8})` }}
        ></div>
        <div
          className="pattern-3 rotate-me"
          style={{ backgroundImage: `url(${shape9})` }}
        ></div>
        <div
          className="pattern-4"
          style={{ backgroundImage: `url(${shape10})` }}
        ></div>
         <div
          className="pattern-8"
          style={{ backgroundImage: `url(${shape11})` }}
        ></div>
      </div>
      <div className="auto-container">
        <div className="sec-title  ybrs-mb-[90px] centred">
          <h2>{t("satuan_kerja")}</h2>
        </div>
        
        <div className="ybrs-grid ybrs-grid-cols-1 sm:ybrs-grid-cols-2 md:ybrs-grid-cols-3 lg:ybrs-grid-cols-4 ybrs-gap-4 ybrs-w-full ">
          {satuanKerjaData.map((satuanKerja, index) => (
            <SatuanKerjaItem
              satuanKerja={satuanKerja}
              index={index}
              key={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SatuanKerjaSection;
