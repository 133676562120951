import { useSnackbar } from "@/hooks/useSnackbar";
import AuthService from "@/services/AuthService";
import useAuthStore from "@/stores/authStore";
import useRootStore from "@/stores/rootStore";
import { useMutation } from "@tanstack/react-query";

const useCMSybrs = ({ window }: any) => {
  const authService = new AuthService();
  const { showSnackbar } = useSnackbar();
  const { desktopOpen } = useRootStore();
  const { auth, authenticate, deauthenticate } = useAuthStore();
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const refreshTokenMutation = useMutation({
    mutationKey: ["refreshToken"],
    mutationFn: async () => {
      const response = await authService.refreshToken();
      return response;
    },
    onSuccess: (data) => {
      showSnackbar("Token refreshed", "success");
      authenticate(data);
    },
    onError: () => {
      showSnackbar("Token refresh failed", "error");
      deauthenticate();
    },
  });

  return { refreshTokenMutation, desktopOpen, auth, window, container };
};

export default useCMSybrs;
