import { ColumnType } from "@/components/table/types/ColumnModel";
import { PostQuery } from "@/types/PostQuery";
import { Box, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ProgramAction from "../../components/ProgramAction";
import ProgramService from "../../services/ProgramService";
import { ProgramModel } from "../../types/ProgramModel";
import RichTextView from "@/components/richtext_view";
import richtextDefaultFormater from "@/utility/richtextFormater";
import { CalendarToday } from "@mui/icons-material";

const useListProgram = () => {
  const uniqKey = "program";
  const programService = new ProgramService();
  const navigate = useNavigate();
  // const { showConfirmationDialog } = useConfirmationDialog();
  const columns: ColumnType<ProgramModel>[] = [
    { field: "tahun", headerName: "Tahun", width: 200, type: "string" },
    {
      field: "createdDate",
      headerName: "Tanggal Dibuat",
      type: "date",
      width: 350,
      renderCell: (row: ProgramModel) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <CalendarToday />
          {new Date(row.createdDate).toLocaleDateString("id-ID")}
        </Box>
      ),
    },
    {
      field: "deskripsi",
      headerName: "Deskripsi",
      type: "string",
      renderCell: (row: ProgramModel) => (
        <RichTextView
          content={row.deskripsi}
          formater={richtextDefaultFormater}
          maxLength={200}
        />
      ),
    },
    {
      field: "isActive",
      headerName: "Is Active",
      type: "boolean",
      width: 350,
      renderCell: (row: ProgramModel) => (
        <Chip label={row.isActive ? "Active" : "Deactive"} />
      ),
    },
    {
      field: "actions",
      type: "actions",

      headerName: "Actions",
      renderCell: (data: ProgramModel) => {
        return <ProgramAction data={data} />;
      },
    },
  ];
  const handleAddButtonClick = () => {
    navigate("/cms-ybrs/program/tambah");
  };

  const service = (postQuery: PostQuery) => {
    return programService.all(postQuery);
  };
  return {
    uniqKey,
    columns,
    handleAddButtonClick,
    service,
  };
};

export default useListProgram;
