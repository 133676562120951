import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
} from "@mui/material";
import { getNestedItemStyles } from "../StyledDrawer";

const SidebarListItem = ({
  item,
  location,
  nestedOpen,
  handleClick,
  handleNestedClick,
  drawerOpen,
}: {
  item: any;
  location: any;
  nestedOpen: any;
  handleClick: any;
  handleNestedClick: any;
  drawerOpen: boolean;
}) => {
  const renderNestedItems = (items: any, level = 1) => (
    <List component="div" disablePadding>
      {items.map((nestedItem: any, nestedIndex: number) => (
        <div key={nestedIndex}>
          <ListItemButton
            sx={getNestedItemStyles(
              location.pathname.includes(nestedItem.route),
              level,
              drawerOpen
            )}
            onClick={() =>
              nestedItem.route
                ? handleNestedClick(nestedItem.t, nestedItem.route)
                : handleNestedClick(nestedItem.t)
            }
          >
            <ListItemIcon
              sx={{
                color: location.pathname.includes(nestedItem.route)
                  ? "white"
                  : "inherit",
              }}
            >
              {nestedItem.icon}
            </ListItemIcon>
            <ListItemText
              primary={nestedItem.t}
              primaryTypographyProps={{
                fontSize: "clamp(0.75rem, 1vw, 1rem)",
              }}
            />
            {nestedItem.nestedItems &&
              (nestedOpen[nestedItem.t] ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
          {nestedItem.nestedItems && (
            <Collapse
              in={nestedOpen[nestedItem.t]}
              timeout="auto"
              unmountOnExit
            >
              {renderNestedItems(nestedItem.nestedItems, level + 1)}
            </Collapse>
          )}
        </div>
      ))}
    </List>
  );

  return (
    <div>
      <ListItem disablePadding>
        <ListItemButton
          sx={getNestedItemStyles(
            location.pathname.includes(item.route),
            undefined,
            drawerOpen
          )}
          onClick={() => handleClick(item)}
        >
          <ListItemIcon
            sx={{
              color: location.pathname.includes(item.route)
                ? "white"
                : "inherit",
            }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            primary={item.t}
            primaryTypographyProps={{
              fontSize: "clamp(0.75rem, 1vw, 1rem)",
            }}
          />
          {item.nestedItems &&
            (nestedOpen[item.t] ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
      </ListItem>
      {item.nestedItems && (
        <Collapse in={nestedOpen[item.t]} timeout="auto" unmountOnExit>
          {renderNestedItems(item.nestedItems)}
        </Collapse>
      )}
    </div>
  );
};

export default SidebarListItem;
