import NotFoundPage from "@/page/404";
import AboutPage from "@/page/main/about";
import ContactPage from "@/page/main/contact";
import DonasiPage from "@/page/main/donasi";
import OrganPage from "@/page/main/organ";
import HomePage from "@/page/main/home";
import ArticlesPage from "@/page/main/informasi/articles/pages/all";
import DetailArticlePage from "@/page/main/informasi/articles/pages/detail";
import KegiatanPage from "@/page/main/informasi/kegiatan/pages/all";
import DetailKegiatanPage from "@/page/main/informasi/kegiatan/pages/detail";
import SatuanKerja from "@/page/main/satuankerja";
import SatuanKerjaDetail from "@/page/main/satuankerja/detail";
import { Route } from "@/types/RouteModel";
import { Navigate } from "react-router-dom";

export default [
  {
    index: true,
    element: <Navigate to="/beranda" />,
  },
  {
    path: "beranda",
    element: <HomePage />,
  },
  {
    path: "tentang-kami",
    element: <AboutPage />,
  },
  {
    path: "hubungi-kami",
    element: <ContactPage />,
  },
  {
    path: "informasi/artikel",
    element: <ArticlesPage />,
  },
  {
    path: "informasi/artikel/:id",
    element: <DetailArticlePage />,
  },
  {
    path: "/informasi/kegiatan",
    element: <KegiatanPage />,
  },
  {
    path: "/informasi/kegiatan/:id",
    element: <DetailKegiatanPage />,
  },
  {
    path: "/donasi",
    element: <DonasiPage />,
  },
  {
    path: "/organ/:type",
    element: <OrganPage />,
  },
  {
    path: "/satuan-kerja",
    element: <SatuanKerja />,
  },
  {
    path: "/satuan-kerja/:id",
    element: <SatuanKerjaDetail />,
  },

  {
    path: "*",
    element: <NotFoundPage />,
  },
] as Route[];
