import { VideoSettingsOutlined } from "@mui/icons-material";
import { Box, SxProps, Theme } from "@mui/material";
import React from "react";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";

interface VideoPreviewProps {
  src: string;
  title?: string;
  poster?: string;
  videoStyle?: React.CSSProperties;
  sx?: SxProps<Theme>;
  handleSuccess?: () => void;
  handleError?: () => void;
}

const VideoPreview: React.FC<VideoPreviewProps> = ({
  src,
  title,
  poster,
  videoStyle,
  handleSuccess,
  handleError,
}) => {
  const videoUrl = React.useMemo(() => {
    if (src && /api\/v1\/.*/.test(src)) {
      const supportedVideoExtensions = [
        "mp4",
        "webm",
        "ogg",
        "m4v",
        "avi",
        "mov",
        "flv",
        "wmv",
        "mkv",
        "3gp",
        "mxf",
      ];
      const isSupportedVideo = supportedVideoExtensions.some((ext) =>
        src.toLowerCase().endsWith(ext)
      );

      if (!isSupportedVideo) {
        console.error("Unsupported video format");
        return null;
      }
      return `${import.meta.env.VITE_BASE_URL}/${src}`;
    } else {
      return src;
    }
  }, [src]);
  const posterUrl = React.useMemo(() => {
    if (poster && /api\/v1\/.*/.test(poster)) {
      const supportedPosterExtensions = ["jpg", "jpeg", "png"];
      const isSupportedPoster = supportedPosterExtensions.some((ext) =>
        poster.toLowerCase().endsWith(ext)
      );

      if (!isSupportedPoster) {
        console.error("Unsupported poster format");
        return null;
      }
      return `${import.meta.env.VITE_BASE_URL}/${poster}`;
    } else {
      return poster;
    }
  }, [  poster]);

  return videoUrl !== null ? (
    <MediaPlayer
      title={title || import.meta.env.VITE_BRAND_NAME}
      poster={posterUrl ?? ""}
      controls
      src={videoUrl}
      style={{
        ...videoStyle,
      }}
      onLoadedMetadata={handleSuccess}
      onError={handleError}
    >
      <MediaProvider />
      <DefaultVideoLayout
        thumbnails={`${import.meta.env.VITE_BASE_URL}/${src}`}
        icons={defaultLayoutIcons}
      />
    </MediaPlayer>
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        cursor: "pointer",
        borderRadius: "4px",
        width: "100%",
        height: "100%",
        backgroundColor: "lightgray",
        ...videoStyle,
      }}
    >
      <VideoSettingsOutlined
        fontSize="large"
        style={{ fontSize: "100px", color: "white" }}
      />
    </Box>
  );
};

export default VideoPreview;
