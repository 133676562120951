import { useSnackbar } from "@/hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import PeriodeService from "../../services/PeriodeService";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";
import { PeriodeFormModel } from "../../types/PeriodeModel";
const useCreateOrgan = (type: string) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const organService = new PeriodeService(type);
  const validationSchema = Yup.object().shape({
    fromYear: Yup.number()
      .required("Tahun harus diisi")
      .test("from-smaller-than-to", "Tahun awal harus lebih kecil dari tahun akhir", function (value) {
        const { toYear } = this.parent;
        return value < toYear;
      }),
    toYear: Yup.number()
      .required("Tahun harus diisi")
      .test("to-greater-than-from", "Tahun akhir harus lebih besar dari tahun awal", function (value) {
        const { fromYear } = this.parent;
        return value > fromYear;
      }),
    isActive: Yup.boolean().required("Status harus diisi"),
  });
  const mutation = useMutation({
    mutationFn: (data: PeriodeFormModel) => organService.create(data),

    onSuccess: () => {
      showSnackbar("Organ berhasil ditambahkan", "success");
      navigate(-1);
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  return {
    validationSchema,
    mutation,
    navigate,
  };
};

export default useCreateOrgan;
