import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useSnackbar } from "@/hooks/useSnackbar";
import SecurityService from "../../services/SecurityService";
import { ChangePasswordModel } from "../../types/ChangePasswordModel";
import { useMutation } from "@tanstack/react-query";
const useChangePassword = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const securityService = new SecurityService();
  const [showPassword, setShowPassword] = useState(false);
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Password lama wajib diisi"),
    newPassword: Yup.string().required("Password baru wajib diisi"),
    confirmPassword: Yup.string().required("Konfirmasi password wajib diisi"),
  });

  const mutation = useMutation({
    mutationFn: (data: ChangePasswordModel) => securityService.changePassword(data),  
    onSuccess: () => {
      showSnackbar("Password berhasil diubah", "success");  
      navigate(-1);
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  return {
    validationSchema,
    mutation,
    navigate,
    showPassword,
    setShowPassword,
    showSnackbar,
  };
};

export default useChangePassword;
