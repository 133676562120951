import ProfileService from "@/services/ProfileService";
import useAuthStore from "@/stores/authStore";

import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";

const useProfileMenu = () => {
  const profileService = new ProfileService();
  const navigate = useNavigate();
  const { deauthenticate } = useAuthStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const profileQuery = useQuery({
    queryKey: ["profile"],
    queryFn: () => profileService.getProfile(),
  });

  const logout = () => {
    deauthenticate();
  };
  return {
    profileQuery,
    anchorEl,
    navigate,
    handleAvatarClick,
    handleMenuClose,
    logout,
  };
};

export default useProfileMenu;
