import ArticleService from "@/page/admin/articles/services/ArticleService";
import { PostQuery } from "@/types/PostQuery";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { artikelDummy } from "@/constants/artikel_dummy";
const useAllArticle = (tag: string | null) => {
  const [postQuery, setPostQuery] = useState<PostQuery>({
    keywords: "",
    filters: tag ? [{ key: "tags", values: [tag], operator: "ILIKE" }] : [],
    sorts: [{ key: "createdDate", order: "DESC" }],
    page: 1,
    size: 6,
  });
  const isAPIActive = import.meta.env.VITE_API_ACTIVE === "true";
  const articleService = new ArticleService();
  const articleQuery = useQuery({
    queryKey: [
      "article-all-guest",
      postQuery.page,
      postQuery.keywords,
      postQuery.size,
    ],
    queryFn: () => articleService.allGuest(postQuery),
  });

  const listArticle = useMemo(() => {
    if (isAPIActive && articleQuery.isSuccess) {
      return articleQuery.data.data.map((article) => ({
        ...article,
        imageFilePath: `${import.meta.env.VITE_BASE_URL}/${
          article.imageFilePath
        }`,
      }));
    } else {
      return artikelDummy;
    }
  }, [isAPIActive, articleQuery.isSuccess, articleQuery.data]);

  const totalRows = articleQuery.data?.page?.totalPage || 0;
  const currentPage = articleQuery.data?.page?.current || 1;

  // const sortedArticle = listArticle.sort((a, b) => {
  //   return moment(b.createdDate).diff(moment(a.createdDate));
  // });
  const handlePaginationChange = (page: number) => {
    setPostQuery({ ...postQuery, page: page });
  };
  const handleSearch = (values: any) => {
    setPostQuery({ ...postQuery, keywords: values.search, page: 1 });
  };

  return {
    listArticle,
    handlePaginationChange,
    totalRows,
    currentPage,
    handleSearch,
  };
};

export default useAllArticle;
