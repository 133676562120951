import { Field, ErrorMessage } from "formik";
import ReactQuill from "react-quill";
import { Box, Typography } from "@mui/material";

interface FormikRichTextProps {
  name: string;
  label: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  height?: number;
}

const FormikRichText = ({
  name,
  label,
  disabled = false,
  height = 750,
}: FormikRichTextProps) => {
  const modules = {
    toolbar: [
      [
        { header: [1, 2, 3, 4, 5, 6] },
        { font: ["serif", "monospace", "sans-serif"] },
      ],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: any }) => (
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {label}
          </Typography>
          <Box sx={{ height: `${height}px`, width: "100%", mt: 1 }}>
            <ReactQuill
              value={field.value}
              readOnly={disabled}
              modules={modules}
              style={{ height: `calc(${height}px - 2.5rem)`, width: "100%" }}
              scrollingContainer={"#root"}
              onChange={(value: any) => form.setFieldValue(field.name, value)}
               className="custom-quill"
            />
            <ErrorMessage
              name={name}
              component="span"
              render={(msg) => (
                <Typography
                  variant="body2"
                  color="error"
                  style={{
                    marginTop: "4px",
                    marginLeft: "16px",
                    textAlign: "left",
                    fontWeight: "normal",
                  }}
                >
                  {msg}
                </Typography>
              )}
            />
          </Box>
        </Box>
      )}
    </Field>
  );
};

export default FormikRichText;
