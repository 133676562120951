import { useSnackbar } from "@/hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import PersonService from "../../services/PersonService";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";
import { PersonFormModel } from "../../types/PersonModel";
const useCreatePerson = (type: string) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const personService = new PersonService(type);
  const validationSchema = Yup.object().shape({
    PeriodeId: Yup.number().required("Periode harus diisi"),
    Name: Yup.string().required("Nama harus diisi"),
    Jabatan: Yup.string().required("Jabatan harus diisi"),
    Order: Yup.number().required("Urutan harus diisi"),
    ImageFile: Yup.mixed().nullable(),
  });
  const mutation = useMutation({
    mutationFn: (data: PersonFormModel) => personService.create(data),

    onSuccess: () => {
      showSnackbar("Person berhasil ditambahkan", "success");
      navigate(-1);
    },

    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  return {
    validationSchema,
    mutation,
    navigate,
  };
};

export default useCreatePerson;
