import FormikImageField from "@/components/formik/FormikImageField";
import FormikRichText from "@/components/formik/FormikRichText";
import FormikSwitchField from "@/components/formik/FormikSwitchField";
import FormikTextField from "@/components/formik/FormikTextField";
import { ArrowCircleLeftOutlined } from "@mui/icons-material";
import {
  Box,
  Card,
  CardHeader,
  IconButton,
  CircularProgress,
  CardContent,
  Button,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import { Form } from "react-router-dom";
import { KegiatanFormModel } from "../../types/KegiatanModel";
import useCreateKegiatan from "./useCreateKegiatan";
import FormikDateField from "@/components/formik/FormikDateField";
import FormikMultipleImageField from "@/components/formik/FormikMultipleImageField";

const CreateKegiatan = () => {
  const { validationSchema, mutation, navigate } = useCreateKegiatan();
  return (
    <Formik<KegiatanFormModel>
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        Tanggal: "",
        Judul: "",
        IsPublish: false,
        Deskripsi: "",
        ThumbnailFile: null,
        Galleries: [],
      }}
      onSubmit={(values) => {
        // console.log(values);
        mutation.mutate(values);
      }}
    >
      {({ handleSubmit }) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: 2, sm: 3, md: 4 },
            width: { xs: "90%", sm: "85%", md: "90%" },
            marginX: "auto",
            marginTop: {
              xs: "4rem",
              sm: "6rem",
              md: "8rem",
              lg: "10rem",
              xl: "12rem",
            },
            paddingX: { xs: 2, sm: 3, md: 4 },
          }}
        >
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3.5} xl={3}>
                <Card>
                  <CardHeader
                    style={{ paddingBottom: 0 }}
                    avatar={
                      <IconButton size="small" onClick={() => navigate(-1)}>
                        <ArrowCircleLeftOutlined />
                      </IconButton>
                    }
                    title="Tambah Kegiatan"
                    titleTypographyProps={{
                      fontSize: 24,
                    }}
                    subheader="Formulir Kegiatan"
                    subheaderTypographyProps={{
                      fontSize: 16,
                    }}
                    action={
                      mutation.isPending ? (
                        <CircularProgress size="1.5rem" />
                      ) : null
                    }
                  />
                  <CardContent
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <FormikTextField
                      name="Judul"
                      label="Judul"
                      placeholder="Masukkan judul Kegiatan"
                      disabled={mutation.isPending}
                    />

                    <FormikImageField
                      name="ThumbnailFile"
                      label="Thumbnail"
                      disabled={mutation.isPending}
                    />
                    <FormikDateField
                      name="Tanggal"
                      label="Tanggal"
                      disabled={mutation.isPending}
                    />
                    <Box display="flex" justifyContent="flex-end">
                      <FormikSwitchField
                        name="IsPublish"
                        label="Publish"
                        disabled={mutation.isPending}
                      />
                    </Box>
                    <Button
                      disabled={mutation.isPending}
                      startIcon={
                        mutation.isPending ? (
                          <CircularProgress size="1rem" />
                        ) : null
                      }
                      sx={{ mt: 2 }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Submit
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={8.5} xl={9}>
                <Card>
                  <CardContent
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <FormikMultipleImageField
                      name="Galleries"
                      label="Galleries"
                      disabled={mutation.isPending}
                    />
                    <FormikRichText
                      name="Deskripsi"
                      label="Deskripsi"
                      disabled={mutation.isPending}
                      height={500}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default CreateKegiatan;
