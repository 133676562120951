import { Card, CardContent, Typography, CardActions } from "@mui/material";
import OrganAction from "./OrganAction";
import { PeriodeModel } from "../types/PeriodeModel";

interface OrganKanbanProps {
  index: number;
  row: PeriodeModel;
  type: string;
}

const OrganKanban = ({ index, row, type }: OrganKanbanProps) => {
  return (
    <Card
      key={index}
      elevation={1}
      sx={{ width: "100%", position: "relative", height: "100%" }}
    >
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h5">{row.periodeRange}</Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <OrganAction type={type} data={row} />
      </CardActions>
    </Card>
  );
};

export default OrganKanban;
