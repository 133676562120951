import { DonationsVideosModel } from "@/page/admin/donations_videos/types/DonationsVideosModel";
import donasi from "@/assets/video/donasi_hand.mp4";
import logo from "@/assets/images/donasi/donasi_hand.jpg";

export const donasiDummy: DonationsVideosModel = {
  id: 1,
  title: "Mengapa Donasi Anda Berarti?",
  description:
    "Donasi adalah wujud nyata dari kasih sayang dan empati kita kepada mereka yang sedang berjuang. Setiap kontribusi yang kita berikan, sekecil apapun, dapat menjadi sinar harapan dan membawa perubahan besar dalam hidup mereka.",
  videoFilePath: donasi,
  isActive: true,
  thumbnailFilePath: logo,
  createdDate: "2022-01-01T00:00:00Z",
  updatedDate: "2022-01-02T00:00:00Z",
  createdBy: "Admin",
  updatedBy: "Admin",
};
