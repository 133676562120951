import { AuthModel } from "@/types/AuthModel";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface AuthState {
  isAuthenticated: boolean;

  auth: AuthModel | null;
  authenticate: (auth: AuthModel) => void;
  deauthenticate: () => void;
  version: number;
}
const useAuthStore = create<AuthState>()(
  persist(
    (set, _) => ({
      isAuthenticated: false,
      auth: null,
      authenticate: (auth: AuthModel) =>
        set({
          auth: auth,
          isAuthenticated: true,
        }),
      deauthenticate: () =>
        set({
          isAuthenticated: false,
          auth: null,
        }),
      version: Number(import.meta.env.VITE_REDUX_PERSIST_VERSION),
    }),
    {
      name: "xa",
      storage: createJSONStorage(() => localStorage),
      version: Number(import.meta.env.VITE_REDUX_PERSIST_VERSION),
      migrate: (persistedState, version) => {
        if (version !== Number(import.meta.env.VITE_REDUX_PERSIST_VERSION)) {
          return {
            isAuthenticated: false,
            auth: null,
          };
        }
        return persistedState;
      },
    }
  )
);

export default useAuthStore;
