import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SatuanKerjaItem = ({
  satuanKerja,
  index,
}: {
  satuanKerja: any;
  index: number;
}) => {
  const { t } = useTranslation("satuan_kerja");
  return (
    <Link
      to={satuanKerja.route}
      key={index}
      className="ybrs-w-full ybrs-h-full news-block"
    >
      <div
        className="news-block-one wow fadeInUp animated"
        data-wow-delay={`${index * 300}ms`}
        data-wow-duration="1500ms"
        style={{
          visibility: "visible",
          animationDuration: "1500ms",
          animationDelay: `${index * 300}ms`,
        }}
      >
        <div className="inner-box">
          <figure className="image-box">
            <a href={satuanKerja.link} target="_blank">
              <img
                style={{ width: "100%", height: "300px" }}
                src={satuanKerja.imgSrc}
                alt=""
              />
            </a>
          </figure>
          <div className="lower-content">
            <h5 className="text-center text-base md:text-lg lg:text-xl">
              <a href={satuanKerja.link} target="_blank">
                {satuanKerja.title ? t(satuanKerja.title) : ""}
              </a>
            </h5>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SatuanKerjaItem;
