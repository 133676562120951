import { donasiDummy } from "@/constants/donasi_dummy";
import DonationsVideosService from "@/page/admin/donations_videos/services/DonationsVideosService";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

const useDonasi = () => {
  const isAPIActive = import.meta.env.VITE_API_ACTIVE === "true";
  const donasiService = new DonationsVideosService();
  const donasiQuery = useQuery({
    queryKey: ["donasi-guest"],
    queryFn: () => donasiService.getDetailGuest(),
  });

  const donasiDetail = useMemo(() => {
    if (isAPIActive && donasiQuery.isSuccess) {
      return donasiQuery.data.data;
    } else {
      return donasiDummy;
    }
  }, [isAPIActive, donasiQuery.isSuccess, donasiQuery.data]);

  return { donasiDetail };
};

export default useDonasi;
