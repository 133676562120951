import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArticleModel } from "@/page/admin/articles/types/ArticleModel";
import moment from "moment";
import RichTextView from "@/components/richtext_view";
import richtextDefaultFormater from "@/utility/richtextFormater";

const ArticleItem = ({
  article,
  index,
}: {
  article: ArticleModel;
  index: number;
}) => {
  const { t } = useTranslation("general");
  return (
    <Link
      to={`/informasi/artikel/${article.id}`}  
      key={index}
      className="col-lg-4 col-md-6 col-sm-12 news-block"
    >
      <div
        className="news-block-one wow fadeInUp animated"
        data-wow-delay={`${index * 300}ms`}
        data-wow-duration="1500ms"
        style={{
          visibility: "visible",
          animationDuration: "1500ms",
          animationDelay: `${index * 300}ms`,
        }}
      >
        <div className="inner-box">
          <figure className="image-box">
            <a href="blog-details">
              <img
                src={article.imageFilePath}
                alt=""
                style={{ height: "300px" }}
              />
            </a>
          </figure>
          <div className="lower-content ">
            <ul className="post-info mb_15 clearfix">
              <li>
                <a href="blog-details">Admin</a>
              </li>
              <li>{moment(article.createdDate).format("DD/MM/YYYY")}</li>
            </ul>
            <h3
              style={{
                height: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <a href="blog-details">
                {article.judul.length > 80
                  ? `${article.judul.substring(0, 77)}...`
                  : article.judul}
              </a>
            </h3>
            <p
              style={{
                height: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <RichTextView
                content={article.deskripsi}
                maxLength={100}
                formater={richtextDefaultFormater}
              />
            </p>
            <div className="link">
              <a href="blog-details">
                <span>{t("baca_selengkapnya")}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ArticleItem;
