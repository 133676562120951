import { Card, CardContent, Typography, CardActions } from "@mui/material";
import DonationsVideosAction from "./DonationsVideosAction";
import { DonationsVideosModel } from "../types/DonationsVideosModel";
import VideoPreview from "@/components/media/VideoPreview";

interface DonationsVideosKanbanProps {
  index: number;
  row: DonationsVideosModel;
}

const DonationsVideosKanban = ({ index, row }: DonationsVideosKanbanProps) => {
  return (
    <Card
      key={index}
      elevation={1}
      sx={{ width: "100%", position: "relative", height: "100%" }}
    >
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h5">{row.title}</Typography>
        <Typography variant="body2">{row.description}</Typography>
     
        <VideoPreview
          src={row.videoFilePath ?? ""}
          poster={row.thumbnailFilePath}
        />
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <DonationsVideosAction data={row} />
      </CardActions>
    </Card>
  );
};

export default DonationsVideosKanban;
