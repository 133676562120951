import {
  GalleriesModel,
  KegiatanFormModel,
  KegiatanModel,
} from "../types/KegiatanModel";
import { APIResponse } from "@/types/APIResponse";
import { PostQuery } from "@/types/PostQuery";
import { BaseAPI } from "@/utility/BaseAPI";
class KegiatanService {
  private baseURL = `${import.meta.env.VITE_BASE_URL}/api/v1/cms/kegiatan`;
  private baseURLGuest = `${import.meta.env.VITE_BASE_URL}/api/v1/web/kegiatan`;
  private baseURLGalleries = `${
    import.meta.env.VITE_BASE_URL
  }/api/v1/cms/kegiatan_galleries/by_kegiatan_id`;
  baseAPI: BaseAPI;
  constructor() {
    this.baseAPI = new BaseAPI();
  }

  public async create(
    data: KegiatanFormModel
  ): Promise<APIResponse<KegiatanModel>> {
    return this.baseAPI.createMultipart(this.baseURL, data);
  }
  public async update(
    id: number,
    data: KegiatanFormModel
  ): Promise<APIResponse<KegiatanModel>> {
    return this.baseAPI.updateMultipart(this.baseURL, id, data);
  }
  public async getGalleries(
    idKegiatan: number
  ): Promise<APIResponse<GalleriesModel[]>> {
    return this.baseAPI.getDetail(this.baseURLGalleries, idKegiatan);
  }
  public async getDetail(id: number): Promise<APIResponse<KegiatanModel>> {
    return this.baseAPI.getDetail(this.baseURL, id);
  }
  public async detailGuest(id: number): Promise<APIResponse<KegiatanModel>> {
    return this.baseAPI.getDetailGuest(this.baseURLGuest, id);
  }
  async all(postQuery: PostQuery): Promise<APIResponse<KegiatanModel[]>> {
    return this.baseAPI.all(this.baseURL, postQuery);
  }
  async allGuest(postQuery: PostQuery): Promise<APIResponse<KegiatanModel[]>> {
    return this.baseAPI.allGuest(this.baseURLGuest, postQuery);
  }
  async listGuest(): Promise<APIResponse<KegiatanModel[]>> {
    return this.baseAPI.listGuest(this.baseURLGuest);
  }
  async list(): Promise<APIResponse<KegiatanModel[]>> {
    return this.baseAPI.list(this.baseURL);
  }
  async delete(ids: string[]): Promise<APIResponse<any>> {
    return this.baseAPI.delete(this.baseURL, ids);
  }
}
export default KegiatanService;
