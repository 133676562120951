import { PersonFormModel, PersonModel } from "../types/PersonModel";
import { APIResponse } from "@/types/APIResponse";
import { PostQuery } from "@/types/PostQuery";
import { BaseAPI } from "@/utility/BaseAPI";
class PersonService {
  baseURL: string;
  baseURLGuest: string;
  baseAPI: BaseAPI;
  constructor(type: string) {
    this.baseAPI = new BaseAPI();
    this.baseURL = `${import.meta.env.VITE_BASE_URL}/api/v1/cms/${type}`;
    this,this.baseURLGuest = `${import.meta.env.VITE_BASE_URL}/api/v1/web/${type}`
  }

  public async create(
    data: PersonFormModel
  ): Promise<APIResponse<PersonModel>> {
    return this.baseAPI.createMultipart(this.baseURL, data);
  }
  public async update(
    id: number,
    data: PersonFormModel
  ): Promise<APIResponse<PersonModel>> {
    return this.baseAPI.updateMultipart(this.baseURL, id, data);
  }

  public async getDetail(id: number): Promise<APIResponse<PersonModel>> {
    return this.baseAPI.getDetail(this.baseURL, id);
  }
  async all(postQuery: PostQuery): Promise<APIResponse<PersonModel[]>> {
    return this.baseAPI.all(this.baseURL, postQuery);
  }
  async allGuest(postQuery: PostQuery): Promise<APIResponse<PersonModel[]>> {
    return this.baseAPI.allGuest(this.baseURLGuest, postQuery);
  }
  async list(): Promise<APIResponse<PersonModel[]>> {
    return this.baseAPI.list(this.baseURL);
  }
  async delete(ids: string[]): Promise<APIResponse<any>> {
    return this.baseAPI.delete(this.baseURL, ids);
  }
}
export default PersonService;
