import { ArticleModel } from "@/page/admin/articles/types/ArticleModel";
import artikel1 from "@/assets/images/artikel/good_sleep.jpg";
import artikel2 from "@/assets/images/artikel/diabetes.jpg";
import artikel3 from "@/assets/images/artikel/keberagaman.jpg";
import artikel4 from "@/assets/images/artikel/donor_darah.jpg";
import artikel5 from "@/assets/images/artikel/mental_health.jpg";
import artikel6 from "@/assets/images/artikel/cek_kesehatan_berkala.jpg";

export const artikelDummy: ArticleModel[] = [
  {
    id: 1,
    judul: "Manfaat Tidur Cukup untuk Kesehatan Mental dan Fisik",
    deskripsi:
      "Tidur yang cukup adalah salah satu aspek terpenting dari gaya hidup sehat. Meski sering diabaikan, tidur memiliki peran vital dalam menjaga kesehatan mental dan...",
    tags: "kesehatan, tidur",
    isPublish: true,
    imageFilePath: artikel1,
    createdDate: "2024-10-25",
    updatedDate: "2024-10-25",
    createdBy: "Admin",
    updatedBy: "Admin",
  },
  {
    id: 2,
    judul: "Panduan Lengkap Cek Diabetes Mellitus",
    deskripsi:
      "Diabetes Mellitus, sering disebut sebagai kencing manis, adalah penyakit kronis yang ditandai dengan tingginya kadar gula (glukosa) dalam darah. Penyakit ini terjadi...",
    tags: "kesehatan, diabetes",
    isPublish: true,
    imageFilePath: artikel2,
    createdDate: "2024-10-23",
    updatedDate: "2024-10-23",
    createdBy: "Admin",
    updatedBy: "Admin",
  },
  {
    id: 3,
    judul: "Lintas Iman: Mempererat Persaudaraan di Tengah Keberagaman",
    deskripsi:
      "Lintas iman adalah bentuk keterbukaan hati yang aktif, di mana kita saling mendengarkan, belajar, dan menghargai setiap pandangan hidup demi persaudaraan sejati",
    tags: "keberagaman, iman",
    isPublish: true,
    imageFilePath: artikel3,
    createdDate: "2024-10-21",
    updatedDate: "2024-10-21",
    createdBy: "Admin",
    updatedBy: "Admin",
  },
  {
    id: 4,
    judul:
      "Manfaat Donor Darah: Bagaimana Aksi Kecil Ini Bisa Menyelamatkan Banyak Nyawa",
    deskripsi:
      "Donor darah adalah salah satu bentuk kepedulian yang paling sederhana namun berdampak besar bagi banyak orang. Satu kali donor darah dapat menyelamatkan...",
    tags: "kesehatan, donor darah",
    isPublish: true,
    imageFilePath: artikel4,
    createdDate: "2024-10-19",
    updatedDate: "2024-10-19",
    createdBy: "Admin",
    updatedBy: "Admin",
  },
  {
    id: 5,
    judul: "Kesehatan Mental di Era Digital: Tantangan dan Solusi",
    deskripsi:
      "Di era digital saat ini, teknologi telah menjadi bagian tak terpisahkan dari kehidupan sehari-hari. Mulai dari komunikasi hingga pekerjaan, hampir semua aspek kehidupan...",
    tags: "kesehatan, mental",
    isPublish: true,
    imageFilePath: artikel5,
    createdDate: "2024-10-17",
    updatedDate: "2024-10-17",
    createdBy: "Admin",
    updatedBy: "Admin",
  },
  {
    id: 6,
    judul:
      "Pentingnya Pemeriksaan Kesehatan Berkala: Mencegah Lebih Baik Daripada Mengobati",
    deskripsi:
      "Pemeriksaan kesehatan berkala atau check-up rutin adalah salah satu cara terbaik untuk menjaga kesehatan tubuh secara keseluruhan. Meski kita merasa sehat, banyak penyakit yang...",
    tags: "kesehatan, pemeriksaan",
    isPublish: true,
    imageFilePath: artikel6,
    createdDate: "2024-10-15",
    updatedDate: "2024-10-15",
    createdBy: "Admin",
    updatedBy: "Admin",
  },
];
