import CreateContactUs from "./pages/create";
import ListContactUs from "./pages/list";
import { Route } from "@/types/RouteModel";
import UpdateContactUs from "./pages/update";

const contactusRoutes: Route[] = [
  {
    path: "contact-us",
    element: <ListContactUs />,
  },
  {
    path: "contact-us/tambah",
    element: <CreateContactUs />,
  },
  {
    path: "contact-us/ubah/:id",
    element: <UpdateContactUs />,
  },
];

export default contactusRoutes;
