import toPascalCase from "@/utility/pascalCase";
import { Chip, Box } from "@mui/material";

const TagPreview = ({
    tags,
    onDelete,
  }: {
    tags: string;
    onDelete: (tag: string) => void;
  }) => {
    const tagsArray = tags
      .split(",")
      .filter((tag) => tag.trim() !== "")
      .map((tag, index) => (
        <Chip
          key={index}
          label={toPascalCase(tag)} 
          variant="outlined"
          onDelete={() => onDelete(tag)}
        />
      ));
    return (
      <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1} mb={tagsArray.length > 0 ? 2 : 0}>
        {tagsArray}
      </Box>
    );
  };

export default TagPreview;
