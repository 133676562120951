import { useSnackbar } from "@/hooks/useSnackbar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import PersonService from "../../services/PersonService";
import { PersonFormModel } from "../../types/PersonModel";
import * as Yup from "yup";
import { useMemo } from "react";

const useUpdatePerson = (id: number, type: string, personId: number) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const personService = new PersonService(type);
  const validationSchema = Yup.object().shape({
    PeriodeId: Yup.number().required("Periode harus diisi"),
    Name: Yup.string().required("Nama harus diisi"),
    Jabatan: Yup.string().required("Jabatan harus diisi"),
    Order: Yup.number().required("Urutan harus diisi"),
    ImageFile: Yup.mixed().nullable(),
  });
  const mutation = useMutation({
    mutationFn: (data: PersonFormModel) => personService.update(personId, data),
    onSuccess: () => {
      showSnackbar("Person berhasil ditambahkan", "success");
      navigate(-1);
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  const PersonQuery = useQuery({
    queryKey: ["person-detail", personId],
    queryFn: () => personService.getDetail(personId),
  });
  const personData = useMemo(() => {
    return PersonQuery.data?.data ?? null;
  }, [PersonQuery.isSuccess, PersonQuery.data]);
  const initialValues = useMemo(() => {
    return {
      PeriodeId: personData?.periodeId ?? id,
      Name: personData?.name ?? null,
      Jabatan: personData?.jabatan ?? null,
      Order: personData?.order ?? 0,
      ImageFile: null,
    };
  }, [personData]);
  return {
    validationSchema,
    mutation,
    navigate,
    initialValues,
    personData,
  };
};

export default useUpdatePerson;
