import { useSnackbar } from "@/hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import DonationsVideosService from "../../services/DonationsVideosService";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";
import { DonationsVideosFormModel } from "../../types/DonationsVideosModel";
const useCreateDonationsVideos = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const donationsvideosService = new DonationsVideosService();
  const validationSchema = Yup.object().shape({
    Title: Yup.string().required("Title is required"),
    Description: Yup.string().required("Description is required"),
    IsActive: Yup.boolean().required("IsActive is required"),
    ThumbnailFile: Yup.mixed().required("ThumbnailFile is required"),
    VideoFile: Yup.mixed().required("VideoFile is required"),
  });
  const mutation = useMutation({
    mutationFn: (data: DonationsVideosFormModel) => donationsvideosService.create(data),
    onSuccess: () => {
      showSnackbar("DonationsVideos berhasil ditambahkan", "success");
      navigate(-1);
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  return {
    validationSchema,
    mutation,
    navigate,
  };
};

export default useCreateDonationsVideos;
