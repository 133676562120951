import MiniHero from "@/components/minihero";
import bni from "@/assets/images/bni.png";
import mandiri from "@/assets/images/mandiri.png";
import { useTranslation } from "react-i18next";
import VideoPreview from "@/components/media/VideoPreview";
import useDonasi from "./useDonasi";

const DonasiPage = () => {
  const { t } = useTranslation("donasi");
  const { t: tGeneral } = useTranslation("general");
  const { donasiDetail } = useDonasi();

  return (
    <>
      <MiniHero
        title={t("donasi")}
        breadcrumb={[
          { name: tGeneral("beranda"), link: "/" },
          { name: t("donasi") },
        ]}
      />

      <section className="sidebar-page-container sec-pad-2">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-12 content-side">
              <div className="blog-details-content">
                <div className="news-block-one">
                  <div className="inner-box">
                    <VideoPreview
                      src={donasiDetail?.videoFilePath}
                      title="Donasi"
                      poster={donasiDetail?.thumbnailFilePath}
                    />

                    <div className="lower-content">
                      <h2 className="text-3xl font-bold text-center text-blue-600 my-4">
                        {donasiDetail?.title}
                      </h2>
                      <p className="text-lg text-gray-700 leading-relaxed">
                        {donasiDetail?.description}
                      </p>
                    </div>
                    <div className="ybrs-grid ybrs-grid-cols-1 md:ybrs-grid-cols-2 ybrs-gap-4 ybrs-mt-3">
                      <div className="ybrs-p-4 ybrs-border ybrs-rounded-2xl !ybrs-bg-[#ffffff] ybrs-shadow ">
                        <img
                          src={mandiri}
                          alt="Bank Mandiri Card"
                          className="ybrs-w-[240px]  ybrs-h-[80px] ybrs-rounded-lg"
                        />
                        <h3 className="ybrs-text-lg ybrs-font-semibold ybrs-mt-3">
                          Bank Mandiri
                        </h3>
                        <p>No. Rekening: xxxxxxxxxx</p>
                        <p>a.n. YBRS GKP</p>
                      </div>
                      <div className="ybrs-p-4 ybrs-border ybrs-rounded-2xl !ybrs-bg-[#ffffff] ybrs-shadow">
                        <img
                          src={bni}
                          alt="Bank BNI Card"
                          className="ybrs-w-[240px]  ybrs-h-[80px] ybrs-rounded-lg"
                        />
                        <h3 className="ybrs-text-lg ybrs-font-semibold ybrs-mt-3">
                          Bank BNI
                        </h3>
                        <p>No. Rekening: xxxxxxxxxx</p>
                        <p>a.n. YBRS GKP</p>
                      </div>
                    </div>
                    {/* <div className="ybrs-flex ybrs-justify-center ybrs-my-8">
                      <img
                        src={qrExample}
                        alt="QR Code for Donasi"
                        className="ybrs-p-4 ybrs-w-full md:ybrs-w-1/2 lg:ybrs-w-1/2 ybrs-h-auto ybrs-mx-auto"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DonasiPage;
