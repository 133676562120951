import DataTable from "@/components/table";
import {
  Box,
  Card,
  CardContent,
} from "@mui/material";
import useListArticles from "./useListArticles";
import ArticleKanban from "../../components/ArticleKanban";

const ListArticles = () => {
  const { columns, service, handleAddButtonClick, uniqKey } = useListArticles();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { xs: 2, sm: 3, md: 4 },
        width: { xs: "90%", sm: "85%", md: "90%" },
        marginX: "auto",
        marginTop: {
          xs: "4rem",
          sm: "6rem",
          md: "8rem",
          lg: "10rem",
          xl: "12rem",
        },
        paddingX: { xs: 2, sm: 3, md: 4 },
      }}
    >
      <Card>
        <CardContent>
          <DataTable
            
            kanbanRender={({ index, row }) => {
              return <ArticleKanban index={index} row={row} />;
            }}
            uniqKey={uniqKey}
            handleAddButtonClick={handleAddButtonClick}
            columns={columns}
            service={service}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ListArticles;
