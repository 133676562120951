import kegiatan1 from "@/assets/images/kegiatan/kegiatan-1.png";
import kegiatan1_1 from "@/assets/images/kegiatan/baksos/baksos_1.jpg";
import kegiatan1_2 from "@/assets/images/kegiatan/baksos/baksos_2.jpg";
import kegiatan1_3 from "@/assets/images/kegiatan/baksos/baksos_3.jpg";
import kegiatan1_4 from "@/assets/images/kegiatan/baksos/baksos_4.jpg";
import kegiatan1_5 from "@/assets/images/kegiatan/baksos/baksos_5.jpg";
import kegiatan1_6 from "@/assets/images/kegiatan/baksos/baksos_6.jpg";
import kegiatan1_7 from "@/assets/images/kegiatan/baksos/baksos_7.jpg";
import kegiatan1_8 from "@/assets/images/kegiatan/baksos/baksos_8.jpg";
import kegiatan1_9 from "@/assets/images/kegiatan/baksos/baksos_9.jpg";
import kegiatan1_10 from "@/assets/images/kegiatan/baksos/baksos_10.jpg";
import kegiatan1_11 from "@/assets/images/kegiatan/baksos/baksos_11.jpg";
import kegiatan1_12 from "@/assets/images/kegiatan/baksos/baksos_12.jpg";

import kegiatan2 from "@/assets/images/kegiatan/kegiatan-2.jpg";
import kegiatan2_1 from "@/assets/images/kegiatan/health_education/edu_1.jpg";
import kegiatan2_2 from "@/assets/images/kegiatan/health_education/edu_2.jpg";
import kegiatan2_3 from "@/assets/images/kegiatan/health_education/edu_3.jpg";
import kegiatan2_4 from "@/assets/images/kegiatan/health_education/edu_4.jpg";
import kegiatan2_5 from "@/assets/images/kegiatan/health_education/edu_5.jpg";
import kegiatan2_6 from "@/assets/images/kegiatan/health_education/edu_6.jpg";
import kegiatan2_7 from "@/assets/images/kegiatan/health_education/edu_7.jpg";
import kegiatan2_8 from "@/assets/images/kegiatan/health_education/edu_8.jpg";
import kegiatan2_9 from "@/assets/images/kegiatan/health_education/edu_9.jpg";
import kegiatan2_10 from "@/assets/images/kegiatan/health_education/edu_10.jpg";
import kegiatan2_11 from "@/assets/images/kegiatan/health_education/edu_11.jpg";

import kegiatan3 from "@/assets/images/kegiatan/kegiatan-3.png";
import kegiatan3_1 from "@/assets/images/kegiatan/ultah/ultah_1.png";
import kegiatan3_2 from "@/assets/images/kegiatan/ultah/ultah_2.png";
import kegiatan3_3 from "@/assets/images/kegiatan/ultah/ultah_3.jpg";
import kegiatan3_4 from "@/assets/images/kegiatan/ultah/ultah_4.jpg";
import kegiatan3_5 from "@/assets/images/kegiatan/ultah/ultah_5.png";
import kegiatan3_6 from "@/assets/images/kegiatan/ultah/ultah_6.jpg";
import kegiatan3_7 from "@/assets/images/kegiatan/ultah/ultah_7.jpg";
import kegiatan3_8 from "@/assets/images/kegiatan/ultah/ultah_8.jpg";
import kegiatan3_9 from "@/assets/images/kegiatan/ultah/ultah_9.jpg";
import kegiatan3_10 from "@/assets/images/kegiatan/ultah/ultah_10.jpg";
import kegiatan3_11 from "@/assets/images/kegiatan/ultah/ultah_11.jpg";
import { KegiatanModel } from "@/page/admin/kegiatan/types/KegiatanModel";

export const kegiatanDummy: KegiatanModel[] = [
  {
    id: 1,
    tanggal: "2024-05-23",
    judul: "Bakti Sosial di GKP Cikembar Sukabumi",
    deskripsi:
      "Kegiatan Bakti Sosial di GKP Cikembar Sukabumi dalam rangka menyambut HUT ke 75 YBRS GKP. Dalam kegiatan yang dilakukan pada tanggal 23 Mei 2024, diadakan pengobatan gratis bagi warga di sekitar GKP Cikembar Sukabumi. Hal ini dilakukan sebagai bentuk kepedulian dan pelayanan kepada masyarakat setempat.",
    isPublish: true,
    imageFilePath: kegiatan1,
    createdDate: null,
    updatedDate: null,
    createdBy: null,
    updatedBy: null,
    galleries: [
      kegiatan1_1,
      kegiatan1_2,
      kegiatan1_3,
      kegiatan1_4,
      kegiatan1_5,
      kegiatan1_6,
      kegiatan1_7,
      kegiatan1_8,
      kegiatan1_9,
      kegiatan1_10,
      kegiatan1_11,
      kegiatan1_12,
    ].map((image, index) => ({
      id: index + 1,
      kegiatanId: 1,
      imageFilePath: image,
      createdDate: null,
      updatedDate: null,
      createdBy: null,
      updatedBy: null,
    })),
  },
  {
    id: 2,
    tanggal: "2024-06-08",
    judul: "Health Education Fair - Panti Asuhan Tanjung Barat",
    deskripsi:
      "Kegiatan Health Education Fair di Panti Asuhan Tanjung Barat, diadakan dalam rangka menyambut HUT ke 75 YBRS GKP dengan memberikan edukasi kesehatan berupa Pelatihan Bantuan Hidup Dasar (BHD) dan Pelatihan P3K untuk warga jemaat GKP Wilayah Klasis Jakarta, Banten dan Bekasi. Diadakan 8 Juni 2024 bertempat di Asuhan Tanjung Barat.",
    isPublish: true,
    imageFilePath: kegiatan2,
    createdDate: null,
    updatedDate: null,
    createdBy: null,
    updatedBy: null,
    galleries: [
      kegiatan2_1,
      kegiatan2_2,
      kegiatan2_3,
      kegiatan2_4,
      kegiatan2_5,
      kegiatan2_6,
      kegiatan2_7,
      kegiatan2_8,
      kegiatan2_9,
      kegiatan2_10,
      kegiatan2_11,
    ].map((image, index) => ({
      id: index + 1,
      kegiatanId: 2,
      imageFilePath: image,
      createdDate: null,
      updatedDate: null,
      createdBy: null,
      updatedBy: null,
    })),
  },
  {
    id: 3,
    tanggal: "2024-06-20",
    judul: "HUT ke 75 YBRS GKP",
    deskripsi:
      "Acara puncak HUT ke 75 YBRS GKP 20 Juni 2024 di Aula Agape RS Immanuel. Turut mengundang Majelis Sinode GKP, Badan-Badan Pelayanan di lingkup GKP, Gereja-gereja GKP di Klasus wilayah Priangan, para Pimpinan Satuan Kerja di lingkup YBRS, serta mitra-mitra YBRS GKP ",
    isPublish: true,
    imageFilePath: kegiatan3,
    createdDate: null,
    updatedDate: null,
    createdBy: null,
    updatedBy: null,
    galleries: [
      kegiatan3_1,
      kegiatan3_2,
      kegiatan3_3,
      kegiatan3_4,
      kegiatan3_5,
      kegiatan3_6,
      kegiatan3_7,
      kegiatan3_8,
      kegiatan3_9,
      kegiatan3_10,
      kegiatan3_11,
    ].map((image, index) => ({
      id: index + 1,
      kegiatanId: 3,
      imageFilePath: image,
      createdDate: null,
      updatedDate: null,
      createdBy: null,
      updatedBy: null,
    })),
  },
];
