import { DonationsVideosFormModel, DonationsVideosModel } from "../types/DonationsVideosModel";
import { APIResponse } from "@/types/APIResponse";
import { PostQuery } from "@/types/PostQuery";
import { BaseAPI } from "@/utility/BaseAPI";
class DonationsVideosService {
  private baseURL = `${import.meta.env.VITE_BASE_URL}/api/v1/cms/donation_videos`;
  private baseURLGuest = `${import.meta.env.VITE_BASE_URL}/api/v1/web/donation_videos/fetch_one_active`;
  baseAPI: BaseAPI;
  constructor() {
    this.baseAPI = new BaseAPI();
  }

  public async create(
    data: DonationsVideosFormModel
  ): Promise<APIResponse<DonationsVideosModel>> {
    return this.baseAPI.createMultipart(this.baseURL, data);
  }
  public async update(
    id: number,
    data: DonationsVideosFormModel
  ): Promise<APIResponse<DonationsVideosModel>> {
    return this.baseAPI.updateMultipart(this.baseURL, id, data);
  }

  public async getDetail(id: number): Promise<APIResponse<DonationsVideosModel>> {
    return this.baseAPI.getDetail(this.baseURL, id);
  }
  public async getDetailGuest(): Promise<APIResponse<DonationsVideosModel>> {
    return this.baseAPI.getDetailGuestWithoutId(this.baseURLGuest);
  }
  async all(postQuery: PostQuery): Promise<APIResponse<DonationsVideosModel[]>> {
    return this.baseAPI.all(this.baseURL, postQuery);
  }
  async list(): Promise<APIResponse<DonationsVideosModel[]>> {
    return this.baseAPI.list(this.baseURL);
  }
  async delete(ids: string[]): Promise<APIResponse<any>> {
    return this.baseAPI.delete(this.baseURL, ids);
  }
}
export default DonationsVideosService;
