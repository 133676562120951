import { Route } from "@/types/RouteModel";
import { createHashRouter } from "react-router-dom";
import buildRoute from "./BuildRoute";
import mainRoutes from "./routes/main";
import adminRoutes from "./routes/admin";
import MainLayout from "@/layouts/main_layout";
export const routesConfig: Route[] = [
  {
    path: "/",
    element: <MainLayout />,
    children: [...mainRoutes, ...adminRoutes],
  },
];
export default createHashRouter(routesConfig.map(buildRoute));
