import { ProgramFormModel, ProgramModel } from "../types/ProgramModel";
import { APIResponse } from "@/types/APIResponse";
import { PostQuery } from "@/types/PostQuery";
import { BaseAPI } from "@/utility/BaseAPI";
class ProgramService {
  private baseURL = `${import.meta.env.VITE_BASE_URL}/api/v1/cms/programs`;
  baseAPI: BaseAPI;
  constructor() {
    this.baseAPI = new BaseAPI();
  }

  public async create(
    data: ProgramFormModel
  ): Promise<APIResponse<ProgramModel>> {
    return this.baseAPI.create(this.baseURL, data);
  }
  public async update(
    id: number,
    data: ProgramFormModel
  ): Promise<APIResponse<ProgramModel>> {
    return this.baseAPI.update(this.baseURL, id, data);
  }

  public async getDetail(id: number): Promise<APIResponse<ProgramModel>> {
    return this.baseAPI.getDetail(this.baseURL, id);
  }
  async all(postQuery: PostQuery): Promise<APIResponse<ProgramModel[]>> {
    return this.baseAPI.all(this.baseURL, postQuery);
  }
  async list(): Promise<APIResponse<ProgramModel[]>> {
    return this.baseAPI.list(this.baseURL);
  }
  async delete(ids: string[]): Promise<APIResponse<any>> {
    return this.baseAPI.delete(this.baseURL, ids);
  }
}
export default ProgramService;
