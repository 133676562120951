import { Form, Formik } from "formik";
import useChangePassword from "./useChangePassword";
import { ChangePasswordModel } from "../../types/ChangePasswordModel";
import {
  Box,
  CardHeader,
  Card,
  CardContent,
  IconButton,
  CircularProgress,
  InputAdornment,
  Button,
} from "@mui/material";
import {
  ArrowCircleLeftOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import FormikTextField from "@/components/formik/FormikTextField";
const ChangePassword = () => {
  const {
    validationSchema,
    mutation,
    navigate,
    showPassword,
    setShowPassword,
    showSnackbar,
  } = useChangePassword();
  return (
    <Formik<ChangePasswordModel>
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      }}
      onSubmit={(values) => {
        // console.log(values);
        if (values.newPassword !== values.confirmPassword) {
          showSnackbar("Password baru dan konfirmasi password tidak cocok", "error");
          return;
        }
        mutation.mutate(values);
      }}
    >
      {({ handleSubmit }) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: 2, sm: 3, md: 4 },
            width: { xs: "35%", sm: "30%", md: "25%" },
            marginX: "auto",
            marginTop: {
              xs: "4rem",
              sm: "6rem",
              md: "8rem",
              lg: "10rem",
              xl: "12rem",
            },
            paddingX: { xs: 2, sm: 3, md: 4 },
          }}
        >
          <Form onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                style={{ paddingBottom: 0 }}
                avatar={
                  <IconButton size="small" onClick={() => navigate(-1)}>
                    <ArrowCircleLeftOutlined />
                  </IconButton>
                }
                title="Ubah Password"
                titleTypographyProps={{
                  fontSize: 24,
                }}
                subheader="Formulir Ubah Password"
                subheaderTypographyProps={{
                  fontSize: 16,
                }}
                action={
                  mutation.isPending ? <CircularProgress size="1.5rem" /> : null
                }
              />
              <CardContent>
                <FormikTextField
                  name="oldPassword"
                  label="Password Lama"
                  placeholder="Password Lama"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormikTextField
                  name="newPassword"
                  label="Password Baru"
                  placeholder="Password Baru"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormikTextField
                  name="confirmPassword"
                  label="Konfirmasi Password"
                  placeholder="Konfirmasi Password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                  <Button
                    sx={{ marginTop: "0.5rem" }}
                    disabled={mutation.isPending}
                    startIcon={
                      mutation.isPending ? (
                        <CircularProgress size="1rem" />
                      ) : null
                    }
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Ubah Password
                  </Button>
              </CardContent>
            </Card>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default ChangePassword;
