import { useNavigate } from "react-router-dom";
import ArticleService from "../../services/ArticleService";
import { ArticleModel } from "../../types/ArticleModel";
import { ColumnType } from "@/components/table/types/ColumnModel";
import { PostQuery } from "@/types/PostQuery";
import ImagePreview from "@/components/media/ImagePreview";
import { Box, Chip } from "@mui/material";
import ArticleAction from "../../components/ArticleAction";
import toPascalCase from "@/utility/pascalCase";
import { CalendarToday } from "@mui/icons-material";

const useListArticles = () => {
  const uniqKey = "articles";
  const articleService = new ArticleService();
  const navigate = useNavigate();
  // const { showConfirmationDialog } = useConfirmationDialog();
  const columns: ColumnType<ArticleModel>[] = [
    { field: "judul", headerName: "Judul", width: 200, type: "string" },
    {
      field: "thumbnail",
      headerName: "Thumbnail",
      hideFilter: true,
      hideSort: true,
      renderCell: (row: ArticleModel) => (
        <ImagePreview
          imageStyle={{ width: "120px", height: "120px", objectFit: "cover" }}
          src={row.imageFilePath}
          alt="artikel"
        />
      ),
    },
    {
      field: "tags",
      headerName: "Tags",

      type: "string",
      renderCell: (row: ArticleModel) => (
        <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          {row.tags.split(",").map((tag) => (
            <Chip key={tag} label={toPascalCase(tag.trim())} />
          ))}
        </div>
      ),
    },
    {
      field: "isPublish",
      headerName: "Publikasi",
      type: "boolean",
      renderCell: (row: ArticleModel) => (
        <Chip label={row.isPublish ? "Publikasi" : "Draft"} />
      ),
    },
    {
      field: "createdDate",
      headerName: "Tanggal Dibuat",
      type: "date",
      renderCell: (row: ArticleModel) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <CalendarToday />
          {new Date(row.createdDate).toLocaleDateString("id-ID")}
        </Box>
      ),
    },
    {
      field: "actions",
      type: "actions",
    
      headerName: "Actions",
      renderCell: (data: ArticleModel) => {
        return <ArticleAction data={data} />;
      },
    },
  ];
  const handleAddButtonClick = () => {
    navigate("/cms-ybrs/artikel/tambah");
  };

  const service = (postQuery: PostQuery) => {
    return articleService.all(postQuery);
  };
  return {
    uniqKey,
    columns,
    handleAddButtonClick,
    service,
  };
};

export default useListArticles;
