import {
  Box,
  CSSObject,
  Divider,
  Drawer as MuiDrawer,
  List,
  styled,
  Theme,
  Toolbar,
} from "@mui/material";
import menu from "@/constants/app_menu_constant";

import SidebarListItem from "./components/SidebarListItem";
import useSidebar from "./useSidebar";
import Logo from "@/assets/images/logo.png";
import { drawerWidth } from "@/constants/app_theme_constant";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

const Sidebar = ({ container }: { container?: () => HTMLElement }) => {
  const {
    desktopOpen,
    mobileOpen,
    nestedOpen,
    location,
    handleClick,
    handleNestedClick,
    handleDrawerClose,
    handleDrawerTransitionEnd,
    isHover,
    setIsHover,
  } = useSidebar();

  const drawer = (
    <Box
      onMouseEnter={() => {
        if (desktopOpen) return;
        setIsHover(true);
      }}
      onMouseLeave={() => {
        if (isHover) {
          setIsHover(false);
        }
      }}
      sx={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <DrawerHeader>
        <img
          src={Logo}
          alt="Brand Logo"
          style={{
            height: "100%",
            width: "100%",
            padding: 20,
            transition: "all 0.3s ease",
          }}
        />
        {/* <Typography variant="body1" noWrap>
          {brandName}
        </Typography> */}
      </DrawerHeader>
      <Divider />
      <List
        sx={{
          overflowY: "auto",
          height: "calc(100vh - 200px)",
          flexGrow: 1,
        }}
      >
        {menu.map((item, index) => (
          <SidebarListItem
            key={index}
            item={item}
            location={location}
            nestedOpen={nestedOpen}
            handleClick={handleClick}
            handleNestedClick={handleNestedClick}
            drawerOpen={desktopOpen}
          />
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { xl: drawerWidth }, flexShrink: { xl: 0 } }}
      aria-label="mailbox folders"
    >
      <MuiDrawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", xl: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth * 1.2, // Increase the width
          },
        }}
      >
        {drawer}
      </MuiDrawer>
      <Drawer
        sx={{
          display: { xs: "none", xl: "flex" },
        }}
        variant="permanent"
        // anchor="left"

        open={desktopOpen}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
