import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { LANGUAGE_OPTIONS } from "@/constants/app_language";
import { LanguageModel } from "@/types/LanguageModel";

interface LanguageState {
  currentLanguage: LanguageModel;
  availableLanguages: LanguageModel[];
  setLanguage: (language: LanguageModel) => void;
  removeLanguage: (languageValue: string) => void;
}

const useLanguageStore = create<LanguageState>()(
  persist(
    (set) => ({
      currentLanguage: LANGUAGE_OPTIONS[0],
      availableLanguages: LANGUAGE_OPTIONS,
      setLanguage: (language) => set(() => ({ currentLanguage: language })),
      removeLanguage: (languageValue) => set((state) => ({
        availableLanguages: state.availableLanguages.filter(
          (language) => language.value !== languageValue
        ),
      })),
    }),
    {
      name: "language-store",
      storage: createJSONStorage(() => localStorage),
      version: Number(import.meta.env.VITE_REDUX_PERSIST_VERSION),
      migrate: (persistedState, version) => {
        if (version !== Number(import.meta.env.VITE_REDUX_PERSIST_VERSION)) {
          return {
            currentLanguage: LANGUAGE_OPTIONS[0],
            availableLanguages: LANGUAGE_OPTIONS,
          };
        }
        return persistedState;
      },
    }
  )
);

export default useLanguageStore;
