import { artikelDummy } from "@/constants/artikel_dummy";
import ArticleService from "@/page/admin/articles/services/ArticleService";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

const useDetailArticle = (id: number) => {
  const isAPIActive = import.meta.env.VITE_API_ACTIVE === "true";
  const articleService = new ArticleService();
  const articleQuery = useQuery({
    queryKey: ["article-guest", id],
    queryFn: () => articleService.getDetailGuest(id),
  });

  const articleDetail = useMemo(() => {
    if (isAPIActive && articleQuery.isSuccess) {
      return {
        ...articleQuery.data.data,
        imageFilePath: `${import.meta.env.VITE_BASE_URL}/${
          articleQuery.data.data.imageFilePath
        }`,
      } ;
    } else {
      const article = artikelDummy.find((article) => article.id === id);
      return article ;
    }
  }, [isAPIActive, articleQuery.isSuccess, articleQuery.data]);

  return { articleDetail };
};

export default useDetailArticle;
