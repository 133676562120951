import { useSnackbar } from "@/hooks/useSnackbar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import HeroImagesService from "../../services/HeroImagesService";
import { HeroImagesFormModel } from "../../types/HeroImagesModel";
import * as Yup from "yup";
import { useMemo } from "react";

const useUpdateHeroImages = (id: number) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const heroimagesService = new HeroImagesService();
  const validationSchema = Yup.object().shape({
    Deskripsi: Yup.string().required("Description is required"),
    IsActive: Yup.boolean().required("Is Active is required"),
    ImageFile: Yup.mixed().nullable(),
  });
  const mutation = useMutation({
    mutationFn: (data: HeroImagesFormModel) =>
      heroimagesService.update(id, data),
    onSuccess: () => {
      showSnackbar("HeroImages berhasil ditambahkan", "success");
      navigate(-1);
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });
  const heroimagesQuery = useQuery({
    queryKey: ["heroimages-detail", id],
    queryFn: () => heroimagesService.getDetail(id),
  });
  const heroimagesData = useMemo(() => {
    return heroimagesQuery.data?.data ?? null;
  }, [heroimagesQuery.isSuccess, heroimagesQuery.data]);
  const initialValues = useMemo(() => {
    return {
      Deskripsi: heroimagesData?.deskripsi ?? "",
      IsActive: heroimagesData?.isActive ?? false,
      ImageFile: null,
    };
  }, [heroimagesData]);
  return {
    validationSchema,
    mutation,
    navigate,
    initialValues,
    heroimagesData,
  };
};

export default useUpdateHeroImages;
