import KegiatanService from "@/page/admin/kegiatan/services/KegiatanService";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import moment from "moment";
import { kegiatanDummy } from "@/constants/kegiatan_dummy";

const useListKegiatan = () => {
  const isAPIActive = import.meta.env.VITE_API_ACTIVE === "true";
  const kegiatanService = new KegiatanService();
  const kegiatanQuery = useQuery({
    queryKey: ["kegiatan-guest"],
    queryFn: () => kegiatanService.listGuest(),
  });

  const listKegiatan = useMemo(() => {
    if (isAPIActive && kegiatanQuery.isSuccess) {
      return kegiatanQuery.data.data.map((kegiatan) => ({
        ...kegiatan,
        imageFilePath: `${import.meta.env.VITE_BASE_URL}/${kegiatan.imageFilePath}`,
      }));
    } else {
      return kegiatanDummy;
    }
  }, [isAPIActive, kegiatanQuery.isSuccess, kegiatanQuery.data]);

  const sortedKegiatan = listKegiatan.sort((a, b) => {
    return moment(b.tanggal).diff(moment(a.tanggal));
  });

  const slicedKegiatan = sortedKegiatan.slice(0, 3);    

  return { listKegiatan: slicedKegiatan };
};

export default useListKegiatan;
