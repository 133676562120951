import MiniHero from "@/components/minihero";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useOrgan from "./useOrgan";
import notFound from "@/assets/images/data-not-found.jpg";

const Organ = () => {
  const { type } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { periode, autocompleteOptions, setPeriode, personData, personQuery } =
    useOrgan(String(type));

  return (
    <>
      <MiniHero
        title={`Organ ${tGeneral(`organ_${type}`)}`}
        breadcrumb={[{ name: tGeneral("beranda"), link: "/" }]}
      />
      <section className="team-section  centred !ybrs-mt-16">
        <div className="auto-container">
          <div className=" ybrs-flex ybrs-flex-col ybrs-w-full !ybrs-gap-4">
            <p className="!ybrs-text-[30px] xl:!ybrs-text-[60px] !ybrs-font-bold !ybrs-text-secondary ">
              ORGAN {tGeneral(`organ_${type}`).toUpperCase()}
            </p>
            <p className="!ybrs-text-lg xl:!ybrs-text-[30px] !ybrs-font-semibold  !ybrs-text-primary ">
              YAYASAN BADAN RUMAH SAKIT GKP
            </p>
            <p className="!ybrs-text-lg xl:!ybrs-text-[30px]  !ybrs-font-semibold !ybrs-text-accent">
              {" "}
              PERIODE {periode?.label}
            </p>
          </div>
          <div className=" ybrs-flex ybrs-flex-row ybrs-items-center ybrs-w-full ybrs-justify-items-start ybrs-mb-4 ybrs-mt-8">
            <Autocomplete
              getOptionLabel={(option) => option.label ?? ""}
              options={autocompleteOptions ?? []}
              value={periode ?? { label: null, value: null }}
              sx={{ width: 300 }}
              onChange={(_event, value) => setPeriode(value)}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Periode"
                  variant="outlined"
                  fullWidth
                  placeholder="Cari Periode"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              disableClearable
            />
          </div>

          {personQuery.isFetching ? (
            <div className="ybrs-flex ybrs-items-center ybrs-justify-center ybrs-w-full ybrs-text-center ybrs-min-h-[calc(100vh-700px)]">
              <CircularProgress color="primary" />
            </div>
          ) : personData.length === 0 ? (
            <div className=" ybrs-flex ybrs-flex-col ybrs-items-center ybrs-justify-center ybrs-w-full  ybrs-text-center ybrs-min-h-[calc(100vh-700px)]">
              <img src={notFound} className="ybrs-w-1/2" alt="" />
              <p className="ybrs-text-[40px] ybrs-font-bold">
                {tGeneral("data_not_found")}
              </p>
            </div>
          ) : (
            <div className={`ybrs-grid md:ybrs-grid-cols-2 ybrs-w-full ybrs-gap-4 ybrs-min-h-[calc(100vh-700px)] ${
              personData.length < 4 ? "xl:ybrs-grid-cols-3" : personData.length % 4 === 1 ? "xl:ybrs-grid-cols-3" : "xl:ybrs-grid-cols-4"
            }`}>
              {personData.map((e, index) => (
                <div
                  key={index}
                  className="team-block-one wow fadeInUp animated"
                  data-wow-delay={`${index * 300}ms`}
                  data-wow-duration="1500ms"
                  style={{
                    visibility: "visible",
                    animationDuration: "1500ms",
                    animationDelay: `${index * 300}ms`,
                  }}
                >
                  <div className="inner-box ">
                    <div className="image-box">
                      <figure className="image">
                        <img className="ybrs-w-full ybrs-h-[650px] ybrs-object-cover" src={e.imageFilePath} alt="" />
                      </figure>
                      {/* <ul className="social-links clearfix">
                      <li>
                        <a href="#">
                         <VisibilityOutlined/>
                        </a>
                      </li>
                     
                    </ul> */}
                    </div>
                    <div className="lower-content">
                      <h3
                        style={{
                          height: "110px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <a href="team-details">{e.name}</a>
                      </h3>
                      <span className="designation !ybrs-text-accent !ybrs-text-[24px]">{e.jabatan}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Organ;
