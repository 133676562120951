import { ColumnType } from "@/components/table/types/ColumnModel";
import { PostQuery } from "@/types/PostQuery";
import { useNavigate } from "react-router-dom";
import HeroImagesAction from "../../components/HeroImagesAction";
import HeroImagesService from "../../services/HeroImagesService";
import { HeroImagesModel } from "../../types/HeroImagesModel";
import { Chip } from "@mui/material";
import ImagePreview from "@/components/media/ImagePreview";

const useListHeroImages = () => {
  const uniqKey = "heroimages";
  const heroimagesService = new HeroImagesService();
  const navigate = useNavigate();
  const columns: ColumnType<HeroImagesModel>[] = [
    {
      field: "imageFilePath",
      headerName: "Gambar",
      hideFilter: true,
      hideSort: true,
      renderCell: (row: HeroImagesModel) => (
        <ImagePreview
          imageStyle={{ width: "120px", height: "120px", objectFit: "cover" }}
          src={row.imageFilePath}
          alt="hero-image"
        />
      ),
    },
    {
      field: "deskripsi",
      headerName: "Deskripsi",
      type: "string",
    },
    {
      field: "isActive",
      headerName: "Aktif",
      type: "boolean",
      width: 350,
      renderCell: (row: HeroImagesModel) => (
        <Chip label={row.isActive ? "Aktif" : "Tidak Aktif"} />
      ),
    },
    {
      field: "actions",
      type: "actions",

      headerName: "Actions",
      renderCell: (data: HeroImagesModel) => {
        return <HeroImagesAction data={data} />;
      },
    },
  ];
  const handleAddButtonClick = () => {
    navigate("/cms-ybrs/hero-images/tambah");
  };

  const service = (postQuery: PostQuery) => {
    return heroimagesService.all(postQuery);
  };
  return {
    uniqKey,
    columns,
    handleAddButtonClick,
    service,
  };
};

export default useListHeroImages;
