import MiniHero from "@/components/minihero";
import { useParams } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { useState } from "react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import type { Swiper as SwiperType } from "swiper";
import useDetailKegiatan from "./useDetailKegiatan";
import moment from "moment";
import RichTextView from "@/components/richtext_view";
import richtextDefaultFormater from "@/utility/richtextFormater";
const DetailKegiatanPage = () => {
  const { id } = useParams();

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
  const { kegiatanDetail } = useDetailKegiatan(Number(id));
  return (
    <>
      <MiniHero
        title="Detail Kegiatan"
        breadcrumb={[
          { name: "Beranda", link: "/" },
          { name: "Kumpulan Kegiatan", link: "/informasi/kegiatan" },
          { name: "Detail Kegiatan" },
        ]}
      />
      <section className="sidebar-page-container sec-pad-2">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-12 content-side">
              <div className="blog-details-content">
                <div className="news-block-one">
                  <div className="inner-box">
                    <Swiper
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                    >
                      {kegiatanDetail?.galleries.map((gallery, index) => (
                        <SwiperSlide key={index}>
                          <img
                            style={{
                              width: "100%",
                              height: "750px",
                              backgroundSize: "cover",
                              cursor: "pointer",
                            }}
                            src={gallery.imageFilePath}
                            alt={`Slide ${index + 1}`}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <Swiper
                      onSwiper={(swiper) => {
                        console.log(swiper);
                      }}
                      onClick={(swiper) => {
                        setThumbsSwiper(swiper as SwiperType);
                      }}
                      spaceBetween={10}
                      slidesPerView={1}
                      breakpoints={{
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 10,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                      }}
                      freeMode={true}
                      watchSlidesProgress={true}
                      navigation={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper ybrs-mt-3"
                    >
                      {kegiatanDetail?.galleries.map((gallery, index) => (
                        <SwiperSlide key={index}>
                          <img
                            style={{
                              width: "100%",
                              height: "250px",
                              backgroundSize: "cover",
                              cursor: "pointer",
                            }}
                            src={gallery.imageFilePath}
                            alt={`Slide ${index + 1}`}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="lower-content">
                      <ul className="post-info mb_15 clearfix">
                        <li>
                          {moment(kegiatanDetail?.tanggal).format("DD/MM/YYYY")}
                        </li>
                      </ul>
                      <h2>{kegiatanDetail?.judul}</h2>
                      <p>
                        <RichTextView
                          content={kegiatanDetail?.deskripsi ?? ""}
                          formater={richtextDefaultFormater}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="post-tags mb_60">
                  <ul className="tags-list clearfix">
                    <li>
                      <h3>Tags:</h3>
                    </li>
                    {kegiatan.tags.map((tag, index) => (
                      <li key={index}>
                        <a href="blog-details">{tag}</a>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DetailKegiatanPage;
